import ShopifyBuy from 'shopify-buy'
import { LocalStorageKeys } from './keys'
import { isCart } from '../../utils'

function set(key: string, value: string) {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    window.localStorage.setItem(key, value)
  }
}

function remove(key: string) {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    window.localStorage.removeItem(key)
  }
}

function get(key: string) {
  const isBrowser = typeof window !== 'undefined'
  if (!isBrowser) {
    return null
  }

  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

function getInitialCart(): ShopifyBuy.Cart | null {
  manageCartExpiration()
  const existingCartString = get(LocalStorageKeys.CART)
  if (existingCartString == null) {
    return null
  }
 
  try {
    const existingCart = JSON.parse(existingCartString)
    if (!isCart(existingCart)) {
      return null
    }

    return existingCart as ShopifyBuy.Cart
  } catch {
    return null
  }
}

function getInitialUser() {
  const existingUser = get(LocalStorageKeys.USER)
  if (existingUser == null) {
    return null
  }

  try {
    const existingUserData = JSON.parse(existingUser)
    if (existingUser) {
      return existingUserData
    }
    return existingUserData
  } catch {
    return null
  }
}

function manageCartExpiration(){

  if(isProductInCart()){
    let cartExpiration = get( "cartExpiration")
    if(cartExpiration){
      let timeDiff = Date.now() - cartExpiration
      if(timeDiff >=  2*24*60*60*1000){
        remove(LocalStorageKeys.CART)
        remove(LocalStorageKeys.CHECKOUT_ID)
        window.localStorage.clear()
    }
  }
}
}
function addCartExpiration(){
  set("cartExpiration", Date.now() )  // 2*24*60*60*1000
}

function isProductInCart(){
  let lsCart = get(LocalStorageKeys.CART)
  if(lsCart){
    let cart = JSON.parse(lsCart)
   // console.log(!!cart.lineItems.length , cart.lineItems.length , "cart.lineItems.length ");
    
    return !!cart?.lineItems?.length 
  }
  return false
}

export const LocalStorage = {
  get,
  remove,
  set,
  getInitialCart,
  getInitialUser,
  manageCartExpiration,
  addCartExpiration
}

