export const useRecipeAnalytics = (recipe) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.trackEvent,
        eventAction: ctConstants.recipeClick,
        eventValue: 1,
        eventLabel: recipe,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Interest',
    })
    console.log(window.digitalData)
  }
}
