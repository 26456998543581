import React from 'react'
import styled from 'styled-components'

export const Amex = props => {
  return (
    <StyledSvg
      width={36.794}
      height={24.529}
      viewBox='0 0 36.794 24.529'
      {...props}
    >
      <path
        data-name='Path 56'
        d='M28.408 9.718l-1.547 1.673-1.5-1.673h-4.9v5.1h4.825l1.558-1.691 1.5 1.691h1.567l-2.281-2.534 2.278-2.566zm-3.739 4.046h-3v-1.02h2.672v-1.038H21.67v-.932h3.057l1.335 1.49zM11.308 9.545l-.015-.031h.015zm4.592 3.633l-1.6-3.46h-1.99v4.825l-2.06-4.825H8.435l-2.182 5.1h1.326l.471-1.144h2.537l.471 1.144h2.481v-4l1.769 4h1.076l1.758-4 .009 4h1.2v-5.1h-1.978zm-7.42-.558l.836-2.037.836 2.037zM33.727 0H3.066A3.067 3.067 0 000 3.066v18.4a3.067 3.067 0 003.066 3.066h30.661a3.067 3.067 0 003.066-3.066v-18.4A3.067 3.067 0 0033.727 0zm-1.533 15.821h-4.383l-.97-1.105-1.041 1.105H10.308l-.461-1.134H8.789l-.461 1.134H4.6l3.114-7.113h7.314l.817 1.837.8-1.837h9.284l.941 1.085 1.009-1.085h4.319l-3.128 3.575z'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
