import React, { useState } from 'react'
import styled from 'styled-components'

import { desktopVw, desktopBreakpoint, mobileVw, colors } from '../../styles'

import { useSiteQuery } from '../../hooks'

import { Decoration } from './Decoration'
import { Video } from './Video'
import { Rating } from './Rating'
import { Button } from './Button'

export const Testimonial = ({
  decoration,
  defaultImage,
  goldBorder,
  classicalVersion,
}) => {
  const [isOpen, setMenu] = useState(false)

  const { testimonialText } = useSiteQuery()

  return (
    <StyledTestimonial halfBorder={goldBorder} aria-label='Testimonial'>
      <StyledDecoration>
        {decoration.map((item, i) => {
          return <Decoration key={i} data={item} />
        })}
      </StyledDecoration>
      <TestimonialBlockText>
        <StyledTitle>
          {classicalVersion ? (
            <div>
              <span>Explorez</span>
              <span>chaque saveur</span>
            </div>
          ) : (
            <div>
              <span>Vos avis </span>
            </div>
          )}
        </StyledTitle>
        <Wrapper>
          {classicalVersion ? null : (
            <span className='testimonial-text'>“Nous sommes des inconditionnels” - Nicolas.R</span>
          )}
          {!defaultImage && <Rating score={4} />}
        </Wrapper>
      </TestimonialBlockText>

      {defaultImage && (
        <StyledVideo
          poster={defaultImage.poster.fluid}
          mov={defaultImage.videoMov.file.url}
          mp4={defaultImage.videoMp4.file.url}
          track = {defaultImage.videoTrackFile}
          controls
        />
      )}
      {classicalVersion ? (
        <ButtonWrapper>
          <TextArea display={isOpen}>
            <span>{testimonialText.testimonialText}</span>
          </TextArea>
          <StyledButton
            text={isOpen ? "moins d'informations" : 'En savoir plus'}
            type={isOpen ? 'transparent' : null}
            onClick={() => setMenu(!isOpen)}
          />
        </ButtonWrapper>
      ) : null}
    </StyledTestimonial>
  )
}

const StyledTestimonial = styled.section`
  padding: ${mobileVw(30)} 0 0 0;
  /* margin: ${(props) =>
    props.halfBorder ? `0 0 ${mobileVw(40)}` : `0 0 ${mobileVw(78)}`}; */
  text-align: center;
  position: relative;
  margin: 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(110)} 0 0 0;
    margin: 0 ${desktopVw(128)};

    img {
      height: 50px;
    }
  }
`

const TestimonialBlockText = styled.div`
  position: relative;
`

const StyledButton = styled(Button)`
  margin-top: ${mobileVw(8)};
  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVw(350)};
    margin-top: ${desktopVw(8)};
  }
`

const StyledTitle = styled.h2`
  display: block;
  margin: 0 auto ${mobileVw(10)};
  width: auto;
  font-family: 'Splandor';
  font-size: ${mobileVw(30)};

  span {
    &:first-child {
      padding-right: ${mobileVw(8)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 auto ${desktopVw(20)};
    font-size: ${desktopVw(40)};
    line-height: ${desktopVw(32)};
  }

  span {
    /* display: block; */
    /* margin-right: 0.25em; */
    &:first-child {
      color: #ae841f;
      padding-right: ${desktopVw(8)};
    }
  }
`

const Wrapper = styled.div`
  /* height: ${(props) => props.height}px; */
  position: relative;

  .testimonial-text {
    display: block;
    font-family: "MrsEaves";
    font-size: ${mobileVw(18)};
    line-height: ${mobileVw(24)};
    margin: 0 auto ${mobileVw(5)};
    max-width: ${mobileVw(260)};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(26)};
      line-height: ${desktopVw(24)};
      margin: 0 0 ${desktopVw(20)} 0;
      max-width: initial;
    }
  }

  p {
    color: ${colors.mailleGold};
    font-size: ${mobileVw(18)};
    margin: 0;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(20)};
      line-height: ${desktopVw(24)};
    }
  }
`

const StyledVideo = styled(Video)`
  height: ${mobileVw(176)};
  margin: ${mobileVw(25)} ${mobileVw(16)} 0;
  position: relative;
  width: auto;

  @media (min-width: ${desktopBreakpoint}) {
    width: auto;
    height: ${desktopVw(430)};
    margin: ${desktopVw(25)} ${desktopVw(132)} 0;
  }
`

const Line = styled.div`
  position: relative;
  height: ${mobileVw(1)};
  /* background: ${(props) =>
    props.halfBorder
      ? `linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,0) 40%, rgba(0,0,0,0.5) 41%, rgba(0,0,0, 0.5) 100% )`
      : '#000'}; */

  background: #000;
  display: block;
  margin: ${mobileVw(20)} ${mobileVw(16)} 0;
  z-index: 998;

  /* &::after {
    content: '';
    width: ${mobileVw(144)};
    position: absolute;
    left: 0;
    z-index: 999;
    border: ${(props) =>
      props.halfBorder ? `solid ${mobileVw(1)} ${colors.mailleGold} ` : 'none'};
  } */

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(635)};
    margin: ${desktopVw(20)} ${desktopVw(95)} 0 auto;
    height: ${desktopVw(1)};
    /* background: ${(props) =>
      props.halfBorder
        ? `linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,0) 25%, rgba(0,0,0,0.5) 26%, rgba(0,0,0, 0.5) 75%, rgba(0,0,0, 0) 76%, rgba(0,0,0, 0) 100% )`
        : '#000'}; */

/* :: after wont work:-))) */
    /* &::after {
      content: '';
      width: ${desktopVw(370)};
      background: ${colors.mailleGold};
      position: absolute;
      left: 0;
      bottom: ${desktopVw(0)};
      z-index: 999;
      border: ${(props) =>
        props.halfBorder
          ? `solid ${desktopVw(1)} ${colors.mailleGold} `
          : 'none'};
    } */
  }

  span {
    background: #000;
    background: ${colors.mailleGold};
    top: 0;
    height: 200 %;
    width: ${(props) => 100 / props.width}%;
    transition: 1s ease;
    /* I'll change this later - waneer dan? */
    left: ${(props) => (props.index / props.width) * 100}%;
    position: absolute;
    transform: translate3d(0, -25 %, 0);
    cursor: pointer;
  }
`
const TextArea = styled.div`
  display: ${(props) => (props.display ? 'block' : ' none')};
  padding-top: ${mobileVw(10)};
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    padding-top: 0;
    font-size: ${desktopVw(18)};
  }
`

const ButtonWrapper = styled.div`
  padding: 0 ${mobileVw(16)};
  margin-top: ${mobileVw(25)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVw(20)};
    padding: 0 ${desktopVw(132)};

    button {
      display: none;
    }
  }
`

const StyledDecoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`
