import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import Image from 'gatsby-image'

import {
  desktopVw,
  letterSpacing,
  shortDskVw,
  desktopBreakpoint,
  colors,
  mobileVw,
  fonts,
} from '../../styles'
import { Icon, LinkType } from '../shared'
import { useToggleCart, useCart, useLocale, useLang } from '../../hooks'
import NavLinks from './NavLinks'

const StickyNav = ({ links, logo, mailleNewLogo }) => {

  const locale = useLocale();
  const lang = useLang();
  const toggleCart = useToggleCart()
  // const { y } = useWindowScroll()
  // const stickyNavTransition = useTransition(y > 160, null, {
  //   from: { transform: 'translateY(-100%)' },
  //   enter: { transform: 'translateY(0%)' },
  //   leave: { transform: 'translateY(-100%)' },
  // })
  const cart = useCart()

  const quantities = []
  cart?.lineItems.map((item) => quantities.push(item.quantity))

  const [numberOfItems, setNumberOfItems] = useState(
    quantities.reduce((a, b) => a + b, 0),
  )

  useEffect(() => {
    if (cart) {
      setNumberOfItems(quantities.reduce((a, b) => a + b, 0))
    }
  }, [cart])

  const enToFr = (url) => {
    const newurl = url.replace("ca/en", "ca/fr")
    return newurl
  }
  const frToEn = (url) => {
    const newurl = url.replace('ca/fr', 'ca/en')
    return newurl
  }

  return (
    <StickyNavWrapper>
      <LogoLink to={`/${lang}`} alt='Ce lien va ouvrir une nouvelle fenêtre: homepage'>
        <StyledImage fluid={logo.fluid} alt='Maille logo' />
            <StyledImage src={mailleNewLogo} alt='Maille logo' /> :
      </LogoLink>
      <NavLinks />
      <NavRightLinks>
        {
          locale === "fr" ?
            <>
              <Styledbutton onClick={toggleCart} style={{ position: 'relative' }}>
                {numberOfItems > 0 && (
                  <Items>
                    <div>{numberOfItems}</div>
                  </Items>
                )}
                <Icon name='bag' color='#fff' />
              </Styledbutton>
            </>
            :
            <>
              {
                locale === 'en-CA' &&
                <button
                  type='button'
                  onClick={() => {
                    navigate(`${enToFr(location.pathname)}`)
                    // navigate(`/ca/fr`)
                  }}
                >
                  <StyledSpan style={{ color: `${colors.mailleGold}` }}>
                    Version française
                  </StyledSpan>
                </button>
              }
              {
                locale === 'fr-CA' &&
                <button
                  type='button'
                  onClick={() => {
                    navigate(`${frToEn(location.pathname)}`)
                    // navigate(`/ca/en`)
                  }}
                >
                  <StyledSpan style={{ color: `${colors.mailleGold}` }}>
                    English Version
                  </StyledSpan>
                </button>
              }
            </>
        }
        <LinkType to={`/${lang}`} alt='Ce lien va ouvrir une nouvelle fenêtre'>
          <Icon className='search-icon sticky-nav-icon' name='search' />
        </LinkType>
      </NavRightLinks>
    </StickyNavWrapper>
  )
}

const StickyNavWrapper = styled.nav`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    position: fixed;
    z-index: 98;
    display: flex;
    align-items: center;
    top: 0;
    width: 100vw;
    height: ${desktopVw(80)};
    padding: ${shortDskVw([18, 22, 18, 24])};
    background-color: #000;

    svg {
      height: ${desktopVw(20)};
      max-width: ${desktopVw(19)};
    }
  }
`
const StyledSpan = styled.span`
  color: ${colors.white};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(18)};
  letter-spacing: ${mobileVw(1.6)};
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.4)};
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  img {
    height: ${desktopVw(45)};
  }
`

const Items = styled.span`
  color: ${colors.white};
  font-family: ${fonts.tivoli};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${mobileVw(13)};
  background: #c29938;
  border-radius: 50%;
  position: absolute;
  width: ${mobileVw(13)};
  height: ${mobileVw(13)};
  left: ${mobileVw(7)};
  top: ${mobileVw(9)};
  box-shadow: -1px -1px 14px 0px #f1e3e350;

  div {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(13)};
    width: ${desktopVw(13)};
    height: ${desktopVw(13)};
    left: ${desktopVw(7)};
    top: ${desktopVw(9)};
  }
`

const StyledImage = styled(Image)`
  width: ${desktopVw(45)};
`
const StyledImageCa = styled.img`
  width: ${desktopVw(45)};
`
const NavRightLinks = styled.div`
  min-width: ${desktopVw(80)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a,
  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: ${shortDskVw([0, 8])}; */
    padding: 0;
    margin: 0;
    background: none;
    border: none;
  }
`

const Styledbutton = styled.button`
  margin: 0 ${desktopVw(15)} 0 0 !important;
`

export default StickyNav
