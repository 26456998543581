import { useState } from 'react'

export interface Istate {
  response?: any
  loading: boolean
  error?: string
}

export interface CreateContactData {
  formData: {
    email: string
    optInType?: string
    emailType: string
    dataFields?: [
      {
        key: string
        value: string | number | boolean | Date
      }
    ]
  }
  listId?: string
}

export const useCreateContact = () => {
  const [state, setState] = useState<Istate | null>({
    response: null,
    loading: false,
    error: null,
  })

  const createContact = async (data: CreateContactData) => {
    if (data.formData.email) {
      setState({ response: null, loading: true, error: null })
      try {   
        const output = await fetch('/.netlify/functions/dotdigital-create-contact', {
          method: 'POST',
          body: JSON.stringify(data)
        })
        const res = await output.json()

        setState({ response: res, loading: false, error: null })
      } catch (error) {
        setState({
          response: null,
          loading: false,
          error: error
        })
      } 
    } else {
      setState({
        response: null,
        loading: false,
        error: 'Invalid email'
      })
    }

  }
  return [createContact, state]
}
