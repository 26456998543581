/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { desktopVw, mobileVw, desktopBreakpoint, colors } from '../../styles'

export const PricePersonlisation = ({ quantity = 1, indexSize, shopifyProduct, withPersonalisation, jarPersonalisationPrice, corkPersonalisationPrice, withEngraving }) => {
    const available = shopifyProduct.available;
    const isTextEntered = withPersonalisation && withPersonalisation.find(({ key }) => key === '1. Texte gravé' || key === "Texte brodé")
    //const jarPersonalisationPrice = 9.50
    const isCorkEngrave = withEngraving && withEngraving.find(({ key }) => key === 'Gravure bouchon')
    const getPrice = (price) => {
        if (!isNaN(price)) {
            return price
        } else if (!isNaN(price.amount)) {
            return price.amount
        }
    }

    //Function to calculate the aggregate price of the product
    const getCalculatedPrice = () => {
        let price
        if (isTextEntered && isCorkEngrave) {
            // Normal Product + Product Personalized + Cork Personalized
            price = quantity * getPrice(shopifyProduct.price) + (quantity * jarPersonalisationPrice) + (quantity * corkPersonalisationPrice)
        } else if (isCorkEngrave) {
            // Normal Product + Cork Personalized
            return price = Number(quantity * getPrice(shopifyProduct.price) + (quantity * corkPersonalisationPrice)).toFixed(2)
        } else if (isTextEntered) {
            // Normal Product + Product Personalized
            price = quantity * getPrice(shopifyProduct.price) + (quantity * jarPersonalisationPrice)
        } else {
            // Normal Product + Cork Personalized
            price = quantity * getPrice(shopifyProduct.price)
        }
        return Number(price).toFixed(2)
    }


    return (
        <StyledPrice className='price'>
            {available ? (
                <>
                    {' '}
                    <span>
                        {getCalculatedPrice()}
                        {' '}
                        €
                    </span>
                    {shopifyProduct.compareAtPrice &&
                        shopifyProduct.compareAtPrice.amount > 1 && (
                            <Discountlabel>
                                {Number(
                                    quantity * shopifyProduct.compareAtPrice.amount +
                                    (withPersonalisation && isTextEntered ? quantity * jarPersonalisationPrice : 0) +
                                    (withEngraving && isCorkEngrave ? quantity * corkPersonalisationPrice : 0),
                                ).toFixed(2)}{' '}
                                €
                            </Discountlabel>
                        )}
                </>
            ) : (
                <SoldOut>Bientôt de retour</SoldOut>
            )}
        </StyledPrice>
    )
}

const StyledPrice = styled.div``

const Discountlabel = styled.span`
  display: inline-block;
  margin-left: ${mobileVw(5)} !important;
  color: ${colors.darkGrey} !important;
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    /* margin: 0 0 0 ${desktopVw(10)} !important; */
  }

  &:before {
    content: '';
    width: 100%;
    height: ${mobileVw(2)};
    position: absolute;
    top: 42%;
    background: ${colors.darkGrey};

    @media (min-width: ${desktopBreakpoint}) {
      height: ${desktopVw(2.5)};
    }
  }
`

const SoldOut = styled.span`
  display: inline-block;
  color: ${colors.darkGrey} !important;
  position: relative;
  text-transform: uppercase;
`
