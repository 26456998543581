import React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const TwoPeople = (props) => {
  return (
    <StyledSvg width={17.1} height={11.5} viewBox='0 0 35 40' {...props}>
      <g id='Group_1414' data-name='Group 1414' transform='translate(1.006 1)'>
        <path
          id='Path_1408'
          data-name='Path 1408'
          d='M68.917,921.358a4.211,4.211,0,1,1-8.417,0v-2.494a4.211,4.211,0,1,1,8.417,0Z'
          transform='translate(-43.796 -914.5)'
          fill='none'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <circle
          id='Ellipse_60'
          data-name='Ellipse 60'
          cx='3.507'
          cy='3.507'
          r='3.507'
          transform='translate(6.183 0.701)'
          strokeWidth='2'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          id='Path_1409'
          data-name='Path 1409'
          d='M61.813,926.1a8.416,8.416,0,0,0-13.2,5.2,1.388,1.388,0,0,0,1.389,1.613H56.88'
          transform='translate(-48.592 -910.472)'
          fill='none'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_1410'
          data-name='Path 1410'
          d='M66.694,925.5h0a11.225,11.225,0,0,0-11.11,9.627,1.411,1.411,0,0,0,1.4,1.595H76.4a1.411,1.411,0,0,0,1.4-1.595A11.225,11.225,0,0,0,66.694,925.5Z'
          transform='translate(-45.782 -910.07)'
          fill='none'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(16)};
  height: ${mobileVw(20)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(40)};
    height: ${desktopVw(40)};
  }
`
