import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

const StyledVideo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const VideoPlayer = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;

  /* &::-webkit-media-controls {
    display: none;
    -webkit-appearance: none;
  } */
`

const StyledImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`

export const Video = ({ mov, mp4, poster, className, controls, noRepeat, id, track }) => {
  
  const videoEl = useRef()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (videoEl.current.readyState >= 3) {
        videoEl.current.play()
        setReady(true)
        clearInterval(intervalId)
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <StyledVideo className={className}>
      <VideoPlayer
        id={id}
        crossOrigin=""
        autoPlay
        disableRemotePlayback
        loop  = {noRepeat ? false : true }
        playsInline
        muted
        ref={videoEl}
        controls={!!controls}
      >
        <source src={mov} type='video/mov' />
        <source src={mp4} type='video/mp4' />
        {track && <track src={track.file.url} kind="captions" label="French" default srcLang='fr' />}
      </VideoPlayer>
      {poster && (
        <StyledImage
          hidden={ready}
          fluid={poster}
          withPlaceholder
          alt='video poster'
        />
      )}
    </StyledVideo>
  )
}
