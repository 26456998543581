import React from 'react'
import styled from 'styled-components'
import { Image } from './Image'
import { desktopBreakpoint, desktopVw, mobileVw } from '../../styles'

export const Decoration = ({ data }) => {
  const {
    view,
    image,
    widthMobile,
    widthDesktop,
    positionMobile,
    positionDesktop,
    transformBlock,
  } = data

  const positions = ['top', 'right', 'bottom', 'left']

  const position =
    positionDesktop &&
    positions.map((item, i) => {
      return !Number.isNaN(Number(positionDesktop[i]))
        ? `${item}: ${desktopVw(positionDesktop[i])};`
        : `${item}: ${positionDesktop[i]};`
    })

  const positionMob =
    positionMobile &&
    positions.map((item, i) => {
      return !Number.isNaN(Number(positionMobile[i]))
        ? `${item}: ${mobileVw(positionMobile[i])};`
        : `${item}: ${positionMobile[i]};`
    })

  return (
    <StyledDecoration
      transform={transformBlock && transformBlock.toString()}
      position={position}
      positionMobile={positionMob}
      widthDesktop={widthDesktop && widthDesktop}
      widthMobile={widthMobile && widthMobile}
      displayDesktop={view && view[0] === 'desktop only'}
      displayMobile={view && view[0] === 'mobile only'}
    >
      <StyledImage fluid={image.fluid} alt={image.title} />
    </StyledDecoration>
  )
}

const StyledDecoration = styled.div`
  width: 100%;
  position: absolute;
  height: auto;
  display: block;
  transform: translate(${(props) => props.transform});
  display: ${(props) => (props.displayDesktop ? 'none' : 'block')};

  width: ${(props) =>
    props.widthMobile ? mobileVw(props.widthMobile) : '100%'};

  ${(props) => props.positionMobile}

  @media (min-width: ${desktopBreakpoint}) {
    width: ${(props) =>
      props.widthDesktop ? desktopVw(props.widthDesktop) : '100%'};

    display: ${(props) => (props.displayMobile ? 'none' : 'block')};

    ${(props) => props.position}
  }
`
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`
