import React from 'react'
import styled from 'styled-components'

export const Paypal = props => {
  return (
    <StyledSvg
      width={32.705}
      height={24.529}
      viewBox='0 0 32.705 24.529'
      {...props}
    >
      <path
        data-name='Path 58'
        d='M29.98 0H2.725A2.726 2.726 0 000 2.725V21.8a2.726 2.726 0 002.725 2.725H29.98a2.726 2.726 0 002.725-2.725V2.725A2.726 2.726 0 0029.98 0zM13.432 18.514H10.76a.426.426 0 01-.425-.45c.048-.77.493-3.3 2.037-13.235a.7.7 0 01.695-.6h4.919c2.633 0 4.576 1.545 4.039 4.314-.552 2.881-2.311 4.28-5.373 4.28h-1.514c-.62 0-.849.237-.928.79zm10.188-6.66c-.567 2.96-2.51 3.983-4.99 3.983h-.384a.621.621 0 00-.609.529l-.538 3.41a.615.615 0 01-.606.527h-1.834a.424.424 0 01-.42-.491l.928-5.948.042-.038h1.443c3.506 0 5.7-1.755 6.346-5.071a2.731 2.731 0 01.736 2.01 6.093 6.093 0 01-.114 1.089z'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
