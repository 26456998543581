import { useContext } from 'react'
import { StoreContext } from '../../contexts'

export function useWishlistRecipes() {
  const { setLayout } = useContext(StoreContext)

  async function setWishlistRecipes(newWishlistRecipes) {
    setLayout((prevState) => {
      return { ...prevState, wishlistRecipes: newWishlistRecipes }
    })
  }

  return setWishlistRecipes
}
