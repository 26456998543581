export const useProductCategoryAnalytics = (product, link) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.trackEvent,
        eventAction: ctConstants.linkClick,
        eventValue: 1,
        eventLabel: `${link}-${product}`,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Read',
    })
    console.log(window.digitalData)
  }
}
