import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Egg = props => {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 313.61 426.036" {...props}>
      <path fill="#AD8C4C" stroke="#AD8C4C" stroke-width="6" stroke-miterlimit="10" d="M161.497 397.187c-35.651 0-70.772-17.445-96.358-47.867-26.854-31.926-41.643-75.656-41.643-123.133 0-101.664 65.775-194.25 138-194.25 71.441 0 136.5 93.301 136.5 195.75 0 47.492-14.523 90.955-40.891 122.379-25.495 30.387-59.45 47.121-95.608 47.121zm0-359.25c-69.084 0-132 89.727-132 188.25 0 103.283 67.115 165 132 165 73.178 0 130.5-71.818 130.5-163.5 0-99.309-62.2-189.75-130.5-189.75z" />
      <path fill="#AD8C4C" stroke="#AD8C4C" stroke-width="6" stroke-miterlimit="10" d="M195.37 122.712l-37.026-26.447-40.709 24.123-28.498-25.496-35.641 21.795 2.965-9.328 33.394-19.877 28.502 25.504 40.292-23.877 36.475 26.053 37.863-26.057 35.068 23.52-3.117 5.125-31.441-21.313zM198.231 163.023l-39.828-26.303-43.669 25.598-33.748-26.996-46.99 27.115 3.727-8.584 43.783-25.801 33.752 27.004 43.332-25.402 39.672 26.197 39.694-29.043 46.791 29.879-.356 6.035-46.394-28.785z" />
      <path fill="#AD8C4C" d="M29.932 226.605l-4.936-13.168 43.938-34.895 48.759 25.059 42.366-25.723 42.844 27.059 47.281-27.018 44.813 30.268-1.752 11.793-43.436-28.025-47.219 26.982-42.656-26.941-41.635 25.277-48.303-24.836z" />
      <path fill="#AD8C4C" d="M30.246 269.687l-3.008-12.926 43.399-39.275 45.232 24.197 42.563-24.642 48.6 26.919 41.41-26.347 45.945 28.211-1.64 13.363-44.196-27.426-41.09 26.153-48.9-27.081-42.938 24.858-44.127-23.504z" />
      <path fill="#AD8C4C" stroke="#AD8C4C" stroke-width="6" stroke-miterlimit="10" d="M201.895 307.628l-43.445-26.216-44.903 25.443-40.998-21.617-27.723 21.328-3.66-4.758 30.777-23.672 41.502 21.883 45.098-25.557 43.555 26.284 45.926-24.092 33.762 25.127-3.578 4.812-30.739-22.873zM195.036 344.472l-36.692-26.207-40.324 23.895-40.192-23.073-17.894 15.141-3.875-4.582 21.105-17.859 40.809 23.427 40.676-24.105 36.809 26.293 46.5-25.5 27.781 19.844-3.485 4.882-24.718-17.656z" />
    </StyledSvg>
  )
}
const StyledSvg = styled.svg`
  width: ${mobileVw(18)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(17)};
  }
`
