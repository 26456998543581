import React from 'react'
import styled from 'styled-components'

export const Delivery = props => {
  return (
    <StyledSvg
      width={65.75}
      height={53.655}
      viewBox='0 0 65.75 53.655'
      {...props}
    >
      <g
        data-name='Group 813'
        transform='translate(0 .78)'
        fill='none'
        stroke='#ae841f'
        strokeMiterlimit={10}
        strokeWidth={1.5}
      >
        <path
          data-name='Path 724'
          d='M60.093 47.537H65V33.772l-5.1-13.255H46.137'
        />
        <path data-name='Line 79' d='M27.975 47.537h22.941' />
        <path data-name='Path 725' d='M9.431 38.488v9.05h9.431' />
        <circle
          data-name='Ellipse 27'
          cx={4.588}
          cy={4.588}
          r={4.588}
          transform='translate(18.608 42.949)'
        />
        <circle
          data-name='Ellipse 28'
          cx={4.588}
          cy={4.588}
          r={4.588}
          transform='translate(50.725 42.949)'
        />
        <path data-name='Path 726' d='M46.137 47.537V15.929H9.431v11.726' />
        <path data-name='Path 727' d='M51.235 20.517v13.255H65' />
        <path data-name='Line 80' d='M2.549 29.184h14.275' />
        <path data-name='Line 81' d='M4.588 33.772h14.275' />
        <path data-name='Line 82' d='M0 38.361h14.275' />
        <path
          data-name='Path 728'
          d='M28.294 15.42S7.901 8.792 14.02 1.146c5.1-6.119 14.274 14.274 14.274 14.274z'
        />
        <path data-name='Line 83' d='M12.49 11.341l15.804 4.078' />
        <path
          data-name='Path 729'
          d='M28.294 15.42S48.686 8.792 42.569 1.145C37.47-4.973 28.294 15.42 28.294 15.42z'
        />
        <path data-name='Line 84' d='M44.098 11.341l-15.804 4.078' />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
