/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../shared'
import { desktopVw, mobileVw, desktopBreakpoint, colors } from '../../styles'

export const Rating = ({ badge, className, score, ratingId }) => {
  return (
    <StyledRating
      badge={badge}
      href={badge && '#review-section'}
      className={`rating ${className}`}
    >
      {!ratingId &&
        [...Array(5)].map((x, i) => {
          return i < score ? (
            <StarIcon key={i} name='star' />
          ) : (
            <StarIcon key={i} className='empty' name='star' />
          )
        })}
      {/* {[...Array(5)].map((x, i) => {
        return i < 0 ? (
          <StarIcon key={i} name='star' />
        ) : (
          <StarIcon key={i} className='empty' name='star' />
        )
      })} */}

      {ratingId && (
        <span className='shopify-product-reviews-badge' data-id={ratingId}>
          <PlaceholderStars>
            <StarIcon className='empty' name='star' />
            <StarIcon className='empty' name='star' />
            <StarIcon className='empty' name='star' />
            <StarIcon className='empty' name='star' />
            <StarIcon className='empty' name='star' />
          </PlaceholderStars>
        </span>
      )}
    </StyledRating>
  )
}

const StyledRating = styled.a`
  min-height: ${mobileVw(20)};
  color: transparent;

  .spr-badge-caption {
    text-decoration: underline;
    color: #000;
    display: ${(props) =>
      props.badge ? 'inline-block !important' : 'none !important'};
  }

  .spr-badge {
    margin: 0;
  }

  .shopify-product-reviews-badge {
    position: relative;
  }

  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${desktopVw(20)};
  }

  .spr-icon {
    font-size: 120% !important;
    position: relative !important;
    top: 1px !important;
    width: ${mobileVw(14)} !important;
    height: ${mobileVw(13)} !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4xNTciIGhlaWdodD0iMTIuNTkzIiB2aWV3Qm94PSIwIDAgMTMuMTU3IDEyLjU5MyI+PHBhdGggZD0iTTI2LjM3Mi40MjUsMjQuNzY2LDMuNjgxbC0zLjU5My41MjRhLjc4Ny43ODcsMCwwLDAtLjQzNSwxLjM0M2wyLjYsMi41MzMtLjYxNSwzLjU3OGEuNzg3Ljc4NywwLDAsMCwxLjE0MS44MjlsMy4yMTQtMS42OSwzLjIxNCwxLjY5YS43ODcuNzg3LDAsMCwwLDEuMTQxLS44MjlsLS42MTUtMy41NzgsMi42LTIuNTMzYS43ODcuNzg3LDAsMCwwLS40MzUtMS4zNDNMMjkuMzksMy42ODEsMjcuNzg0LjQyNUEuNzg4Ljc4OCwwLDAsMCwyNi4zNzIuNDI1WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIwLjUgMC4wMTMpIiBmaWxsPSIjYWU4NDFmIi8+PC9zdmc+) !important;
    display: inline-block !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    margin: 0 6px 0 0 !important;

    @media (min-width: ${desktopBreakpoint}) {
      width: ${desktopVw(14)} !important;
      height: ${desktopVw(13)} !important;
    }
    &:before {
      content: none !important;
    }
    &.spr-icon-star-empty {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4xNTciIGhlaWdodD0iMTIuNTkzIiB2aWV3Qm94PSIwIDAgMTMuMTU3IDEyLjU5MyI+PHBhdGggZD0iTTI2LjM3Mi40MjUsMjQuNzY2LDMuNjgxbC0zLjU5My41MjRhLjc4Ny43ODcsMCwwLDAtLjQzNSwxLjM0M2wyLjYsMi41MzMtLjYxNSwzLjU3OGEuNzg3Ljc4NywwLDAsMCwxLjE0MS44MjlsMy4yMTQtMS42OSwzLjIxNCwxLjY5YS43ODcuNzg3LDAsMCwwLDEuMTQxLS44MjlsLS42MTUtMy41NzgsMi42LTIuNTMzYS43ODcuNzg3LDAsMCwwLS40MzUtMS4zNDNMMjkuMzksMy42ODEsMjcuNzg0LjQyNUEuNzg4Ljc4OCwwLDAsMCwyNi4zNzIuNDI1WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIwLjUgMC4wMTMpIiBmaWxsPSIjZjJmMmYyIi8+PC9zdmc+) !important;
      opacity: 1 !important;
    }

    &.spr-icon-star-half-alt {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14.077' height='13' viewBox='0 0 14.077 13'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='7.5' height='13' transform='translate(52)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cpath d='M26.372.425,24.766,3.681l-3.593.524a.787.787,0,0,0-.435,1.343l2.6,2.533-.615,3.578a.787.787,0,0,0,1.141.829l3.214-1.69,3.214,1.69a.787.787,0,0,0,1.141-.829l-.615-3.578,2.6-2.533a.787.787,0,0,0-.435-1.343L29.39,3.681,27.784.425A.788.788,0,0,0,26.372.425Z' transform='translate(-19.58 0.013)' fill='%23f2f2f2'/%3E%3Cg transform='translate(-52)' clip-path='url(%23a)'%3E%3Cpath d='M26.372.425,24.766,3.681l-3.593.524a.787.787,0,0,0-.435,1.343l2.6,2.533-.615,3.578a.787.787,0,0,0,1.141.829l3.214-1.69,3.214,1.69a.787.787,0,0,0,1.141-.829l-.615-3.578,2.6-2.533a.787.787,0,0,0-.435-1.343L29.39,3.681,27.784.425A.788.788,0,0,0,26.372.425Z' transform='translate(32.44 0.013)' fill='%23ae841f'/%3E%3C/g%3E%3C/svg%3E") !important;
      opacity: 1 !important;
    }
  }
`

const PlaceholderStars = styled.div`
  position: relative;
  display: flex;
  z-index: -1;
  height: ${mobileVw(18)};
  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(20)};
  }
`

const StarIcon = styled(Icon)`
  fill: ${colors.mailleGold};
  margin-right: ${mobileVw(3.4)};
  svg {
    height: ${mobileVw(10)};
    width: ${mobileVw(10)};
  }
  &.empty {
    fill: ${colors.mediumGrey};
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin-right: ${desktopVw(4.5)};
    svg {
      height: ${desktopVw(14)};
      width: ${desktopVw(14)};
    }
  }
`
