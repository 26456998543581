import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon, LinkType } from '../shared'
import { CollectionSlider } from '../shared/sliders'

import { useLang, useSiteQuery, useToggleMenu } from '../../hooks'

import {
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  shortDskVw,
  colors,
  fonts,
} from '../../styles'

const NavLinks = () => {
  const { headerLinks } = useSiteQuery()
  const [menu, setMenu] = useState(false)
  const [visibility, setVisibility] = useState(false)

  const handleHover = (title) => {
    setMenu(title)
    if (menu === 'Boutique en ligne' || 'Nos produits' || 'Products' || 'PRODUITS' || 'Personnalisation') {
      setVisibility(true)
    }
  }

  const lang = useLang();
  // console.log(lang)

  return (
    <>
      <Links>
        {headerLinks && headerLinks.map(({ title, optionalIcon, link, externalLink }, i) => {
          return (
            <li key={i} onMouseEnter={() => handleHover(title)}>
              {/* {(title === 'Nos produits' || title === 'Personnalisation') ? (
                <button
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                  onClick={() => setVisibility(false)}
                >
                  <Icon name={optionalIcon} />
                  <span>{title}</span>
                </button>
              ) : (
                <LinkType
                  activeClassName={title === menu ? 'active' : null}
                  to={link && link.slug ? `/${lang}${link.slug}` : `/${lang}${externalLink}`}
                  onClick={() => setVisibility(false)}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                >
                  <Icon name={optionalIcon} />
                  <span>{title}</span>
                </LinkType>
              )} */}
              <LinkType
                  activeClassName={title === menu ? 'active' : null}
                  to={link && link.slug ? `/${lang}${link.slug}` : `/${lang}${externalLink}`}
                  onClick={() => setVisibility(false)}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                >
                  <Icon name={optionalIcon} />
                  <span>{title}</span>
                </LinkType>
            </li>
          )
        })}
      </Links>
      <CarouselOverlay
        onMouseLeave={() => setVisibility(false)}
        onClick={() => setVisibility(false)}
        show={visibility}
      >
        {headerLinks && headerLinks.map(({ title, link }, i) => {
          return link && link.slides && (title === 'Boutique en ligne' || title === 'Products' || title === 'PRODUITS') ? (
            <SliderWrapper
              show={menu === title && visibility === true}
              key={i}
              center
            >
              <CollectionSlider
                titleHeading={link.title}
                slides={link.slides}
                topTitle
                productCategory
                menuCarousel
              />
            </SliderWrapper>
          ) : (title === 'Nos produits'|| title === 'Personnalisation') ? (
            <SliderWrapper show={menu === title} showButton center key={i}>
              <CollectionSlider
                titleHeading={link.title}
                slides={link.slides}
                topTitle
                topSubtitle
                optionalButtonText
                menuCarousel
              />
            </SliderWrapper>
          ) : null
        })}
      </CarouselOverlay>
    </>
  )
}

const CarouselOverlay = styled.div`
  display: ${(props) => (props.show ? 'flex' : ' none')};
`

const SliderWrapper = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    width: 100%;
    display: ${(props) => (props.show ? 'flex' : ' none')};
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    z-index: 999;
    bottom: 0;
    left: 0;
    text-align: ${(props) => (props.center ? 'center' : ' left')};

    > section {
      background-color: ${colors.black};
      padding-bottom: ${desktopVw(35)};
      position: absolute;
      width: 100%;
      height: ${desktopVw(370)};
    }

    h2,
    button {
      display: none;
    }

    button {
      :first-of-type {
        display: ${(props) => (props.showButton ? 'block' : ' none')};
      }
    }

    .swiper-container {
      padding-left: 0;
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide {
      max-width: ${desktopVw(220)};
      img {
        background-color: ${colors.black};
      }
    }

    .slider-image-wrapper {
      background-color: ${colors.black};
      max-height: ${desktopVw(180)};
      mix-blend-mode: multiply;
      img {
        object-fit: contain !important;
      }
    }

    &:hover {
      display: block;
    }
  }
`

const Links = styled.ul`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    width: 100%;
    list-style: none;
    padding: ${desktopVw(16)} ${desktopVw(35)};
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
        &:last-child{
            a{
                color:#ae841f;
            } 
        }
      a,
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        font-family: ${fonts.tivoli};
        font-size: ${desktopVw(17)};
        letter-spacing: ${letterSpacing(100)};
        line-height: ${desktopVw(16)};
        &.active,
        :hover {
          span {
            :after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              border-bottom: 1px solid #fff;
            }
          }
        }
        span {
          position: relative;
          margin: ${shortDskVw([0, 0, 0, 8])};
          padding: ${shortDskVw([8, 0, 4, 0])};
        }
      }

      svg {
        fill: ${colors.mailleGold};
      }
      button {
        cursor: pointer;
        margin: ${shortDskVw([2, 0, 0, 0])};
        padding: ${desktopVw(4)};
        background: none;
        border: none;
      }
    }
  }
`
export default NavLinks
