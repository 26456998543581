import * as React from "react";

export const ArrowPlay = props => {
  return (
    <svg width={60} height={60} viewBox="0 0 60 60" {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path
            data-name="Rectangle 586"
            transform="translate(130 2099)"
            fill="#fff"
            d="M0 0h60v60H0z"
          />
        </clipPath>
      </defs>
      <g
        data-name="Mask Group 1"
        transform="translate(-130 -2099)"
        clipPath="url(#prefix__a)"
      >
        <g transform="translate(130 2099)">
          <circle
            data-name="Ellipse 3"
            cx={30}
            cy={30}
            r={30}
            fill="#fff"
            opacity={0.701}
          />
          <path data-name="Polygon 1" d="M39 31l-14 8V23z" />
        </g>
      </g>
    </svg>
  );
};
