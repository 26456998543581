import React from 'react'
import styled from 'styled-components'

export const Lock = (props) => {
  return (
    <StyledSvg
      data-name='Group 33'
      width={15.359}
      height={19.986}
      viewBox='0 0 15.359 19.986'
      {...props}
    >
      <path
        id='Path_473'
        data-name='Path 473'
        d='M1334.655,1642.492h-.053v-1.907a6.1,6.1,0,0,0-5.4-6.144,6.377,6.377,0,0,0-1.18.018v-.014a5.855,5.855,0,0,0-2.542.876l-.033.021-.095.062a5.976,5.976,0,0,0-2.717,4.44s-.015.494-.015.745l-.053,1.9h-.144a1.593,1.593,0,0,0-1.589,1.589v8.739a1.593,1.593,0,0,0,1.589,1.589H1334.6a1.593,1.593,0,0,0,1.589-1.589v-8.739A1.549,1.549,0,0,0,1334.655,1642.492Zm-5.137,6.355a.377.377,0,0,0-.159.318v1.907a.741.741,0,0,1-1.483,0v-1.907a.377.377,0,0,0-.159-.318,1.488,1.488,0,0,1-.477-1.8,1.564,1.564,0,0,1,1.642-.847,1.431,1.431,0,0,1,1.218,1.43A1.584,1.584,0,0,1,1329.518,1648.847Zm2.436-6.355h-6.688v-1.956a3.433,3.433,0,0,1,2.913-3.443v-.013a3.365,3.365,0,0,1,.863.009,3.433,3.433,0,0,1,2.913,3.443Z'
        transform='translate(-1320.832 -1634.422)'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
