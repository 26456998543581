import React from 'react'
import styled from 'styled-components'

export const ApplePay2 = (props) => {
  return (
    <StyledSvg
      data-name='Group 33'
      width={47.737}
      height={20.222}
      viewBox='0 0 47.737 20.222'
      {...props}
    >
      <path
        id='Path_443'
        data-name='Path 443'
        d='M261.293,1615.971a2.9,2.9,0,0,1-2.35,1.145,3.569,3.569,0,0,1,3.17-3.752,3.729,3.729,0,0,1-.821,2.607m.811,1.328c-1.3-.077-2.406.76-3.021.76s-1.567-.722-2.592-.7a3.816,3.816,0,0,0-3.254,2.04c-1.4,2.482-.364,6.157.988,8.177.662,1,1.455,2.1,2.5,2.059.988-.038,1.38-.664,2.574-.664s1.548.664,2.592.645c1.082-.019,1.762-1,2.424-2a8.906,8.906,0,0,0,1.082-2.3,3.611,3.611,0,0,1-2.107-3.3,3.711,3.711,0,0,1,1.706-3.1,3.658,3.658,0,0,0-2.891-1.616m7.488-2.79v15h2.257v-5.127h3.124a4.687,4.687,0,0,0,4.858-4.945,4.633,4.633,0,0,0-4.784-4.926Zm2.257,1.963h2.6a2.979,2.979,0,1,1-.009,5.955h-2.592Zm12.1,13.151a3.712,3.712,0,0,0,3.329-1.914h.047v1.8h2.089v-7.465c0-2.165-1.678-3.559-4.261-3.559-2.4,0-4.168,1.414-4.233,3.358h2.033a1.988,1.988,0,0,1,2.135-1.53c1.38,0,2.154.664,2.154,1.885v.827l-2.816.173c-2.62.164-4.038,1.27-4.038,3.194A3.238,3.238,0,0,0,283.952,1629.622Zm.606-1.78c-1.2,0-1.968-.6-1.968-1.51,0-.943.737-1.491,2.145-1.578l2.508-.164v.847A2.481,2.481,0,0,1,284.558,1627.842Zm7.646,5.743c2.2,0,3.236-.866,4.14-3.492l3.963-11.467h-2.294l-2.658,8.86h-.047l-2.658-8.86h-2.359l3.823,10.919-.205.664c-.345,1.126-.9,1.558-1.9,1.558-.177,0-.522-.019-.662-.038v1.8A6.031,6.031,0,0,0,292.2,1633.586Z'
        transform='translate(-252.571 -1613.364)'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
