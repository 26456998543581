export const useCountryRedirectAnalytics = (country) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.trackEvent,
        eventAction: ctConstants.languageSelector,
        eventLabel: country,
        eventValue: 1,
      },
      category: { primaryCategory: ctConstants.custom },
      subcategory: 'Read',
    })
    console.log(window.digitalData)
  }
}
