import { useLocale } from '../index'

export const useLang = () => {
  const locale = useLocale()

  let lang
  if (locale === 'fr') {
    lang = 'fr'
  } else if (locale === 'en-CA') {
    lang = 'ca/en'
  } else if (locale === 'fr-CA') {
    lang = 'ca/fr'
  }
  else {
    lang = 'de'
  }

  return lang
}
