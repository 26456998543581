import { decode } from 'shopify-gid'

export const useRemoveItemFromCartAnalytics = async (
  newId,
  amount,
  title,
  quantity,
) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    const id = newId ? decode(newId).id : 'unset'
    const price = amount
    const variant = title
    window.digitalData.cart.item.push({
      productInfo: {
        productID: id,
        productName: title,
        price,
        brand: 'Maille',
        quantity,
      },
      category: {
        primaryCategory: 'Product',
      },
      pCAT: {
        name: 'shs',
        level: '2',
      },
      attributes: {
        productVariants: variant,
        listPosition: 4,
      },
    })

    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.removecart,
      },
      category: { primaryCategory: ctConstants.conversion },
      subcategory: 'Diversion',
    })
    console.log(window.digitalData)
  }
}
