import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import Swiper from 'react-id-swiper'
import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'

import { LinkType } from './LinkType'
import { ArrowDown } from './icons'
import 'swiper/css/swiper.css'

export const FeaturedArticlesBlock = (props) => {
  const [swiper, updateSwiper] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const [showAll, setShowAll] = useState(false)

  const [openMenu, setMenu] = useState(false)

  let articlesToDisplay = []

  const params = {
    getSwiper: updateSwiper,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 30,
  }

  if (showAll) {
    articlesToDisplay = props.articles
  } else {
    props.articles.map((article, i) =>
      i <= 2 ? articlesToDisplay.push(article) : null,
    )
  }

  const updateArticlesDisplayed = () => {
    if (showAll) {
      articlesToDisplay = props.articles
    } else {
      props.articles.map((article, i) =>
        i <= 2 ? articlesToDisplay.push(article) : null,
      )
    }
  }

  useEffect(() => {
    updateArticlesDisplayed(), [showAll]
  })

  return (
    <Wrapper>
      {/* Remove Categories Button and text below the visual for the
      article section optimization */}
      {/* <div style={{ backgroundColor: "#FFFF", color: '#AE841F' }}> */}
      <div>
        <StyledTitle>L’expertise Maille</StyledTitle>
        <Container>
          {/* <MenuButton onClick={() => setMenu(!openMenu)}>
            catégories
            <StyledArrow />
          </MenuButton> */}
          {/* <Menu display={!!openMenu}>
            {articlesToDisplay.map((article, i) => (
              <Option key={i} to={`/fr${article.slug}`}>
                {article.category}
              </Option>
            ))}
          </Menu> */}
        </Container>
        <Swiper {...params} >
          {articlesToDisplay.map((article, i) => (
            <div key={i}>
              <LinkType
                to={`/fr${article.slug}`}
                alt={`Ce lien va ouvrir une nouvelle fenêtre: ${article.title}`}
              >
                <StyledImage
                  fluid={article.thumbnailImage.fluid}
                  alt={article.thumbnailImage.title}
                />
                {/* <InfoWrapper>
                  <StyledSpan>{article.author}</StyledSpan>
                  <StyledSpan style={{ color: `${colors.white}` }}>
                    {article.date}
                  </StyledSpan>
                </InfoWrapper> */}
                <Title>{article.title}</Title>
                {/* <Subtitle>{article.subtitle}</Subtitle> */}
                <Button>lire la suite</Button>
              </LinkType>
            </div>
          ))}
        </Swiper>
      </div>
      {props.articles.length > 3 ? (
        <StyledButton display={showAll} onClick={() => setShowAll(!showAll)}>
          Voir plus d'articles
        </StyledButton>
      ) : null}
    </Wrapper>
  )
}

const StyledLink = styled(LinkType)`
  color: ${colors.white};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(1.6)};
  margin-right: ${mobileVw(10)};
  white-space: nowrap;
  cursor: pointer;
  padding: ${mobileVw(2)} 0;
  margin-bottom: ${mobileVw(12)};
  border-bottom: solid ${mobileVw(1)} ${colors.black};

  @media (min-width: ${desktopBreakpoint}) {
    letter-spacing: ${desktopVw(2.2)};
    margin-right: ${desktopVw(20)};
    font-size: ${desktopVw(22)};
    line-height: ${desktopVw(24)};
    padding-bottom: ${desktopVw(2)};
    margin-bottom: ${desktopVw(10)};
    border-bottom: solid ${desktopVw(1)} ${colors.black};
  }
`

const Wrapper = styled.div`
  padding: ${mobileVw(30)} 0 0;
  background-color: #FFFF;
  display: flex;
  flex-direction: column;
  position: relative;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: ${mobileVw(570)};
    position: relative;
  }

  .swiper-slide-active {
    ${StyledLink} {
      border-bottom: solid ${mobileVw(1)} ${colors.white};
    }
  }

  .swiper-container {
    padding: 0 ${mobileVw(16)};
    .swiper-button-prev {
      transform: rotate(180deg);
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev.swiper-button-disabled {
      background-color: ${colors.lightGrey};
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: ${colors.mailleGold};
      display: flex;
      width: ${mobileVw(35)};
      height: ${mobileVw(60)};
      z-index: 999;

      &:after {
        content: '';
        height: 15px;
        width: 15px;
      }

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy43OTQ5IDcuNTI5OTZIMFY2LjUyOTk2SDEzLjc5MjhMNy45NzA0MyAwLjcwNzU0OUw4LjY3NzU0IDAuMDAwNDQyNTA1TDE1LjcwODEgNy4wMzFMOC42Nzc1NCAxNC4wNjE2TDcuOTcwNDMgMTMuMzU0NEwxMy43OTQ5IDcuNTI5OTZaIiBmaWxsPSJibGFjayIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwIj4KPHJlY3Qgd2lkdGg9IjE1LjcwNyIgaGVpZ2h0PSIxNC4wNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }

    .swiper-button-next {
      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy43OTQ5IDcuNTI5OTZIMFY2LjUyOTk2SDEzLjc5MjhMNy45NzA0MyAwLjcwNzU0OUw4LjY3NzU0IDAuMDAwNDQyNTA1TDE1LjcwODEgNy4wMzFMOC42Nzc1NCAxNC4wNjE2TDcuOTcwNDMgMTMuMzU0NEwxMy43OTQ5IDcuNTI5OTZaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwIj4KPHJlY3Qgd2lkdGg9IjE1LjcwNyIgaGVpZ2h0PSIxNC4wNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(50)} 0 0;

    .swiper-container {
      width: 100%;
      padding: 0;
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
    .swiper-slide {
      max-width: ${desktopVw(350)};
      min-height: ${desktopVw(590)};
    }

    .swiper-slide-active {
      ${StyledLink} {
        border-bottom: solid ${desktopVw(1)} ${colors.white};
      }
    }
  }
`

const StyledTitle = styled.h2`
  color: ${colors.mailleGold};
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  padding-left: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    padding-left: 0;
  }
`

const StyledImage = styled(Image)`
  height: 100vw;

  @media (min-width: ${desktopBreakpoint}) {
    width: 100%;
    height: ${desktopVw(284)};
  }
`

const InfoWrapper = styled.div`
  display: flex;
`

const StyledSpan = styled.span`
  color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(14)};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(1.4)};
  padding: ${mobileVw(14)} 0;

  &:first-of-type {
    margin-right: ${mobileVw(10)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: ${desktopVw(16)};
    padding: ${desktopVw(15)} 0;

    &:first-of-type {
      margin-right: ${desktopVw(10)};
    }
  }
`

const Title = styled.h3`
  color: ${colors.white};
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(18)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(24)};
  }
`

const Subtitle = styled.h3`
  color: ${colors.white};
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(20)};
  padding: ${mobileVw(5)} 0 ${mobileVw(8)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    padding: ${desktopVw(10)} 0 ${desktopVw(22)};
  }
`

const Button = styled.button`
  width: 100%;
  color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(16)};
  letter-spacing: ${mobileVw(1.6)};
  text-transform: uppercase;
  background-color: inherit;
  border: solid ${mobileVw(1)} ${colors.mailleGold};
  padding: ${mobileVw(10)} 0;
  cursor: pointer;
  position: absolute;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: ${desktopVw(16)};
    border: solid ${desktopVw(1)} ${colors.mailleGold};
    padding: ${desktopVw(18)} 0;
    bottom:168px
  }
`

const Option = styled(LinkType)`
  cursor: pointer;
  display: block;
  font-size: ${mobileVw(18)};
  font-family: ${fonts.tivoli};
  text-transform: uppercase;
  margin-top: ${mobileVw(10)};
  border-radius: ${mobileVw(10)};
  background-color: ${colors.white};
  color: ${colors.black};
  padding: ${mobileVw(4)};

  :hover {
    color: ${colors.mailleGold};
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(22)};
    margin-right: ${desktopVw(15)};
    padding: ${desktopVw(4)};
    max-width: ${desktopVw(350)};
    margin-top: ${desktopVw(10)};
  }
`

const MenuButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(20)};
  letter-spacing: ${mobileVw(2)};
  text-transform: uppercase;
  background-color: ${colors.mailleGold};
  padding: ${mobileVw(10)} 0;
  cursor: pointer;
  display: ${(props) => (props.display ? 'none' : ' block')};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.8)};
    line-height: ${desktopVw(16)};
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
    margin: 0 auto 0 0;
  }
`

const Menu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['display'].includes(prop),
})`
  display: ${(props) => (props.display ? `block` : `none`)};
  color: ${colors.white};
  @media (min-width: ${desktopBreakpoint}) {
    padding-left: 0;
    margin: ${desktopVw(15)} 0;
`

const StyledArrow = styled(ArrowDown)`
  width: ${mobileVw(8)};
  height: auto;
  stroke: ${colors.black};
  margin-left: ${mobileVw(5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(8)};
    margin-left: ${desktopVw(15)};
    margin-bottom: ${desktopVw(2)};
  }
`

const Container = styled.div`
  padding: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(20)} 0;
  }
`

const StyledButton = styled.button`
  margin-top: ${mobileVw(10)};
  margin-left: ${mobileVw(16)};
  width: ${mobileVw(288)};
  background-color: ${colors.mailleGold};
  padding: ${mobileVw(10)} 0;
  text-transform: uppercase;
  font-size: ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(1.4)};
  color: ${colors.white};
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`
