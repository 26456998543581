import React from 'react'
// import { Link } from "gatsby";
import styled from 'styled-components'
import { Icon } from './Icon'
import {
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  mobileVw,
  colors,
} from '../../styles'

export const Social = ({ social, className }) => {
  return (
    <StyledSocial className={className} aria-label='Social links'>
      <Title>{social.title}</Title>
      {social.share.map((item, i) => {
        return (
          <a
            href={`${social.links[i]}`}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${social.title}`}
            key={i}
            target= '_blank'
            rel='noopener noreferrer'
          >
            <span>ouvrir lien dans une nouvelle fenêtre</span>
            <StyledIcons name={item.toLowerCase()} />
          </a>
        )
      })}
    </StyledSocial>
  )
}

const StyledSocial = styled.section`
  /* padding-left: ${mobileVw(16)}; */
    position:relative;
  a {
    margin: 0 ${mobileVw(37)} 0 0;
    height: ${mobileVw(24)};
    width: auto;
  }
  & a span {
    position: absolute;
    left: -9000px;
    width: 0;
    overflow: hidden;
  }
  & a:hover span {
    display: block;
    position: absolute;
    top: 22px;
    left: 1em;
    width: 15em;
    border: 1px solid #0cf;
    background-color: #fff;
    color: #000;
    text-align: center;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding-left: ${desktopVw(128)};

    a {
      margin: 0 ${desktopVw(50)} 0 0;
      height: ${desktopVw(32)};
    }
  }
`

const Title = styled.h2`
  font-family: 'Tivoli bold';
  font-size: ${mobileVw(20)};
  text-transform: uppercase;
  letter-spacing: ${letterSpacing(100)};
  margin: 0 0 ${mobileVw(12)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(21)};
    font-size: ${desktopVw(28)};
    line-height: ${desktopVw(24)};
  }
`

const StyledIcons = styled(Icon)`
  fill: ${colors.mailleGold};
  width: auto;
  height: auto;
`

