import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {
  colors,
  desktopBreakpoint,
  desktopVw,
  fonts,
  letterSpacing,
  mobileVw,
} from '../../styles'
import { Icon } from './Icon'

export const NewsletterWDPopup = ({ image, setPopupOpen }) => {
  return (
    <Container>
      <Overlay onClick={() => setPopupOpen(false)} />
      <Wrapper>
        {image && image.fluid && <Image fluid={image.fluid} />}
        <TextAndDownload>
          <Title>
            <span>Merci d’explorer </span>
            <span>nos recettes !</span>
          </Title>
          <ButtonWrapper>
            <DownloadButton
              href='https://cdn.shopify.com/s/files/1/2334/9801/files/Maille_FR_Veganuary_RecipeBook.pdf?v=1609863915'
              target='_blank'
            >
              Téléchargez notre livre de recette
            </DownloadButton>
          </ButtonWrapper>
          {/* <DiscountText>
            <p>
              Bénéficiez de <span>-20%</span>
            </p>
            <p>
              avec le code <span>MailleVeganuary</span>
            </p>
            <p>
              sur le site maille.com
            </p>
          </DiscountText>
          <DiscountNotice>Valable jusqu’au 5 février 2021 </DiscountNotice> */}
          <InstagramWrapper>
            <InstaTitle>Partagez-nous vos créations</InstaTitle>
            <ButtonWrapper>
              <InstaButton href='https://www.instagram.com/maisonmaille/?hl=en'>
                <Icon name='instagram' />
                <span>Rejoignez-nous</span>
              </InstaButton>
            </ButtonWrapper>
          </InstagramWrapper>
        </TextAndDownload>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // Tablet fitting
    @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
      padding: ${mobileVw(6)} 0;
      font-size: ${mobileVw(10)};
      line-height: ${mobileVw(10)};
    }
  }

  svg {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
      height: ${mobileVw(10)};
      margin-right: ${mobileVw(2)};
    }
  }
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  z-index: 999999;
  background: ${colors.white};
  max-width: 90vw;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    width: 80vw;
  }
`

const Image = styled(Img)`
  height: ${mobileVw(184)};
  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(500)};
    width: 50%;
  }
`

const TextAndDownload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${mobileVw(25)};
  font-family: ${fonts.mrs};
  p {
    text-align: center;
  }
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
    padding: ${mobileVw(15)} ${mobileVw(20)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(60)} ${desktopVw(30)} 0;
  }
`

const Title = styled.h2`
  font-family: ${fonts.splandor};
  span {
    font-size: ${mobileVw(30)};
    &:first-child {
      color: ${colors.mailleGold};
    }
    &:last-child {
      color: ${colors.black};
    }
  }

  // Tablet fitting
  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
    span {
      font-size: ${mobileVw(18)};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    span {
      font-size: ${desktopVw(44)};
    }
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(300)};
  }
`

const DownloadButton = styled.a`
  margin: ${mobileVw(20)} 0;
  cursor: pointer;
  font: inherit;
  width: 100%;
  padding: ${mobileVw(15)} 0;
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(16)};
  text-transform: uppercase;
  background: ${colors.mailleGold};
  color: #fff;
  border: 1px solid ${colors.mailleGold};
  font-family: 'Tivoli Bold', sans-serif;
  letter-spacing: ${letterSpacing(100)};
  :disabled {
    color: #000;
    background: #fafafa;
    border: 1px solid #707070;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(20)} 0;
    padding: ${desktopVw(16)} 0 ${desktopVw(12)} 0;
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    border: 1px solid ${colors.mailleGold};
    width: ${desktopVw(300)};
  }
`

const DiscountText = styled.div`
  margin: 0;

  p {
    margin: 0;
    line-height: ${mobileVw(20)};
  }

  span {
    font-size: ${mobileVw(18)};
    font-weight: bold;
    font-family: ${fonts.splandor};
  }

  // Tablet fitting
  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
    p {
      font-size: ${mobileVw(12)};
    }
    
    span {
      font-size: ${mobileVw(14)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    p {
      line-height: ${desktopVw(24)};
      font-size: ${desktopVw(22)};
    }

    span {
      font-size: ${desktopVw(24)};
    }
  }
`

const DiscountNotice = styled.p`
  font-size: ${mobileVw(12)};
  line-height: ${mobileVw(12)};
  margin: ${mobileVw(10)} 0;
  // Tablet fitting
  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: ${mobileVw(8)};
    line-height: ${mobileVw(8)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(16)};
    margin: ${desktopVw(10)} 0;
  }
`

const InstagramWrapper = styled.div`
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${desktopVw(50)};
  }
`

const InstaTitle = styled.h3`
  text-align: center;
`

const InstaButton = styled.a`
  margin: ${mobileVw(10)} 0 0;
  cursor: pointer;
  font: inherit;
  width: 100%;
  padding: ${mobileVw(15)} 0;
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(14)};
  text-transform: uppercase;
  background: ${colors.mailleGold};
  color: #fff;
  border: 1px solid ${colors.mailleGold};
  font-family: 'Tivoli Bold', sans-serif;
  letter-spacing: ${letterSpacing(100)};
  :disabled {
    color: #000;
    background: #fafafa;
    border: 1px solid #707070;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(10)} 0 0;
    padding: ${desktopVw(16)} 0 ${desktopVw(12)} 0;
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    border: 1px solid ${colors.mailleGold};
    width: ${desktopVw(300)};
  }

  svg {
    fill: ${colors.white};
    height: ${mobileVw(16)};
    margin-right: ${mobileVw(10)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-right: ${desktopVw(10)};
      height: ${desktopVw(16)};
    }
  }
`
