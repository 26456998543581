import React from 'react'
import styled from 'styled-components'

export const LevelOfDifficulty2 = props => {
  return (
    <StyledSvg viewBox='0 0 25.547 10.096' {...props}>
      <g data-name='Group 1518' fill={props.color}>
        <path d='M4.708.352L3.42 2.962l-2.881.42A.631.631 0 00.19 4.459L2.274 6.49l-.493 2.869a.631.631 0 00.915.664l2.577-1.354 2.577 1.354a.631.631 0 00.915-.664L8.272 6.49l2.084-2.031a.631.631 0 00-.349-1.077l-2.881-.42L5.839.352a.631.631 0 00-1.131 0z' />
        <path
          data-name='star'
          d='M19.708.352l-1.288 2.61-2.881.42a.631.631 0 00-.349 1.077l2.084 2.031-.493 2.869a.631.631 0 00.915.664l2.577-1.354 2.577 1.354a.631.631 0 00.915-.664l-.493-2.869 2.084-2.031a.631.631 0 00-.349-1.077l-2.881-.42-1.287-2.61a.631.631 0 00-1.131 0z'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
