import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Gift = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      viewBox='0 0 14.694 21.961'
      {...props}
    >
      <g
        fill='none'
        stroke='#ae841f'
        strokeMiterlimit='10'
        strokeWidth='1'
        data-name='Group 638'
        transform='translate(-127 -75.458)'
      >
        <g data-name='Rectangle 497' transform='translate(127 83.419)'>
          <path stroke='none' d='M0 0H14.694V14H0z'></path>
          <path d='M0.5 0.5H14.194V13.5H0.5z'></path>
        </g>
        <path
          d='M0 0L0 12.581'
          data-name='Line 50'
          transform='translate(134.347 84.085)'
        ></path>
        <path
          d='M14.512 0L0 0'
          data-name='Line 51'
          transform='translate(127 90.387)'
        ></path>
        <ellipse
          cx='2.094'
          cy='3.915'
          data-name='Ellipse 15'
          rx='2.094'
          ry='3.915'
          transform='rotate(-45 159.485 -114.875)'
        ></ellipse>
        <ellipse
          cx='3.915'
          cy='2.094'
          data-name='Ellipse 16'
          rx='3.915'
          ry='2.094'
          transform='rotate(-45 164.75 -118.795)'
        ></ellipse>
      </g>
    </StyledSvg>
  )
}
const StyledSvg = styled.svg`
  width: ${mobileVw(14.69)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(14.69)};
    &.sticky-nav-icon {
      width: ${desktopVw(12)};
    }
  }
`
