import React from 'react'
import styled from 'styled-components'

import { mobileVw, desktopVw, desktopBreakpoint } from '../../styles'

export const Space = ({ s, l }: { s?: number; l?: number }) => {
  const small = s ? 10 * s : null
  const large = l ? 20 * l : null
  return <StyledSpace spaceMobile={small} spaceDesktop={large} />
}

const StyledSpace = styled.div`
  display: block;
  width: 100%;
  padding-top: ${(props: any) =>
    props.spaceMobile ? mobileVw(props.spaceMobile) : 0};
  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${(props: any) =>
      props.spaceDesktop ? desktopVw(props.spaceDesktop) : 0};
  }
`
