import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'
import { Video } from './Video'
import { Button } from './Button'
import { LinkType } from './LinkType'
import { useDictionaryQuery, useLang, useLocale } from '../../hooks'

export const FeaturedItemBlock = ({
  id,
  season,
  title,
  data,
  hideButton,
  hideSubtitle,
  link,
  alt,
}) => {
  const lang = useLang();
  const { viewMoreArticles, viewMoreRecipesSlug } = useDictionaryQuery();

  const randomData = useStaticQuery(graphql`
    query {
      allContentfulPageRecipe {
        nodes {
          node_locale
          noPage
          thumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          images {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          seasonText
          id
          title
          slug
        }
      }
    }
  `)

  const locale = useLocale()

  const recipes = []
  randomData.allContentfulPageRecipe.nodes.map((obj) => {
    if (obj && obj.node_locale === locale && obj.title && obj.thumbnailImage && obj.slug && obj.thumbnailImage.fluid && obj.noPage != true && obj.slug[0] === "/") {
      recipes.push(obj)
    }
  })
  const sameSeasonRecipes = []
  recipes.map((recipe) => {
    return recipe?.seasonText &&
      season && recipe.seasonText.some((a) => season.indexOf(a) >= 0) &&
      recipe.id !== `${id}`
      ? sameSeasonRecipes.push(recipe)
      : null
  })

  const randomRecipe =
    sameSeasonRecipes[
    Math.floor(Math.random() * Math.floor(sameSeasonRecipes.length))
    ]

  const moreSlug = viewMoreRecipesSlug ? viewMoreRecipesSlug : '/cuisinez-chaque-saveur'
  return (

    <Wrapper>
      {title && <Title>
        {typeof title === 'string'
          ? title
          : title.map((word, i) => <span key={i}>{word}</span>)}
      </Title>}
      {(id && season && randomRecipe) || (data && data.featuredItem.thumbnailImage) ? (
        <ImageWrapper>
          {(id && season && randomRecipe) ? (
            <LinkType to={link || `/${lang}${randomRecipe.slug}`}>
              {randomRecipe.thumbnailImage.fluid && (
                <StyledImage
                  className='featured-image'
                  fluid={randomRecipe.thumbnailImage.fluid}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${randomRecipe.title}`}
                />
              )}
            </LinkType>
          ) : (
            <LinkType to={link && link} alt={alt && alt}>
              <StyledImage
                className='featured-image'
                fluid={data.featuredItem.thumbnailImage.fluid}
                alt={`Ce lien va ouvrir une nouvelle fenêtre: ${data.featuredItem.thumbnailImage.title}`}
              />
            </LinkType>
          )}
        </ImageWrapper>
      ) : (
        <>

          {data && <StyledVideo controls poster={data.featuredItem?.poster?.fluid}  mp4={data.featuredItem.videoMp4.file.url} track = {data.featuredItem.videoTrackFile}/>}

        </>
      )}
      <Subtitle className='subtitle-section' hideSubtitle={!!hideSubtitle}>
        {id && season ? randomRecipe && randomRecipe.title : (data && data.featuredItem?.title)}
      </Subtitle>

      {data && data.buttonText ? (
        <StyledButton hideButton={!!hideButton} text={data.buttonText} />
      ) : (
        <LinkType to={`/${lang}${moreSlug}`} alt='recipe landing page'>
          <StyledButton
            hideButton={!!hideButton}
            text={`${viewMoreArticles}`}
          />
        </LinkType>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    margin: auto;
  }
`

const Title = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  color: ${colors.black};
  line-height: ${mobileVw(40)};
  padding: 0;

  span {
    &:first-child {
      color: ${colors.mailleGold};
      padding-right: ${mobileVw(8)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(5)} 0;
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(32)};
    text-align: left;

    span {
      display: inline;
      &:first-child {
        padding-right: ${desktopVw(8)};
      }
    }
  }
`

const Subtitle = styled.span`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(18)};
  display: ${(props) => (props.hideSubtitle ? 'none' : ' block')};
  padding: 0 0 ${mobileVw(8)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(8)} 0;
    font-size: ${desktopVw(24)};
  }
`

const ImageWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    /* display: none; */
    /* height: ${desktopVw(528)}; */
    padding: ${desktopVw(8)} 0;
  }
`

const StyledImage = styled(Image)`
  @media (min-width: ${desktopBreakpoint}) {
    max-height: ${desktopVw(530)};
  }
`

const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['hideButton'].includes(prop),
})`
  display: ${(props) => (props.hideButton ? 'none' : ' block')};
  margin-top: ${mobileVw(8)};

  @media (min-width: ${desktopBreakpoint}) {
    display: ${(props) => (props.hideButton ? 'none' : ' block')};
    margin: ${desktopVw(5)} auto 0;
    max-width: ${desktopVw(350)};
  }
`

const StyledVideo = styled(Video)`
  margin: 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
  }
`
