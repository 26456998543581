import React from 'react'
import styled from 'styled-components'

export const Twitter = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      width='32.949'
      height='26.77'
      viewBox='0 0 32.949 26.77'
      {...props}
    >
      <path
        fill='#ae841f'
        d='M29.572 6.667c.012.29.018.579.018.872 0 8.93-6.8 19.23-19.233 19.23A19.121 19.121 0 010 23.732a14.106 14.106 0 001.613.091 13.538 13.538 0 008.393-2.891 6.773 6.773 0 01-6.313-4.694 6.97 6.97 0 001.272.119 6.73 6.73 0 001.781-.238A6.76 6.76 0 011.324 9.5v-.088a6.758 6.758 0 003.062.845A6.77 6.77 0 012.3 1.232 19.167 19.167 0 0016.226 8.3a6.765 6.765 0 0111.52-6.164A13.488 13.488 0 0032.04.491a6.783 6.783 0 01-2.971 3.739 13.556 13.556 0 003.88-1.061 13.715 13.715 0 01-3.37 3.5h-.006z'
        data-name='Path 34'
      ></path>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
