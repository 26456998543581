import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Youtube = (props) => {
    return (
        <StyledSvg
            width={32.387}
            height={32.387}
            viewBox='0 0 32.387 32.387'
            {...props}
        >
            <path
                id="Icon_simple-youtube"
                d="M31.1,3.6c-0.4-1.3-1.4-2.4-2.7-2.7C25.9,0.1,16,0.1,16,0.1s-9.9,0-12.4,0.7
        C2.3,1.2,1.3,2.2,0.9,3.6c-0.5,2.5-0.7,5.1-0.7,7.6c0,2.6,0.2,5.1,0.7,7.6c0.4,1.3,1.4,2.4,2.7,2.7C6.1,22.2,16,22.2,16,22.2
        s9.9,0,12.4-0.7c1.3-0.4,2.4-1.4,2.7-2.7c0.5-2.5,0.7-5.1,0.7-7.6C31.8,8.6,31.6,6.1,31.1,3.6L31.1,3.6z M12.9,15.9V6.5l8.2,4.7
        L12.9,15.9z"
                fill={props.color}
            />
        </StyledSvg>
    )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(18)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(16)};
    &.sticky-nav-icon {
      width: ${desktopVw(12)};
    }
  }
`