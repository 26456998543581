import * as React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const Accounts = props => {
  return (
    <Svg width={17.005} height={14.311} viewBox='0 0 17.005 14.311' {...props}>
      <g
        data-name='Group 1196'
        transform='translate(-48.089 -914)'
        fill='none'
        stroke='#ae841f'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          data-name='Path 1408'
          d='M61.135 917.925a2.1 2.1 0 11-4.2 0v-1.245a2.1 2.1 0 114.2 0z'
        />
        <circle
          data-name='Ellipse 60'
          cx={1.752}
          cy={1.752}
          r={1.752}
          transform='translate(51.68 914.85)'
        />
        <path
          data-name='Path 1409'
          d='M55.2 922.306a4.2 4.2 0 00-6.591 2.6.693.693 0 00.694.806h3.433'
        />
        <path
          data-name='Path 1410'
          d='M59.037 922.207h0a5.606 5.606 0 00-5.549 4.808.7.7 0 00.7.8h9.7a.7.7 0 00.7-.8 5.606 5.606 0 00-5.551-4.808z'
        />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: ${mobileVw(16)};
  height: ${mobileVw(13.31)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32.04)};
    height: ${desktopVw(26.65)};
  }
`
