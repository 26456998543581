export const useSearchAnalytics = (search, matches) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.trackEvent,
        eventAction: ctConstants && ctConstants.siteSearch,
        eventLabel: `${search}-${matches}`,
        eventValue: 1,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Interest',
    })
  }
  console.log(window.digitalData)
}
