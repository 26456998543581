import { decode } from 'shopify-gid'

export const useAddToCartTracking = (title: string, shopifyProduct?: any) => {
  const id = shopifyProduct
    ? decode(shopifyProduct.variants.edges[0].node.id).id
    : 'unset'
  const price = shopifyProduct && shopifyProduct.variants.edges[0].node.price
  const variant = shopifyProduct && shopifyProduct.variants.edges[0].node.title

  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.cart.item.push({
      productInfo: {
        productID: id,
        productName: title,
        price,
        brand: 'Maille',
        quantity: 1,
      },
      category: {
        primaryCategory: shopifyProduct ? 'Product' : 'Classic product',
      },
      pCAT: {
        name: 'shs',
        level: '2',
      },
      attributes: {
        productVariants: variant,
        listPosition: 4,
      },
    })

    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants?.addtoCart,
      },
      category: { primaryCategory: ctConstants.conversion },
      subcategory: 'Lead',
    })
  }
}
