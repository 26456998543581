import * as React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const Information = props => {
  return (
    <Svg width={17} height={17} viewBox='0 0 17 17' {...props}>
      <g
        data-name='Group 1201'
        transform='translate(-651.5 -867.5)'
        stroke='#ae841f'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      >
        <circle
          data-name='Ellipse 63'
          cx={8}
          cy={8}
          r={8}
          transform='translate(652 868)'
        />
        <path data-name='Path 1422' d='M660 881v-6.875' />
        <circle
          data-name='Ellipse 64'
          cx={0.625}
          cy={0.625}
          r={0.625}
          transform='translate(659.375 871)'
        />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32)};
    height: ${desktopVw(32)};
  }
`
