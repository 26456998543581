import React from 'react'
import styled from 'styled-components'

export const Facebook = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      width='15.054'
      height='32.387'
      viewBox='0 0 15.054 32.387'
      {...props}
    >
      <path
        fill='#ae841f'
        d='M14.463 16.205H9.925v16.182H3.2V16.205H0v-5.716h3.2v-3.7C3.2 4.139 4.457 0 9.985 0l4.982.021v5.552h-3.615a1.37 1.37 0 00-1.427 1.556v3.362h5.129l-.591 5.714z'
        data-name='Path 32'
      ></path>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
