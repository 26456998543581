import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocale } from '../../hooks'

export const SEO = ({
  title,
  description,
  image,
  location,
  pageType,
  googleSiteVerificationId,
  schemas,
}: {
  title: string
  description?: string
  image?: string
  location?: any
  product
  pageType
  googleSiteVerificationId: any
  schemas
}) => {
  const locale = useLocale()
  let digitalData
  if (locale === 'fr') {
    function isMobile() {
      let check = false
      if (typeof window !== 'undefined') {
        ;(function (a) {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4),
            )
          ) {
            check = true
          }
        })(navigator.userAgent || navigator.vendor || window.opera)
      }
      return check
    }
    //
    let channelVal
    if (isMobile()) {
      channelVal = 'Mobile Site'
    } else {
      channelVal = 'Brand Site'
    }
    digitalData = {
      siteInfo: {
        channel: '',
        sitetype: 'Shopify/Gatsby',
      },
      page: {
        pageInfo: {
          destinationURL: location?.pathname, // Changes the Value
        },
        category: {
          pageType: pageType || 'undefined',
        },
      },
      video: [],
      campaign: [],
      product: [],
      privacy: {
        accessCategories: [
          {
            domains: [],
          },
        ],
      },
      promotion: [],
      cart: { item: [] },
    }
    digitalData.siteInfo.channel = channelVal
    digitalData.page.category.primaryCategory = channelVal
    digitalData.trackingInfo = {}
    digitalData.trackingInfo.tool = [{}]
    digitalData.trackingInfo.tool[0] = {}
    digitalData.trackingInfo.tool[1] = {}
    digitalData.privacy = {} // Optional
    digitalData.page.attributes = {}
    digitalData.privacy.accessCategories = [{}] // Optional
    digitalData.privacy.accessCategories[0].domains = [] // Optional
    digitalData.event = []
    digitalData.page.pageInfo.pageName = title // To be set on each page change
    digitalData.page.pageInfo.language = 'fr' // Update the value
    // digitalData.page.category.subCategory1 = 'SITE SECTION 1'
    // digitalData.page.category.subCategory2 = 'SITE SECTION 2'
    // digitalData.page.category.subCategory3 = 'SITE SECTION 3'
    digitalData.page.attributes.contentType = pageType // To be set on each page change. Should contain 404 for error pages
    // digitalData.page.attributes.articleName = 'ARTICLE NAME' //Populate only on Article pages
    digitalData.page.attributes.brandCategory = 'Foods'
    digitalData.page.attributes.country = 'France'
    digitalData.page.attributes.globalBrand = 'Maille'
    digitalData.page.attributes.localBrand = 'Maille'
    // digitalData.user[n].profile[n].profileInfo.crmID = 'CRM ID' //Optional
    // digitalData.trackingInfo.tool[0].id = 'UA-75731283-10' // It would be change with Google Analytics id Production id (UA-12159578-14)
    // digitalData.trackingInfo.tool[1].id =
    //   'unilever-maille-fr-uat,unilever-allbrands-uat' // Please change for production FR :- unilever-maille-fr
    // digitalData.siteInfo.internalDomain = 'maille.com/fr ' // pass main domain

    // digitalData.trackingInfo.tool[0].id = 'UA-12159578-14' // Google analytics Id
    digitalData.trackingInfo.tool[0].id =
      'unilever-maille-fr,unilever-global-allbrands'
    digitalData.trackingInfo.tool[1].id = ''
    digitalData.siteInfo.internalDomain = 'maille.com'
  }

  const avisRatings = () => {
    const script = document.createElement('script')
    script.setAttribute('async', 'true')
    script.setAttribute(
      'src',
      'https://widgets.rr.skeepers.io/product/2ae904e7-a6ea-f774-bd3f-ba04ab119673/8a8fb78f-5045-4ec1-a61f-20cba6e80317.js',
    )
    document.head.appendChild(script)
  }
  useEffect(() => avisRatings(), [])

  return (
    <>
      <Helmet>
        <html lang='fr-FR' />
        <title>{title}</title>
        <meta name='description' content={description} />
        {image && <meta name='image' content={`https:${image}`} />}
        {location && <link rel='canonical' href={location.href} />}
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        {image && <meta property='og:image' content={`https:${image}`} />}
        <meta
          name='google-site-verification'
          content='Gpjca8SERQU5ZeE6zyHxDrfFeoy8D8yX_Ah8Gt52k-0'
        />
        <meta
          name='google-site-verification'
          content={googleSiteVerificationId}
        />
        {locale === 'fr' &&
          schemas &&
          schemas.map((schema, index) => (
            <script key={index} type='application/ld+json'>
              {JSON.stringify(schema)}
            </script>
          ))}
        {/* <script async src="https://widgets.rr.skeepers.io/product/2ae904e7-a6ea-f774-bd3f-ba04ab119673/8a8fb78f-5045-4ec1-a61f-20cba6e80317.js"></script> */}
        {/* 
        <script
          type='text/javascript'
          src='https://cdn.cookielaw.org/consent/25f09290-b34b-496d-a193-569ace36015d/OtAutoBlock.js'
        />
        <script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          data-document-language='true'
          type='text/javascript'
          charset='UTF-8'
          data-domain-script='25f09290-b34b-496d-a193-569ace36015d'
          onComplete={function OptanonWrapper() {}}
        /> */}

        {locale == 'fr' && (
          <script
            defer
            id='gatsby-plugin-adobe-dtm-datalayer'
          >{`digitalData = ${JSON.stringify(digitalData)}`}</script>
        )}

        {/* <script
          key='gatsby-plugin-adobe-dtm'
          src='//assets.adobedtm.com/e6bd1902389a/f90a2a4dde3d/launch-170ed9c3d24a-development.min.js'
          async
        /> */}

        {/* <meta
          name='google-site-verification'
          content='Gpjca8SERQU5ZeE6zyHxDrfFeoy8D8yX_Ah8Gt52k-0'
        /> */}
      </Helmet>
    </>
  )
}
