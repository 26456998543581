import React from 'react'
import styled from 'styled-components'

export const Vegetarian = props => {
  if (props.circle === 'true') {
    return (
      <StyledSvg width={41} height={41} viewBox='0 0 41 41' {...props}>
        <g
          data-name='Group 1175'
          transform='translate(.5 .5)'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        >
          <circle data-name='Ellipse 55' cx={20} cy={20} r={20} />
          <path
            data-name='Path 1383'
            d='M9.502 11.101s11.643 10.4 11.643 20.375'
          />
          <path
            data-name='Path 1384'
            d='M30.586 11.874s-10.811 4.158-10.811 19.543'
          />
          <path
            data-name='Path 1385'
            d='M23.648 18.499s-1.091-8.893 8.366-7.346c.372 6.667-6.859 7.7-6.859 7.7'
          />
          <path
            data-name='Path 1386'
            d='M7.496 22.231l8.864 1.773-4.609-4.609s-5.315 0-4.255 2.836z'
          />
          <path
            data-name='Path 1387'
            d='M7.484 26.105l8.864 1.773v-4.254l-8.864-1.773z'
          />
          <path
            data-name='Path 1388'
            d='M33.632 22.623c-.747-.747-2.9-.689-4.256.672l-.019.02a3.975 3.975 0 01.081.783 4.822 4.822 0 01-1.023 3.076 1.89 1.89 0 00.289.377c.99.99 2.9.689 4.256-.672s1.269-3.658.672-4.256z'
          />
          <ellipse
            data-name='Ellipse 56'
            cx={2.966}
            cy={4.078}
            rx={2.966}
            ry={4.078}
            transform='translate(23.996 20.1)'
          />
        </g>
      </StyledSvg>
    )
  }
  return (
    <StyledSvg viewBox='0 0 24.3 18.165' {...props}>
      <g
        data-name='Group 819'
        transform='translate(.505 .506)'
        fill='none'
        stroke={props.color}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path data-name='Path 1383' d='M2.034.822s9.335 8.335 9.335 16.336' />
        <path
          data-name='Path 1384'
          d='M20.037 1.489s-8.668 3.334-8.668 15.669'
        />
        <path
          data-name='Path 1385'
          d='M14.699 6.035s-.875-7.13 6.708-5.89c.3 5.345-5.5 6.177-5.5 6.177'
        />
        <path
          data-name='Path 1386'
          d='M.113 9.69l7.107 1.421-3.7-3.7S-.74 7.416.113 9.69z'
        />
        <path
          data-name='Path 1387'
          d='M.113 13.102l7.107 1.421v-3.411L.113 9.691z'
        />
        <path
          data-name='Path 1388'
          d='M22.963 10.335c-.6-.6-2.321-.552-3.412.539l-.015.016a3.186 3.186 0 01.065.628 3.866 3.866 0 01-.82 2.466 1.513 1.513 0 00.232.3c.793.794 2.321.552 3.412-.539a2.931 2.931 0 00.538-3.41z'
        />
        <ellipse
          data-name='Ellipse 56'
          cx={2.378}
          cy={3.27}
          rx={2.378}
          ry={3.27}
          transform='translate(14.84 8.247)'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
