import React from 'react'
import styled from 'styled-components'

export const Dairyfree = props => {
  if (props.circle === 'true') {
    return (
      <StyledSvg height={41} viewBox='0 0 41 41' width={41} {...props}>
        <g fill='none' stroke='#ae841f' transform='translate(.5 .5)'>
          <circle cx={20} cy={20} r={20} strokeMiterlimit={10} />
          <path
            d='M24.295 25.531a2.337 2.337 0 002.433-2.433c-.029-1.477-2.194-3.6-2.194-3.6a.423.423 0 00-.708.014s-2.082 2.2-2.054 3.677a2.337 2.337 0 002.524 2.338'
            strokeMiterlimit={10}
          />
          <g strokeLinecap='round' strokeLinejoin='round'>
            <path d='M16.073 4.389v2.838l11 1.773V6.517zM28.132 14.321l-9.931-.709-2.128-6.384-3.548 6.383v17.026L18.2 33.12l11-1.773.355-17.025-2.483-5.32M18.555 14.675v18.444M17.491 13.611h-4.966' />
          </g>
        </g>
      </StyledSvg>
    )
  }
  return (
    <StyledSvg viewBox='0 0 13.406 21.936' {...props}>
      <g data-name='Group 822' fill='none' stroke={props.color}>
        <path
          data-name='Path 1402'
          d='M9.073 15.906a1.7 1.7 0 001.773-1.773c-.021-1.076-1.6-2.62-1.6-2.62a.308.308 0 00-.516.01s-1.518 1.6-1.5 2.679a1.7 1.7 0 001.839 1.7'
          strokeMiterlimit={10}
        />
        <path
          data-name='Path 1403'
          d='M3.085.5v2.068l8.012 1.292V2.051z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          data-name='Path 1404'
          d='M11.872 7.737L4.635 7.22 3.084 2.568.5 7.22v12.408l4.135 1.809 8.012-1.292.258-12.406-1.809-3.877'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          data-name='Line 108'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M4.894 7.995v13.44'
        />
        <path
          data-name='Line 109'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M4.119 7.22H.5'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
