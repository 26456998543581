import { useContext } from 'react'
import { StoreContext } from '../../contexts'

import { useGetLineItem } from './useGetLineItem'

export function useUpdateItemAttributes() {
  const { client, cart, setCart } = useContext(StoreContext)
  const getLineItem = useGetLineItem()

  async function updateItemAttributes(
    variantId: string | number,
    customAttributes: any,
  ) {
    if (variantId == null) {
      throw new Error('Must provide a variant id')
    }


    const lineItem = getLineItem(variantId)
    if (lineItem == null) {
      throw new Error(`Item with variantId ${variantId} not in cart`)
    }
    const input = { customAttributes: [customAttributes] }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore

    const newCart = await client.checkout.updateAttributes(cart.id, input);
    // const newCart = await client.checkout.updateLineItems(cart.id, [
    //   { id: lineItem.id, quantity:1 , customAttributes },
    // ])
    setCart(newCart)

  }

  return updateItemAttributes

}
