import React, { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import Header from './Header'
import { Newsletter } from '../newsletter/Newsletter'
import Footer from './Footer'
import LanguageSelector from './LanguageSelector'
import '../../fonts/fonts.css'
import { desktopBreakpoint } from '../../styles'
import { useLayout, useLocale } from '../../hooks'
// import CheckLanguage from '../checkLanguage/CheckLanguage'
import loadable from '@loadable/component'
import Helmet from 'react-helmet'
import { LocalStorage } from '../../utils'
// import { Newsletter } from '../newsletter/Newsletter'
// const Header = loadable(() => import('./Header'));
// const Footer = loadable(()=> import('./Footer'))
const CheckLanguage = loadable(() => import('../checkLanguage/CheckLanguage'))
const GlobalStyles = createGlobalStyle`
 *,
        *::before,
        *::after {
          box-sizing: border-box;
        }
        body {
          /* ::-webkit-scrollbar {
      display: none;
    } */
          width: 100vw;
          overflow-x: hidden;
          margin: 0;
          padding: 0;
          color: #000;
          font-family: 'MrsEaves', Helvetica Neue, serif;
          outline-style: none;
          -webkit-font-smoothing: antialiased;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
          font-family: 'Tivoli Bold', Helvetica Neue, serif;
          font-weight: normal;
        }
        button {
          font: inherit;
          outline: none;
          border: none;
          background: none;
        }
        html {
          font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
            Roboto, 'Helvetica Neue', Arial, sans-serif;
        }
        a {
          text-decoration: none;
          color: inherit;
        }

        .mobileOnly {

          @media (min-width: ${desktopBreakpoint}) {
            display: none;
          }
        }

        .desktopOnly {
          display: none;

          @media (min-width: ${desktopBreakpoint}) {
            display: block;
          }
        }
        .bold-rich-text {
          font-weight: bold;
        }
        .italic-rich-text {
          font-style: italic;
        }
`

const Layout = ({ children }) => {
  const locale = useLocale()
  declare global {
    interface Window {
      Shopify: {}
    }
  }

  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    window.Shopify = {
      shop: 'maille-fr.myshopify.com',
    }
  }

  const { newsletterInactiveOnVegan } = useLayout()
  const newsletterActive =
    !(
      newsletterInactiveOnVegan &&
      typeof window !== 'undefined' &&
      window.location.pathname.includes('fr/recipe/vegan')
    ) &&
    !(
      typeof window !== 'undefined' &&
      window.location.pathname.includes('fr/jeu')
    )

  // const removed = false

  // useEffect(() => {
  // const script = document.createElement('script')
  // script.src =
  // 'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'
  //   script.async = true

  //     if (!removed) document.body.appendChild(script)
  //     removed = true

  //   return () => {
  //     if (removed) document.body.removeChild(script)
  //   }
  // }, [children])

  // console.log(children)

  // useEffect(() => {
  //   const reviewScript = document.createElement('script')
  //   reviewScript.async = true
  //   reviewScript.id = 'review-script'
  //   reviewScript.type = 'text/javascript'
  //   reviewScript.src =
  //     'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'

  //   const alreadyExistingScripts = document.querySelector('#review-script')
  //   if (alreadyExistingScripts)
  //     alreadyExistingScripts.parentNode.removeChild(alreadyExistingScripts)

  //   setTimeout(() => {
  //     try {
  //       document.body.appendChild(reviewScript)
  //     } catch (e) {
  //       console.error(e)
  //     }
  //   }, 3000)
  // }, [])
  const cookieStyle = {
    left: '-40px',
    color: 'rgb(174, 132, 31)',
    width: '200px',
  }
  useEffect(() => {
    //console.log("line 150");
    LocalStorage.manageCartExpiration()

    const bg = [
      'background: white;',
      'color: black',
      'padding: 10px 50px 10px 50px',
      'line-height: 35px',
      'font-family: sans-serif',
      'font-size: 15px',
    ].join(';')
    console.log('%c © 2020, La Maison Maille. (っ◔◡◔)っ', bg)
    console.log('this is js')
  }, [])

  return (
    <>
      <Helmet>
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
      <GlobalStyles />
      <Header />
      {/* <LanguageSelector /> */}
      {newsletterActive && locale === 'fr' && <Newsletter />}
      {(locale === 'en-CA' || locale === 'fr-CA') && <CheckLanguage />}
      <main>{children}</main>
      <Footer />
      {/* {
        locale === 'fr' &&
        <button type='button' id='ot-sdk-btn' className='ot-sdk-show-settings'  style={cookieStyle}>
          {' '}
          Cookie Settings
        </button>
      } */}
    </>
  )
}

export default Layout
