import React from 'react'
import styled from 'styled-components'

export const SamsungPay = (props) => {
  return (
    <StyledSvg
      data-name='Group 33'
      width={109.556}
      height={15.869}
      viewBox='0 0 109.556 15.869'
      {...props}
    >
      <g id='Group_1361' data-name='Group 1361' transform='translate(0 0)'>
        <g id='Group_1360' data-name='Group 1360'>
          <g
            id='Group_1357'
            data-name='Group 1357'
            transform='translate(71.757 0)'
          >
            <g
              id='Group_1356'
              data-name='Group 1356'
              transform='translate(0 0.032)'
            >
              <g id='Group_1355' data-name='Group 1355'>
                <g id='Group_1354' data-name='Group 1354'>
                  <path
                    id='Path_451'
                    data-name='Path 451'
                    d='M607.805,2113.9v-15.551h2.507v1.143a3.034,3.034,0,0,1,1.555-1.143,4.856,4.856,0,0,1,1.65-.254,5.551,5.551,0,0,1,4.062,1.714,6.093,6.093,0,0,1,.032,8.347,5.569,5.569,0,0,1-1.841,1.3,5.358,5.358,0,0,1-2.158.444,5.292,5.292,0,0,1-1.745-.286,3.307,3.307,0,0,1-1.587-1.079v5.4H607.8v-.032Zm5.617-13.615a3.089,3.089,0,0,0-2.348.952,3.828,3.828,0,0,0-.92,2.7,4.156,4.156,0,0,0,.889,2.793,3.37,3.37,0,0,0,4.729-.031,3.719,3.719,0,0,0,.984-2.634,3.873,3.873,0,0,0-.984-2.761A3.111,3.111,0,0,0,613.423,2100.281Z'
                    transform='translate(-607.805 -2098.091)'
                    fill='#fff'
                  />
                  <path
                    id='Path_452'
                    data-name='Path 452'
                    d='M692.136,2114.442l2.095-4.919-4.824-10.632h2.634l3.428,8,3.205-8h2.634l-6.57,15.551Z'
                    transform='translate(-663.509 -2098.637)'
                    fill='#fff'
                  />
                </g>
              </g>
            </g>
            <path
              id='Path_453'
              data-name='Path 453'
              d='M657.487,2109.607v-1.175a3.1,3.1,0,0,1-1.587,1.079,5.44,5.44,0,0,1-1.746.286,5.353,5.353,0,0,1-2.158-.445,5.573,5.573,0,0,1-1.841-1.3,5.9,5.9,0,0,1-1.206-1.9,6.033,6.033,0,0,1-.444-2.221,5.87,5.87,0,0,1,1.682-4.221,5.6,5.6,0,0,1,4.062-1.714,5.8,5.8,0,0,1,1.65.254,3.035,3.035,0,0,1,1.555,1.143v-1.143h2.507v11.331h-2.475v.032ZM652,2101.2a3.927,3.927,0,0,0-.984,2.761,3.721,3.721,0,0,0,.984,2.634,3.183,3.183,0,0,0,2.317.983,3.114,3.114,0,0,0,2.412-.952,3.948,3.948,0,0,0,.889-2.793,3.882,3.882,0,0,0-.92-2.7,3.185,3.185,0,0,0-2.349-.952A3.3,3.3,0,0,0,652,2101.2Z'
              transform='translate(-635.589 -2097.991)'
              fill='#fff'
            />
          </g>
          <g
            id='Group_1359'
            data-name='Group 1359'
            transform='translate(0 1.524)'
          >
            <path
              id='Path_454'
              data-name='Path 454'
              d='M544.314,2111.413l-.127-7.522h2.38v9.49H543.14l-2.349-7.807h-.064l.127,7.807h-2.349v-9.49h3.555l2.222,7.522Z'
              transform='translate(-488.743 -2103.542)'
              fill='#fff'
            />
            <path
              id='Path_455'
              data-name='Path 455'
              d='M412.41,2104.748l-1.333,8.728h-2.571l1.777-9.585h4.253l1.777,9.585h-2.571l-1.3-8.728Z'
              transform='translate(-400.001 -2103.542)'
              fill='#fff'
            />
            <path
              id='Path_456'
              data-name='Path 456'
              d='M444.47,2111.223l1.206-7.332h3.9l.222,9.585H447.39l-.063-8.633h-.063l-1.587,8.633h-2.444l-1.587-8.633h-.063l-.064,8.633h-2.412l.222-9.585h3.935l1.174,7.332Z'
              transform='translate(-420.889 -2103.542)'
              fill='#fff'
            />
            <path
              id='Path_457'
              data-name='Path 457'
              d='M512.169,2112.017a.84.84,0,0,0,.92-.7,1.422,1.422,0,0,0,.032-.381v-7.045h2.444v6.887c0,.19-.032.539-.032.635-.159,1.777-1.587,2.38-3.364,2.38s-3.174-.572-3.332-2.38c0-.1-.032-.445-.032-.635v-6.887h2.444V2111a1.549,1.549,0,0,0,.032.381.813.813,0,0,0,.889.635'
              transform='translate(-468.468 -2103.543)'
              fill='#fff'
            />
            <g id='Group_1358' data-name='Group 1358'>
              <path
                id='Path_458'
                data-name='Path 458'
                d='M386.149,2110a1.328,1.328,0,0,1,.032.7c-.1.317-.286.635-.952.635a.845.845,0,0,1-.952-.856v-.92h-2.571v.73c0,2.158,1.682,2.793,3.491,2.793,1.745,0,3.174-.6,3.4-2.19a4.895,4.895,0,0,0,0-1.587c-.413-2-4.062-2.6-4.316-3.745a1.353,1.353,0,0,1,0-.508.787.787,0,0,1,.889-.635.809.809,0,0,1,.889.857v.6h2.38v-.667c0-2.094-1.873-2.412-3.237-2.412-1.714,0-3.11.571-3.364,2.126a3.631,3.631,0,0,0,.032,1.3c.381,2,3.808,2.538,4.284,3.776'
                transform='translate(-381.706 -2102.793)'
                fill='#fff'
              />
              <path
                id='Path_459'
                data-name='Path 459'
                d='M484.586,2110.269a1.32,1.32,0,0,1,.032.7c-.063.317-.286.635-.92.635a.845.845,0,0,1-.952-.857v-.92h-2.539v.73c0,2.127,1.682,2.761,3.459,2.761,1.714,0,3.142-.572,3.364-2.158a4.632,4.632,0,0,0,0-1.555c-.413-2-4-2.57-4.252-3.681a.914.914,0,0,1,0-.508.773.773,0,0,1,.857-.635.809.809,0,0,1,.889.857v.6h2.38v-.667c0-2.063-1.872-2.38-3.205-2.38-1.682,0-3.078.54-3.332,2.095a2.878,2.878,0,0,0,.032,1.27c.349,1.936,3.713,2.476,4.189,3.713'
                transform='translate(-448.945 -2103.065)'
                fill='#fff'
              />
              <path
                id='Path_460'
                data-name='Path 460'
                d='M575.2,2111.306a.918.918,0,0,0,.984-.7,1.3,1.3,0,0,0,.032-.349v-1.4h-.984v-1.4h3.4v2.571a5.144,5.144,0,0,1-.032.635c-.159,1.745-1.682,2.38-3.4,2.38-1.682,0-3.206-.635-3.364-2.38a5.193,5.193,0,0,1-.032-.635v-4.031c0-.159.032-.476.032-.635.222-1.809,1.65-2.38,3.364-2.38s3.205.572,3.364,2.38c.032.286.032.635.032.635v.317h-2.412v-.54a2.376,2.376,0,0,0-.032-.381.853.853,0,0,0-.952-.7.9.9,0,0,0-.952.7,1.935,1.935,0,0,0-.032.444v4.379a1.177,1.177,0,0,0,.032.349c0,.318.254.73.952.73'
                transform='translate(-511.474 -2102.928)'
                fill='#fff'
              />
            </g>
          </g>
        </g>
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
