import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const NewGift = (props) => {
  return (
    <StyledSvg
    fill="#ae841f" height="40" width="40" version="1.1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 512 512" stroke="#ae841f">

<g stroke-width="0"/>

<g stroke-linecap="round" stroke-linejoin="round"/>

<g> <g> <g> <path d="M495.304,55.652H16.696C7.475,55.652,0,63.127,0,72.348v367.304c0,9.22,7.475,16.696,16.696,16.696h478.609 c9.22,0,16.696-7.475,16.696-16.696V72.348C512,63.127,504.525,55.652,495.304,55.652z M200.348,189.217 c0-18.412,14.979-33.391,33.391-33.391s33.391,14.979,33.391,33.391c0,18.412-14.979,33.391-33.391,33.391h-31.712 c-1.1-5.396-1.68-10.979-1.68-16.696V189.217z M166.957,422.957H33.391V256h100.174h16.65 c-15.245,20.262-39.485,33.391-66.737,33.391c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696 c32.672,0,62.249-13.482,83.478-35.168V422.957z M166.957,205.913c0,5.717-0.58,11.3-1.68,16.696h-31.712 c-18.412,0-33.391-14.979-33.391-33.391s14.979-33.391,33.391-33.391c18.412,0,33.391,14.979,33.391,33.391V205.913z M166.957,131.419c-9.832-5.702-21.231-8.984-33.391-8.984c-36.824,0-66.783,29.959-66.783,66.783 c0,12.16,3.282,23.56,8.984,33.391H33.391V89.043h133.565V131.419z M478.609,422.957H200.348V287.615 c21.229,21.685,50.806,35.168,83.478,35.168c9.22,0,16.696-7.475,16.696-16.696s-7.475-16.696-16.696-16.696 c-27.252,0-51.492-13.129-66.737-33.391h16.65h244.87V422.957z M478.609,222.609H291.537c5.702-9.832,8.984-21.231,8.984-33.391 c0-36.824-29.959-66.783-66.783-66.783c-12.16,0-23.56,3.282-33.391,8.984V89.043h278.261V222.609z"/> </g> </g> <g> <g> <path d="M428.522,289.391h-77.913c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h77.913 c9.22,0,16.696-7.475,16.696-16.696S437.742,289.391,428.522,289.391z"/> </g> </g> <g> <g> <path d="M428.522,356.174h-77.913c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h77.913 c9.22,0,16.696-7.475,16.696-16.696S437.742,356.174,428.522,356.174z"/> </g> </g> </g>
</StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(18)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(20)};
    &.sticky-nav-icon {
      width: ${desktopVw(18)};
    }
  }
`
