import { decode } from 'shopify-gid'

export const useCartCheckoutAnalytics = (products) => {
  products.map((product) => {
    const id = product.id ? decode(product.id).id : 'unset'
    const { price } = product.variant
    const { variant } = product
    if (typeof window !== 'undefined' && window.digitalData) {
      window.digitalData.cart.item.push({
        productInfo: {
          productID: id,
          productName: product.title,
          price,
          brand: 'Maille',
          quantity: product.quantity,
        },
        category: {
          primaryCategory: product.shopifyProduct
            ? 'Product'
            : 'Classic product',
        },
        pCAT: {
          name: 'shs',
          level: '2',
        },
        attributes: {
          productVariants: variant,
          listPosition: 4,
        },
      })
    }
  })

  window.digitalData.event.push({
    eventInfo: {
      type: ctConstants && ctConstants.checkoutenhanced,
    },
    category: { primaryCategory: ctConstants.conversion },
    subcategory: 'Win',
  })
  console.log(window.digitalData)
}
