import { useLayoutEffect } from 'react'

export function useLockBodyScroll(toggle) {
  useLayoutEffect(() => {
    if (toggle) {
      const originalStyle = window.getComputedStyle(document.body).overflow

      document.body.style.overflow = 'hidden'

      return () => (document.body.style.overflow = originalStyle)
    }
  }, [toggle])
}
