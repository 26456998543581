import React from 'react'
import {
  Accounts,
  Amex,
  ApplePay,
  ApplePay2,
  GooglePay,
  SamsungPay,
  Arrow,
  ArrowDown,
  ArrowPlay,
  ArrowUp,
  Bag,
  Barcode,
  Book,
  Check,
  CheckList,
  Crown,
  Lock,
  Dairyfree,
  Delivery,
  DropdownArrow,
  Facebook,
  Gift,
  Glutenfree,
  Information,
  Instagram,
  NewGift,
  Jar,
  LevelOfDifficulty1,
  LevelOfDifficulty2,
  LevelOfDifficulty3,
  Location,
  Mastercard,
  Menu,
  Paypal,
  Person,
  Pinterest,
  ReturnArrow,
  Search,
  Stamp,
  Star,
  Time,
  Tip,
  Truck,
  Twitter,
  Vegan,
  Vegetarian,
  Visa,
  TwoPeople,
  Heart,
  Whisk,
  Egg, 
  Youtube
} from './icons'

// Create headerlink icons and set default back to return null
// headerlink icon names: bag, jar, gift, book, location, crown, stamp
// Move icons to their own files in ./icons , see crown.js + index.js in that folder

export const Icon = (props) => {
  switch (props.name) {
    case 'accounts':
      return <Accounts {...props} />
    case 'amex':
      return <Amex {...props} />
    case 'applepay':
      return <ApplePay {...props} />
    case 'applepay2':
      return <ApplePay2 {...props} />
    case 'googlepay':
      return <GooglePay {...props} />
    case 'samsungpay':
      return <SamsungPay {...props} />
    case 'lock':
      return <Lock {...props} />
    case 'arrow':
      return <Arrow {...props} />
    case 'arrow-down':
      return <ArrowDown {...props} />
    case 'arrow-play':
      return <ArrowPlay {...props} />
    case 'arrow-up':
      return <ArrowUp {...props} />
    case 'bag':
      return <Bag {...props} />
    case 'barcode':
      return <Barcode {...props} />
    case 'book':
      return <Book {...props} />
    case 'check':
      return <Check />
    case 'checklist':
      return <CheckList {...props} />
    case 'crown':
      return <Crown {...props} />
    case 'delivery':
      return <Delivery {...props} />
    case 'difficulty1':
      return <LevelOfDifficulty1 {...props} />
    case 'difficulty2':
      return <LevelOfDifficulty2 {...props} />
    case 'difficulty3':
      return <LevelOfDifficulty3 {...props} />
    case 'dropdown-arrow':
      return <DropdownArrow {...props} />
    case 'facebook':
      return <Facebook {...props} />
    case 'gift':
      return <Gift {...props} />
    case 'new-gift':
      return <NewGift {...props} />
    case 'whisk':
      return <Whisk {...props} />
    case 'gluten-free':
      return <Glutenfree {...props} />
    case 'information':
      return <Information {...props} />
    case 'instagram':
      return <Instagram {...props} />
    case 'jar':
      return <Jar {...props} />
    case 'lactose-free':
      return <Dairyfree {...props} />
    case 'location':
      return <Location {...props} />
    case 'mastercard':
      return <Mastercard {...props} />
    case 'menu':
      return <Menu {...props} />
    case 'paypal':
      return <Paypal {...props} />
    case 'person':
      return <Person {...props} />
    case 'pinterest':
      return <Pinterest {...props} />
    case 'return arrow':
      return <ReturnArrow {...props} />
    case 'search':
      return <Search {...props} />
    case 'stamp':
      return <Stamp {...props} />
    case 'star':
      return <Star {...props} />
    case 'time':
      return <Time {...props} />
    case 'tip':
      return <Tip {...props} />
    case 'truck':
      return <Truck {...props} />
    case 'twitter':
      return <Twitter {...props} />
    case 'vegan':
      return <Vegan {...props} />
    case 'vegetarian':
      return <Vegetarian {...props} />
    case 'visa':
      return <Visa {...props} />
    case 'two people':
      return <TwoPeople {...props} />
    case 'heart':
      return <Heart {...props} />
    case 'egg':
      return <Egg {...props} />
    case 'youtube':
        return <Youtube {...props} />
    default:
      return null
  }
}
