/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { desktopVw, mobileVw, desktopBreakpoint, colors } from '../../styles'

export const Price = ({ quantity = 1, indexSize, variants, withPersonalisation, jarPersonalisationPrice }) => {
  const available = "availableForSale" in variants[indexSize || 0] ? variants[indexSize || 0].availableForSale : variants[indexSize || 0].available;
  const isTextEntered = withPersonalisation && withPersonalisation.find(({ key }) => key === '1. Texte gravé')
  //const jarPersonalisationPrice = 9.50
  
  const getPrice = (price) => {
    if (!isNaN(price)) {
        return price
    } else if (!isNaN(price.amount)) {
        return price.amount
    }
}

  return (
    <StyledPrice className='price'>
      {available ? (
        <>
          {' '}
          <span>
            {isTextEntered ? Number(
              quantity * getPrice(variants[indexSize || 0].price) +
              (quantity * jarPersonalisationPrice),
            ).toFixed(2) : Number(
              quantity * getPrice(variants[indexSize || 0].price)
            ).toFixed(2)}
            {' '}
            €
          </span>
          {variants[indexSize || 0].compareAtPrice &&
            variants[indexSize || 0].compareAtPrice.amount > 1 && (
              <Discountlabel>
                {Number(
                  quantity * variants[indexSize || 0].compareAtPrice.amount +
                  (withPersonalisation && isTextEntered ? quantity * jarPersonalisationPrice : 0),
                ).toFixed(2)}{' '}
                €
              </Discountlabel>
            )}
        </>
      ) : (
        <SoldOut>Bientôt de retour</SoldOut>
      )}
    </StyledPrice>
  )
}

const StyledPrice = styled.div``

const Discountlabel = styled.span`
  display: inline-block;
  margin-left: ${mobileVw(5)} !important;
  color: ${colors.darkGrey} !important;
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    /* margin: 0 0 0 ${desktopVw(10)} !important; */
  }

  &:before {
    content: '';
    width: 100%;
    height: ${mobileVw(2)};
    position: absolute;
    top: 42%;
    background: ${colors.darkGrey};

    @media (min-width: ${desktopBreakpoint}) {
      height: ${desktopVw(2.5)};
    }
  }
`

const SoldOut = styled.span`
  display: inline-block;
  color: ${colors.darkGrey} !important;
  position: relative;
  text-transform: uppercase;
`
