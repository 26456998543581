import * as React from 'react'

export const ArrowDown = (props) => {
  return (
    <svg width={25.441} height={14.135} viewBox='0 0 25.441 14.135' {...props}>
      <path
        data-name='Path 1614'
        d='M24.734.707L12.721 12.72.707.707'
        fill='none'
        stroke={props.color}
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  )
}
