import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '..'

export const useDictionaryQuery = () => {
  const locale = useLocale();
  const {
    allContentfulGlobalDictionary: { edges },
  } = useStaticQuery(
    graphql`
      query allContentfulGlobalDictionary {
        allContentfulGlobalDictionary {
          edges {
            node {
              node_locale
              availableInSupermarkets
              exclusiveOnlineProductRangeBoutiqueMaille
              readMore
              buyOnline
              viewMoreArticles
              showMoreProducts
              recipeDiscoverProducts
              searchBarText
              viewMoreRecipesSlug
              homePageRecipeTitle
              homePageCollectionSliderSubTitle
              homePageCollectionSliderButton
              homePageCollectionSliderButtonSlug
              globalNewsletterTitle
              globalNewsletterDescription
              globalNewsletterEmailAddress
              globalNewsletterIndicatesRequried
              globalNewsletterName
              globalNewsletterButton
              recipeFeatureItemBlockTitle
              filterTitle
              filterRemove
              customizeYourPot
              updatePersonalization
              notFoundTitle
              notFoundSubtitle
              notFoundButton
              notFoundAdditionalLink
              notFoundAdditionalLinkText
              removePersonalization         
              languagePopup
              popupChooseLang
              popupLang 
              showGiftingMessage
              messagePlaceholder  
              giftMessageHeadingText{
                raw
              }
              giftMessageForUser
              landingPagePopUp {
                fluid(sizes: "(max-width: 1023px) 40vw, 30vw") {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
              }
              corkEngravingImage {
                images {
                  fluid {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  title
                }
              }
            }
          }
        }
      }
    `,
  )

  const localeData = locale
    ? edges.filter(({ node }) => node.node_locale === locale)
    : edges
  const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})

  return site
}
