/* eslint-disable camelcase */
import { useState, useContext } from 'react'
import fetch from 'isomorphic-fetch'
// import { LayoutContext } from '../../contexts'
// import errors from '../../static/accountErrors'

export const useNewsletterCustomer = () => {
  const node_locale = 'fr'
  // const localisedError = errors[node_locale]
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  })

  const newsletterCustomer = async (email, demFreeda) => {
    if (email) {
      setState({ response: null, loading: true, error: null })
      try {
        const data = await fetch('/.netlify/functions/newsletter', {
          method: 'POST',
          body: JSON.stringify({
            customer: {
              email,
              accepts_marketing: true,
              tags: demFreeda
                ? `DEM_AGDM, lang_${node_locale}`
                : `lang_${node_locale}`,
            },
          }),
        })
        const res = await data.json()
        if (res.error) {
          setState({
            response: null,
            loading: false,
            // error: localisedError.general,
          })
        } else {
          setState({
            response: res,
            loading: false,
            error: null,
          })
        }
      } catch (error) {
        setState({
          response: null,
          loading: false,
          // error: localisedError.general,
        })
      }
    } else {
      setState({
        response: null,
        loading: false,
        // error: localisedError.emailInputMissing,
      })
    }
  }

  return [newsletterCustomer, state]
}
