import React from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { Image } from '../Image'
import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  colors,
  fonts,
} from '../../../styles'
import { Productcard } from '../Productcard'
import { Button } from '../Button'
import { Space } from '../Space'
import { LinkType } from '../LinkType'
import { useLang } from '../../../hooks'

export const ProductSlider = ({
  title,
  products,
  button,
  buttonSlug,
  slidesMobile,
  shopenful,
  gifting,
  landing,
}) => {
  const lang = useLang();

  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1.7,
    breakpoints: {
      280: {
        slidesPerView: slidesMobile || 1.7,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: landing ? 4 : 2.4,
        allowTouchMove: false,
      },
    },
  }
  const cleanedProduct = []
  products.map((data, i) => {
    const productAvailable = data.shopifyProduct
      ? !!shopenful.find(
        (prod) => prod.handle === data.shopifyProduct.handle,
      ) :
      true
    if (productAvailable) {
      cleanedProduct.push(data)
    }
  })

  return (
    <StyledProductSlider aria-label='Product slider'>
      <Container>
        <StyledTitle landing = {landing}>
          {typeof title === 'string'
            ? title
            : title.map((word, i) => <span key={i}>{word}</span>)}
        </StyledTitle>
        <Wrapper
          gifting={cleanedProduct.length > 2 && gifting && true}
          showProgressBar={products.length > 2}
          displayButtons={products.length > 1}
          landing={cleanedProduct.length > 2 && landing && true}
        >
          <div>
            <Swiper {...params}>
              {cleanedProduct && cleanedProduct.map((product, i) => {
                return (
                  <Item extraPadding={!!product.shopifyProduct} key={i}>

                    <Productcard
                      shopenful={shopenful}
                      product={{
                        ...product,
                        thumbnailImage: product.images[0],
                      }}
                      goldLabel
                      index={i}
                      landingCard = {landing ? true : false}
                    />
                  </Item>

                )
              })
              }
            </Swiper>
            {button && (
              <ButtonWrapper>
                <StyledButton
                  to={buttonSlug ? `/${lang}${buttonSlug}` : ''}
                  text={button}
                  alt={buttonSlug}
                />
              </ButtonWrapper>
            )}
          </div>
        </Wrapper>
      </Container>
    </StyledProductSlider>
  )
}

const StyledProductSlider = styled.section`
  position: relative;
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(128)};
  }
`

const Item = styled.div`
  padding: 0 ${mobileVw(16)} 0 0;
  img {
    padding: ${(props) => (props.extraPadding ? `0` : `0 ${mobileVw(40)} 0 0`)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(29)} 0 0;
  }
`

const Container = styled.div`
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    display: grid;
    grid-template-columns: ${desktopVw(234)} 1fr;
    grid-gap: 0 ${desktopVw(50)};
  }
`

const StyledTitle = styled.h2`
  display: ${(props) => (!props.landing ? 'block' : 'none')};
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(42)};
  margin: 0 0 ${mobileVw(15)} ${mobileVw(16)};

  span {
    &:first-child {
      color: ${colors.mailleGold};
      padding-right: ${mobileVw(8)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(54)};
    line-height: ${desktopVw(50)};
    margin: ${desktopVw(116)} 0 0 0;

    span {
      display: block;
      &:first-child {
        padding-right: 0;
      }
    }
  }
`

const Wrapper = styled.div`
  position: relative;

  .imageContainer {
    width: 80%;
    img {
      object-fit: contain !important;
    }
  }

  .swiper-pagination-progressbar,
  .swiper-pagination {
    display: ${(props) => (props.showProgressBar ? props.landing ? 'none': 'inline-block' : 'none')};
    background: ${colors.darkGrey};
    width: ${mobileVw(288)};
    height: ${mobileVw(2)};
    top: ${mobileVw(-15)};
    left: unset;
  }

  .swiper-container {
    height: 100%;
    overflow: visible;
    padding-left: ${mobileVw(16)};
    ${({ gifting }: any) =>
    gifting &&
    `
    @media (min-width: ${desktopBreakpoint}) {
      width: 60vw;
      }
    `}
    ${({ landing }: any) =>
    landing &&
    `
    @media (min-width: ${desktopBreakpoint}) {
      width: 85vw;
      padding-left: 0;
      }
    `}
    .swiper-button-prev {
      transform: rotate(180deg);
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev.swiper-button-disabled {
      background-color: ${colors.lightGrey};
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: ${colors.mailleGold};
      top: ${mobileVw(100)};
      display: ${(props) => (props.displayButtons ? 'flex' : 'none')};

      width: ${mobileVw(35)};
      height: ${mobileVw(60)};
      z-index: 999;
      ${({ gifting }: any) =>
    gifting &&
    `
      @media (min-width: ${desktopBreakpoint}) {
        top: ${mobileVw(125)};
        width: ${mobileVw(10)};
        height: ${mobileVw(10)};
        }
      `}
      &:after {
        content: '';
        height: 15px;
        width: 15px;
      }

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy43OTQ5IDcuNTI5OTZIMFY2LjUyOTk2SDEzLjc5MjhMNy45NzA0MyAwLjcwNzU0OUw4LjY3NzU0IDAuMDAwNDQyNTA1TDE1LjcwODEgNy4wMzFMOC42Nzc1NCAxNC4wNjE2TDcuOTcwNDMgMTMuMzU0NEwxMy43OTQ5IDcuNTI5OTZaIiBmaWxsPSJibGFjayIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwIj4KPHJlY3Qgd2lkdGg9IjE1LjcwNyIgaGVpZ2h0PSIxNC4wNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }

    .swiper-button-next {
      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy43OTQ5IDcuNTI5OTZIMFY2LjUyOTk2SDEzLjc5MjhMNy45NzA0MyAwLjcwNzU0OUw4LjY3NzU0IDAuMDAwNDQyNTA1TDE1LjcwODEgNy4wMzFMOC42Nzc1NCAxNC4wNjE2TDcuOTcwNDMgMTMuMzU0NEwxMy43OTQ5IDcuNTI5OTZaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwIj4KPHJlY3Qgd2lkdGg9IjE1LjcwNyIgaGVpZ2h0PSIxNC4wNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }
  }

  .swiper-pagination-progressbar-fill {
    background: ${colors.mailleGold};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;

    .swiper-pagination-progressbar,
    .swiper-pagination,
    .swiper-pagination-progressbar-fill,
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      ${({ gifting }: any) =>
    gifting &&
    `
      display:block
    `}
    }
    .swiper-wrapper {
      justify-content: space-between;
    }

    .swiper-container {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        ${({ gifting }: any) =>
    gifting &&
    `
        display:flex
      `}
      }
    }
    ${({ gifting }: any) =>
    gifting &&
    `
    overflow: hidden;
    width: 70vw;
  `}
  ${({ landing }: any) =>
  landing &&
    `
    overflow: hidden;
    width: 85vw;
    margin: 0 auto;
  `}
    margin: 0 ${desktopVw(-29)} 0 0;
  }
`

const ButtonWrapper = styled.div`
  padding: ${mobileVw(10)} ${mobileVw(16)} 0;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(30)} 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
    margin: 0 auto;
  }
`
