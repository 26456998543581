import * as React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const Barcode = props => {
  return (
    <Svg width={17} height={17} viewBox='0 0 17 17' {...props}>
      <g
        data-name='Group 1200'
        fill='none'
        stroke='#ae841f'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path data-name='Path 1418' d='M.5 4.5V1.2a.7.7 0 01.7-.7h3.3' />
        <path data-name='Path 1419' d='M4.5 16.5H1.2a.7.7 0 01-.7-.7v-3.3' />
        <path data-name='Path 1420' d='M16.5 12.5v3.3a.7.7 0 01-.7.7h-3.3' />
        <path data-name='Path 1421' d='M12.5.5h3.3a.7.7 0 01.7.7v3.3' />
        <path data-name='Line 114' d='M2.587 3.283v10.435' />
        <path data-name='Line 115' d='M4.674 3.283v10.435' />
        <path data-name='Line 116' d='M7.457 3.283v10.435' />
        <path data-name='Line 117' d='M9.543 3.283v10.435' />
        <path data-name='Line 118' d='M11.63 3.283v10.435' />
        <path data-name='Line 119' d='M14.413 3.283v10.435' />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32.04)};
    height: ${desktopVw(32.04)};
  }
`
