import React from 'react'
import Helmet from 'react-helmet'
import { StoreContextProvider, LocaleContext, UserNameProvider } from './src/contexts'
import Layout from './src/components/layout/Layout'

export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true);
};
export const wrapRootElement = ({ element }) => (
  <>
    <Helmet></Helmet>
    <StoreContextProvider>{element}</StoreContextProvider>
  </>
)
export const wrapPageElement = ({
  element,
  props: {
    pageContext: { site, node_locale = 'fr', multiLang },
  },
}) => (
  <>
    <UserNameProvider>
      <LocaleContext.Provider value={{ locale: node_locale, multiLang }}>
        <Layout>{element}</Layout>
      </LocaleContext.Provider>
    </UserNameProvider>
  </>
)

export const onRouteUpdate = (props) => {
  const routeChangeEventName = 'gatsbyRouteChange'
  if (props && props.prevLocation === null) {
    setTimeout(() => {
      if (typeof window._satellite !== 'undefined') {
        if (window._satellite.company) {
          window._satellite.track(routeChangeEventName)
          setTimeout(() => {
            window.digitalData.event.push({
              eventInfo: {
                type: ctConstants.trackAjaxPageLoad,
                eventLabel: props.location && props.location.pathname,
                eventValue: 1,
              },
              category: { primaryCategory: ctConstants.conversion },
              subcategory: 'Read',
            })
          }, 1000)
        }
      }
    }, 1000)
  } else {
    setTimeout(() => {
      if (typeof window._satellite !== 'undefined') {
        if (window._satellite.company) {
          window._satellite.track(routeChangeEventName)
          setTimeout(() => {
            window.digitalData.event.push({
              eventInfo: {
                type: ctConstants.trackAjaxPageLoad,
                eventLabel: props.location && props.location.pathname,
                eventValue: 1,
              },
              category: { primaryCategory: ctConstants.conversion },
              subcategory: 'Read',
            })
          }, 200)
        }
      }
    }, 32)
  }

  function triggerRouteChange() {
    setTimeout(() => {
      if (typeof window._satellite !== 'undefined') {
        if (window._satellite.company) {
          window._satellite.track(routeChangeEventName)
          setTimeout(() => {
            window.digitalData.event.push({
              eventInfo: {
                type: ctConstants.trackAjaxPageLoad,
                eventLabel: props.location && props.location.pathname,
                eventValue: 1,
              },
              category: { primaryCategory: ctConstants.conversion },
              subcategory: 'Read',
            })
          }, 1000)
        }
      }
    }, 1000)
  }

  setTimeout(() => {
    const acceptBtn = document.getElementById('onetrust-accept-btn-handler')
    if (acceptBtn) {
      acceptBtn.addEventListener('click', triggerRouteChange)
    }
  }, 1000)
}
