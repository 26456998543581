import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Search = props => {
  return (
    <StyledSvg viewBox='0 0 18.16 17.737' {...props}>
      <g
        transform='translate(.75 .75)'
        fill='none'
        stroke='#ae841f'
        strokeMiterlimit={10}
        strokeWidth={1.5}
      >
        <ellipse
          data-name='Ellipse 17'
          cx={6.789}
          cy={6.43}
          rx={6.789}
          ry={6.43}
        />
        <path data-name='Line 62' d='M11.642 11.468l5.252 4.974' />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(16.89)};
    transform: translateY(${desktopVw(6)});

    &.sticky-nav-icon {
      transform: translateY(${desktopVw(3)});
    }
  }
`
