export const useVideoAnalytics = (video) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      componentInfo: {
        componentID: `${video}`,
        componentName: video,
      },
      eventInfo: {
        type: ctConstants.trackEvent,
        eventAction: ctConstants.videoAutoPlays,
        eventLabel: video.title,
        eventValue: 1,
      },
      category: { primaryCategory: ctConstants.custom },
      subcategory: 'Read',
      attributes: { nonInteractive: { nonInteraction: 1 } },
    })
    console.log(window.digitalData)
  }
}
