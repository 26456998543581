export const useFilterAnalytics = (filterOption) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.trackEvent,
        eventAction: ctConstants.filter,
        eventValue: 1,
        eventLabel: filterOption,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Interest',
    })
  }
}
