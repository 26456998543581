/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { Decoration } from '.'
import { Image } from './Image'
import { Arrow } from './icons'
import {
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'
import { Video } from './Video'
import { LinkType } from './LinkType'
import RichText from './RichText'
import { Textslider } from './HeroBlock/Textslider'

import { useLang, useSiteQuery } from '../../hooks'
import { log } from 'console'

export const HeroBlock = ({
  data,
  zIndex,
  gold,
  marginTitle,
  fontSize,
  lineBreak,
  addSpace,
  lines,
  homePage,
  imageSizeContain
}) => {
  const {
    background,
    breadcrumbs,
    title,
    textSlider,
    isVideoCarousel,
    subtitle,
    description,
    descriptionBackground,
    decoration,
    imagesSlider,
    video,
    richDescription,
    breadcrumbsTitle,
    cta
  } = data

  const { uspBarText } = useSiteQuery()
  const [swiper, updateSwiper] = useState(null)
  const [indexSwiper, setIndexSwiper] = useState(0)
  const refTitle = useRef(null)

  let params
  if (imagesSlider) {
    params = {
      getSwiper: swiper,
      loop: isVideoCarousel? false: imagesSlider.length > 1,
      autoplay: {
        delay: isVideoCarousel ? 32000 : 6000,
      },
    }
  }

  const lang = useLang()
  // const checkVideo = (id) =>{
  //   if(document.getElementById(id)){
  //     return false
  //   }else{
  //     return true
  //   }
  // }
  useEffect(() => {
    if (swiper) {
      const { slides } = swiper

      Object.values(slides).forEach((elem) => {
        if (
          elem.querySelector &&
          elem.classList.contains('swiper-slide-duplicate') &&
          // if there is a video
          elem.querySelector('picture > img')
        ) {
          // eslint-disable-next-line no-param-reassign
          elem.querySelector('picture > img').style.opacity = 1
        }
      })
    }
  }, [swiper])

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  const marginBottom = (index) => {
    return data.marginBottom && data.marginBottom[index]
      ? data.marginBottom[index]
      : 50
  }

  const marginDescription = (index) => {
    return data.marginDescription && data.marginDescription[index]
      ? data.marginDescription[index]
      : 40
  }

  const titleMargin = (index) => {
    return marginTitle && marginTitle[index]
  }

  useEffect(() => {
    if (swiper === null) {
      return
    }

    swiper.on('transitionEnd', () => {
      setIndexSwiper(swiper.realIndex)
    })
  }, [swiper])

  return (
    <StyledHeroBlock
      background={
        (background && background[0] === 'black') ||
        (background && background[0] === 'full black')
      }
      addSpace={addSpace}
      margin={marginBottom(0)}
      marginMobile={marginBottom(1)}
      aria-label='Page hero'
    >
      {textSlider && textSlider[0].link && textSlider[indexSwiper].link && (
        <StyledClickableLink
          to={`/${lang}${textSlider[indexSwiper].link.link.slug}`}
        >
          {title && title}
        </StyledClickableLink>
      )}
      {background && !background.white && <BlackBlock />}
      <WrapperText>
        {breadcrumbs && (
          <Breadcrumbs
            className='breadcrumbs-wrapper'
            margin={titleMargin(0)}
            marginMobile={titleMargin(1)}
          >
            {breadcrumbs.map(({ link, title }, index) => (
              <React.Fragment key={index}>
                <LinkType
                  key={title}
                  to={`/${lang}${link.slug}`}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                >
                  {title}
                </LinkType>
                {' > '}
              </React.Fragment>
            ))}
            <span>{breadcrumbsTitle || title.map((word) => `${word} `)}</span>
          </Breadcrumbs>
        )}
        <StyledTitle
          lines={lines}
          ref={refTitle}
          color={!background || colors.mailleGold}
          fontSize={fontSize || null}
        >
          {textSlider ? (
            <Textslider order swiper={swiper} data={textSlider} />
          ) : typeof title === 'string' ? (
            title
          ) : (
            title.map((word, index) =>
              title.length > 1 || gold ? (
                lineBreak ? (
                  <>
                    <span key={index}>
                      {title.length > 1 ? word.split('$').join(',') : word}
                    </span>
                    <br></br>
                  </>
                ) : (
                  <span key={index}>
                    {title.length > 1 ? word.split('$').join(',') : word}
                  </span>
                )
              ) : (
                <div key={index}>{word.split('$').join(',')}</div>
              ),
            )
          )}
        </StyledTitle>
        {subtitle && !description && <Subtitle>{subtitle}</Subtitle>}
      </WrapperText>
      {!description && background[0] !== 'full black' && <WhiteBlock />}
      <WrapperSlider
        className='hero-slider'
        descriptionBackground={descriptionBackground}
        decreaseHeight={!!uspBarText}
      >
        <StyledDecoration zIndex={zIndex}>
          {decoration &&
            decoration.map((item, index) => {
              return <Decoration key={index} data={item} />
            })}
        </StyledDecoration>
        <Swiper {...params} getSwiper={updateSwiper}>
          {/* this heroblock is not readable unfortanly, because of features keep adding */}
          {video ? (
            textSlider && textSlider[0].link ? (
              <StyledLinkType
                to={`/${lang}${textSlider[0].link.link.slug}`}
                alt='Maille video'
              >
                <StyledVideo
                  mov={video.videoMov.file.url}
                  mp4={video.videoMp4.file.url}
                  poster={video.poster.fluid}
                />
              </StyledLinkType>
            ) : (
              <StyledVideo
                mov={video.videoMov.file.url}
                mp4={video.videoMp4.file.url}
                poster={video.poster.fluid}
              />
            )
          ) : (
            <></>
          )}
          {imagesSlider &&
            imagesSlider.map(({ fluid, title, id, file }, index) => {
              return textSlider && textSlider[0].link ? (
                textSlider[video ? index + 1 : index].link ? (
                  <StyledLinkType
                    key={index}
                    to={`/${lang}${
                      textSlider[video ? index + 1 : index].link.link.slug
                    }`}
                  >
                    { (file && fluid )? <StyledImage fluid={fluid} alt={title} /> : fluid ? <StyledImage fluid={fluid} alt={title} imageFit = {imageSizeContain}/> : <StyledVideo mp4={file.url} mov ={file.url} controls noRepeat = {true} /> }
                  </StyledLinkType>
                ) : (
                  (file && fluid )? <StyledImage fluid={fluid} alt={title}  /> : fluid ? <StyledImage fluid={fluid} alt={title} imageFit = {imageSizeContain}/> : <StyledVideo mp4={file.url} mov ={file.url} controls noRepeat = {true} />
                )
              ) : (
                (file && fluid )? <StyledImage fluid={fluid} alt={title} /> : fluid ? <StyledImage fluid={fluid} alt={title} imageFit = {imageSizeContain}/> :  <StyledVideo mp4={file.url} mov ={file.url} controls noRepeat = {true}  /> 
              )
            })}
        </Swiper>
        {imagesSlider && imagesSlider.length + (!!video && 1) !== 1 && (
          <Wrapper>
            <Button type='button' onClick={() => goPrev()} left>
              <StyledArrow />
            </Button>
            <Button type='button' onClick={() => goNext()}>
              <StyledArrow />
            </Button>
          </Wrapper>
        )}
      </WrapperSlider>
      {richDescription && !subtitle && (
        <Margin>
          <Description
            margin={marginDescription(0)}
            marginMobile={marginDescription(1)}
            homePage={homePage}
          >
            <RichText json={richDescription} />
            {descriptionBackground && (
              <DescriptionBackground
                key={descriptionBackground.title}
                fluid={descriptionBackground.fluid}
                alt={descriptionBackground.title}
              />
            )}
            {cta && <RediectButton> <a href = {`/fr${cta.link.slug}`}>{cta.title}</a>   </RediectButton>}
          </Description>
        </Margin>
      )}
      <Margin>
        <Textslider swiper={swiper} data={data.textSlider} />
      </Margin>
    </StyledHeroBlock>
  )
}

const StyledHeroBlock = styled.section`
  background: ${(props) => (props.background ? '#000' : ' #fff')};
  color: ${(props) => (props.background ? '#fff' : ' #000')};
  position: relative;
  margin-bottom: ${(props) => mobileVw(props.marginMobile)};
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    display: grid;
    /* grid-gap: 0 ${desktopVw(50)}; */
    grid-template-columns: ${(props) =>
      props.addSpace ? `auto ${desktopVw(750)}` : `auto ${desktopVw(858)}`};
    grid-template-rows: auto auto;
    margin-bottom: ${(props) => desktopVw(props.margin)};
  }
`
const RediectButton = styled.button`
  display: block;
    background-color: ${colors.mailleGold};
    color: #fff;
    padding: ${mobileVw(15.35)} 0 ${mobileVw(13.65)};
    font-size: ${mobileVw(16)};
    font-family: ${fonts.tivoli};
    letter-spacing: ${mobileVw(2)};
    line-height: 1;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    @media (min-width: ${desktopBreakpoint}) {
      padding: ${desktopVw(22)} 0 ${desktopVw(16)};
      font-size: ${desktopVw(20)};
      letter-spacing: ${desktopVw(1.8)};
      line-height: 1;
      cursor: pointer;
      padding: 1.5rem;
    }
  `

const WrapperText = styled.div`
  /* top padding is sset wrong on mobile */
  padding: ${mobileVw(26)} ${mobileVw(16)} ${mobileVw(20)};
  position: relative;
  z-index: 4;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(84)} 0 0 ${desktopVw(128)};
  }
`

const WrapperSlider = styled.div`
  height: ${mobileVw(240)};
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    grid-area: 1 / 2 / last-line / 2;
    min-height: ${desktopVw(430)};
    /* height: ${(props) =>
      props.decreaseHeight
        ? `calc(100vh - ${desktopVw(195)})`
        : `calc(100vh - ${desktopVw(177)})`}; */
    height: ${desktopVw(568)};
    z-index: 3;
  }

  .swiper-container {
    height: 100%;
    margin: 0 0 0 ${mobileVw(16)};

    @media (min-width: ${desktopBreakpoint}) {
      margin: 0;
    }
  }
`

const StyledTitle = styled.h1`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  /* display: flex; */
  flex-flow: wrap;

  span {
    white-space: nowrap;
    padding-right: ${mobileVw(8)};

    @media (min-width: ${desktopBreakpoint}) {
      display: ${(props) => (props.lines ? 'block !important' : 'inline')};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${({ fontSize }) =>
      fontSize ? `${desktopVw(fontSize)}` : `${desktopVw(80)}`};
    line-height: ${desktopVw(74)};
    height: unset;
    span {
      padding-right: ${desktopVw(8)};
    }
  }

  /* p {
    font-size: ${desktopVw(22)};
    line-height: ${desktopVw(22)};
    font-family: ${fonts.mrs};
    margin: 0;
    padding: 0;
  } */

  span {
    /* overflow: hidden; */
    display: inline-block;

    div {
      display: inline-block;
    }
  }

  p {
    div {
      display: inline-block;
    }
  }

  span {
    &:first-child {
      color: ${({ color }) => (color ? colors.mailleGold : colors.mailleGold)};
    }
  }
`

const Subtitle = styled.h3`
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  text-transform: uppercase;
`

const Description = styled.div`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(24)};
  margin: ${(props) => mobileVw(props.marginMobile || 50)} 0 0;
  z-index: 2;

  @media (min-width: ${desktopBreakpoint}) {
    position: relative;
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(24)};
    margin: ${(props) =>
      props.homePage
        ? `-${desktopVw(95)} ${desktopVw(95)} 0
    ${desktopVw(0)}`
        : `${desktopVw(props.margin || 50)} ${desktopVw(95)} 0
      ${desktopVw(0)}`};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  h3 {
    font-family: ${fonts.splandor};
    margin: 0 0 ${mobileVw(8)} 0;
    font-size: ${mobileVw(30)};

    @media (min-width: ${desktopBreakpoint}) {
      margin: 0 0 ${desktopVw(15)} 0;
      font-size: ${desktopVw(30)};
    }
  }
`

const DescriptionBackground = styled(Image)`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    position: absolute;
    top: ${desktopVw(-70)};
    left: ${desktopVw(-130)};
    z-index: -100;
    min-width: 100vw;
    min-height: ${desktopVw(400)};
    max-height: ${desktopVw(333)};
    object-fit: cover;
  }
`

const StyledImage = styled(Image)`
img{
  object-fit: ${(props) => (props.imageFit ?  'contain !important' : 'cover')}
}
`

const WhiteBlock = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    height: ${desktopVw(40)};
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #fff;
  }
`

const BlackBlock = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    height: ${desktopVw(48)};
    width: 100%;
    position: absolute;
    top: 0;
    background: #000;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
`

const Button = styled.button`
  background: ${colors.mailleGold};
  display: flex;
  margin: 0;
  padding: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: ${mobileVw(40)};
  height: ${mobileVw(40)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(64)};
    height: ${desktopVw(64)};
  }

  ${(props) => props.left && `transform: rotate(-180deg)`}
`

const StyledArrow = styled(Arrow)`
  width: ${mobileVw(15)};
  height: auto;
  stroke: ${colors.white};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(15)};
  }
`

const StyledVideo = styled(Video)`
  margin: 0;

  @media (min-width: ${desktopBreakpoint}) {
    height:35vw;
    margin: 0;
  }
`

const Breadcrumbs = styled.div`
  margin: 0 0
    ${(props) =>
      props.marginMobile ? mobileVw(props.marginMobile) : mobileVw(10)}
    0;
  font-size: ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(14)};
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0
      ${(props) => (props.margin ? desktopVw(props.margin) : desktopVw(70))} 0;
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(16)};
  }

  span:last-child {
    color: ${colors.mailleGold};
  }
`

const StyledDecoration = styled.div`
  position: absolute;
  z-index: ${(props) => props.zIndex || 0};
  height: 100%;
  ${(props) => !props.zIndex && `width: 100%`}
`

const Margin = styled.div`
  margin: 0 0 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 0 ${desktopVw(128)};
  }
`

const StyledLinkType = styled(LinkType)`
  display: block;
`

const StyledClickableLink = styled(LinkType)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  // width: 100%;
  // height: 100%;
  z-index: 2;
  opacity: 0;
`
