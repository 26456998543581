import React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'


export const Person = props => {
  return (
    <StyledSvg width={17.1} height={11.5} viewBox='0 0 17 15.182' {...props}>
      <g transform='translate(.5 .5)' fill='none' stroke={props.color}>
        <path d='M11.993 7.251a4.719 4.719 0 01-7.986 0A8 8 0 000 14.182h16a8 8 0 00-4.007-6.931z' />
        <circle cx={4.727} cy={4.727} r={4.727} transform='translate(3.273)' />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(16.1)};
  height: ${mobileVw(10.5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32.24)};
    height: ${desktopVw(21.02)};
  }
`
