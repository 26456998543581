export const useImageAnalytics = (image) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants.trackEvent,
        eventAction: ctConstants.imageclick,
        eventLabel: image,
        eventValue: 1,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Read',
    })
    console.log(window.digitalData)
  }
}
