import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint, colors } from '../../../styles'

export const Close = (props) => {
  return (
    <StyledSvg
      width={10.708}
      height={10.708}
      viewBox='0 0 10.708 10.708'
      {...props}
    >
      <g transform='translate(0.354 0.354)'>
        <line
          x1='0.001'
          y2='14.142'
          transform='translate(10 0) rotate(45)'
          fill='none'
          stroke={props.color || colors.mailleGold}
          strokeWidth='1'
        />
        <line
          y1='0.001'
          x2='14.142'
          transform='translate(0.001 0) rotate(45)'
          fill='none'
          stroke={props.color || colors.mailleGold}
          strokeWidth='1'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
