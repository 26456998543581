import React from 'react'
import styled from 'styled-components'

export const Time = props => {
  return (
    <StyledSvg width={19} height={19} viewBox='0 0 19 19' {...props}>
      <g
        transform='translate(.269 .5)'
        fill='none'
        stroke={props.color}
        strokeMiterlimit={10}
      >
        <circle cx={9} cy={9} r={9} transform='translate(.231)' />
        <path d='M14.99 12.168L9.751 8.722V3.041' />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
