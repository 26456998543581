import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import {
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  mobileVw,
  colors,
} from '../../styles'

export const TagsBlock = ({ servings, prepTime, tags, difficulty }) => {
  const difficultyIcon = (difficulty) => {
    switch (difficulty) {
      case 'Explorateur':
        return 'difficulty1'
      case 'Epicurien':
        return 'difficulty2'
      case 'Epicurean':
        return 'difficulty2'
      case 'Expert':
        return 'difficulty3'

      default:
        return 'difficulty1'
    }
  }
  return (
    <Wrapper aria-label='Product tags'>
      <PersonTag>
        <PersonIconWrapper>
          <Icon name='person' color='#AE841F' />
        </PersonIconWrapper>
        <Title>{servings}</Title>
      </PersonTag>
      <TimeTag>
        <IconWrapper>
          <Icon name='time' color='#AE841F' />
        </IconWrapper>
        <Title>{prepTime}'</Title>
      </TimeTag>
      <TagStar>
        <Tag>
          <IconWrapper>
            <Icon
              name={difficulty ? difficultyIcon(difficulty[0]) : 'difficulty1'}
              color='#AE841F'
            />
          </IconWrapper>
          <Title>{difficulty ? difficulty[0] : 'Explorateur'}</Title>
        </Tag>
      </TagStar>

      <Labels>
        {tags &&
          tags.map((tag, i) => {
            return (
              <FoodTag key={i}>
                <IconWrapper>
                  <Icon name={tag} color='#AE841F' />
                </IconWrapper>
                <Title>
                  {tag == 'vegetarian'
                    ? 'VÉGÉTARIEN'
                    : tag == 'vegan'
                      ? 'VÉGAN'
                      : tag}
                </Title>
              </FoodTag>
            )
          })}
      </Labels>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: ${colors.black};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 ${mobileVw(16)} ${mobileVw(8)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
  }
`

const Tag = styled.div`
  box-sizing: border-box;
  padding: ${mobileVw(12)} ${mobileVw(16)};
  border: 1px solid ${colors.mailleGold};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${mobileVw(40)};
  margin: 0 ${mobileVw(8)} ${mobileVw(8)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(56)};
    padding: ${desktopVw(18)} ${desktopVw(17)} ${desktopVw(18)} ${desktopVw(15)};
    margin: 0 ${desktopVw(16)} ${desktopVw(16)} 0;
  }
`

const PersonTag = styled(Tag)`
  width: ${mobileVw(72)};
  margin: 0 ${mobileVw(8)} ${mobileVw(8)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(65)};
    margin: 0 ${desktopVw(15)} ${desktopVw(16)} 0;
  }
`

const TimeTag = styled(Tag)`
  width: ${mobileVw(80)};
  margin: 0 ${mobileVw(8)} ${mobileVw(8)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(80)};
    margin: 0 ${desktopVw(16)} ${desktopVw(16)} 0;
  }
`

const Title = styled.span`
  display: inline-block;
  margin: 0;
  color: ${colors.mailleGold};
  font-family: 'Tivoli Bold';
  font-size: ${mobileVw(16)};
  line-height: 1;
  text-align: center;
  letter-spacing: ${letterSpacing(100)};
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: 1.2;
  }
`

const IconWrapper = styled.div`
  max-width: ${mobileVw(25)};
  height: 100%;
  margin: 0 ${mobileVw(16)} 0 0;
  display: flex;
  align-items: center;
  svg {
    height: 100%;
    width: 100%;
  }
  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVw(25)};
    height: 100%;
    margin: 0 ${desktopVw(11)} 0 0;
    padding: 0 0 ${desktopVw(2)};
  }
`

const PersonIconWrapper = styled(IconWrapper)`
  @media (min-width: ${desktopBreakpoint}) {
    svg {
      width: ${desktopVw(16)};
      height: ${desktopVw(14.18)};
    }
  }
`

const TagStar = styled.button`
  background-color: transparent;
  margin: 0 0 ${mobileVw(8)} 0;
  border: 1px solid ${colors.mailleGold};
  padding: 0;
  height: ${mobileVw(40)};
  width: calc(50% - ${mobileVw(24)});
  ${Tag} {
    padding: ${mobileVw(14)} ${mobileVw(12)} ${mobileVw(16)} ${mobileVw(14)};
    margin: 0;
    border: 0;
    width: 100%;
  }
  ${IconWrapper} {
    max-width: ${mobileVw(10.55)};
    height: ${mobileVw(10.1)};
    margin: 0 ${mobileVw(4.6)} 0 0;
  }
  svg {
    width: ${mobileVw(10.55)};
    height: ${mobileVw(10.1)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(56)};
    width: ${desktopVw(168)};
    margin: 0 0 ${desktopVw(16)} 0;
    ${Tag} {
      padding: ${desktopVw(18)} ${desktopVw(17)} ${desktopVw(18)}
        ${desktopVw(15)};
    }
    ${IconWrapper} {
      max-width: ${desktopVw(25)};
      height: 100%;
      margin: 0 ${desktopVw(11)} 0 0;
    }
    svg {
      width: ${desktopVw(13.1)};
      height: ${desktopVw(12.54)};
    }
  }
`

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  ${Tag} {
    padding: ${mobileVw(11)} ${mobileVw(11)} ${mobileVw(11)} ${mobileVw(8.7)};
    text-align: center;
    &:nth-child(even) {
      margin: 0 0 ${mobileVw(8)} 0;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    ${Tag} {
      width: ${desktopVw(160)};
      padding: ${desktopVw(17)} ${desktopVw(16)} ${desktopVw(16)}
        ${desktopVw(16)};
      &:nth-child(even) {
        margin: 0;
        width: ${desktopVw(168)};
      }
    }
  }
`

const FoodTag = styled(Tag)`
  ${IconWrapper} {
    margin: 0 ${mobileVw(5)} 0 0;
    height: ${mobileVw(19)};
    width: ${mobileVw(20)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    ${IconWrapper} {
      margin: 0 ${desktopVw(8)} 0 0;
      height: ${desktopVw(24)};
      width: ${desktopVw(25)};
    }
  }
`
