import React from 'react'
import styled from 'styled-components'

export const Glutenfree = props => {
  if (props.circle === 'true') {
    return (
      <StyledSvg viewBox='0 0 41 41' {...props}>
        <g
          data-name='Group 1177'
          transform='translate(.5 .5)'
          fill='none'
          stroke='#ae841f'
          strokeMiterlimit={10}
        >
          <circle data-name='Ellipse 58' cx={20} cy={20} r={20} />
          <path
            data-name='Path 1392'
            d='M31.372 33.206c-6.3-16.766-18.181-20.3-18.181-20.3'
          />
          <path
            data-name='Path 1393'
            d='M16.953 13.957s-3.837-2.635-.263-5.928c0 .003 3.837 2.635.263 5.928z'
          />
          <path
            data-name='Path 1394'
            d='M13.476 12.892s-2.9 3.64-5.932-.158c-.005 0 2.896-3.641 5.932.158z'
          />
          <path
            data-name='Path 1395'
            d='M11.96 18.325s-.063-4.654 4.69-3.637c0 0 .062 4.65-4.69 3.637z'
          />
          <path
            data-name='Path 1396'
            d='M24.642 20.829s-3.034-3.53 1.263-5.8c0 .002 3.034 3.534-1.263 5.8z'
          />
          <path
            data-name='Path 1397'
            d='M18.697 23.772s1.132-4.515 5.465-2.314c.004 0-1.132 4.515-5.465 2.314z'
          />
          <path
            data-name='Path 1398'
            d='M20.869 16.817s-3.551-3.01.337-5.925c0 0 3.551 3.01-.337 5.925z'
          />
          <path
            data-name='Path 1399'
            d='M15.461 20.657s.409-4.637 5.034-3.144c0 0-.409 4.637-5.034 3.144z'
          />
          <path
            data-name='Path 1400'
            d='M27.716 25.053s-2.144-4.131 2.559-5.354c0 0 2.145 4.131-2.559 5.354z'
          />
          <path
            data-name='Path 1401'
            d='M21.256 26.555s2.136-4.136 5.849-1c0 0-2.136 4.136-5.849 1z'
          />
        </g>
      </StyledSvg>
    )
  }
  return (
    <StyledSvg viewBox='0 0 18.473 19.16' {...props}>
      <g
        data-name='Group 821'
        fill='none'
        stroke={props.color}
        strokeMiterlimit={10}
      >
        <path
          data-name='Path 1392'
          d='M18.005 18.981C13.415 6.764 4.757 4.189 4.757 4.189'
        />
        <path
          data-name='Path 1393'
          d='M7.499 4.958a2.594 2.594 0 01-.192-4.32 2.594 2.594 0 01.192 4.32z'
        />
        <path
          data-name='Path 1394'
          d='M4.963 4.181A2.594 2.594 0 01.64 4.066a2.594 2.594 0 014.323.115z'
        />
        <path
          data-name='Path 1395'
          d='M3.862 8.14a2.594 2.594 0 013.417-2.65 2.594 2.594 0 01-3.417 2.65z'
        />
        <path
          data-name='Path 1396'
          d='M13.103 9.965a2.594 2.594 0 01.921-4.225 2.594 2.594 0 01-.921 4.225z'
        />
        <path
          data-name='Path 1397'
          d='M8.771 12.109a2.594 2.594 0 013.982-1.686 2.594 2.594 0 01-3.982 1.686z'
        />
        <path
          data-name='Path 1398'
          d='M10.354 7.042a2.594 2.594 0 01.246-4.318 2.594 2.594 0 01-.246 4.318z'
        />
        <path
          data-name='Path 1399'
          d='M6.413 9.839a2.594 2.594 0 013.668-2.291 2.594 2.594 0 01-3.668 2.291z'
        />
        <path
          data-name='Path 1400'
          d='M15.342 13.044a2.594 2.594 0 011.865-3.9 2.594 2.594 0 01-1.865 3.9z'
        />
        <path
          data-name='Path 1401'
          d='M10.636 14.137a2.594 2.594 0 014.262-.729 2.594 2.594 0 01-4.262.729z'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
