import React, { useState, useEffect } from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useTransition, animated } from 'react-spring'
import {
  desktopBreakpoint,
  letterSpacing,
  mobileVw,
  shortMobVw,
  colors,
  desktopVw,
  fonts,
} from '../../styles'
import {
  useToggleMenu,
  useLayout,
  useSiteQuery,
  useLogoutCustomer,
  useCustomer,
  useLang,
  useLocale,
} from '../../hooks'
import { Icon, LinkType, Button } from '../shared'
import { CollectionSlider } from '../shared/sliders'
import { ArrowRight } from '../shared/icons'
import GammeOverlay from './GammeOverlay'
import HeaderButtons from './headerButtons'
import SearchSuggestions from './searchSuggestions'

const Aside = ({ logo, links, search, openSearch, mailleNewLogo }) => {
  const { menuIsOpen } = useLayout()
  const toggleAside = useToggleMenu()

  const locale = useLocale();

  const lang = useLang();

  const [overlayIndex, setOverlayIndex] = useState<number>();

  const user = useCustomer()
  const [logoutCustomer, { response, error }] = useLogoutCustomer()

  const [visibility, setVisibility] = useState(false)
  const { navigationAsideDecoration } = useSiteQuery()

  const asideTransition = useTransition(menuIsOpen, null, {
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(-100%)' },
  })

  const [searchTerm, setSearchTerm] = React.useState('')
  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const collectionData = [
    { title: 'Moutardes' },
    { title: 'Cornichons' },
    { title: 'Huiles & vinaigres' },
    { title: 'Cadeaux' },
  ]

  const handleClose = () => {
    toggleAside()
    setSearchTerm('')
  }

  const handleLogout = async () => {
    await logoutCustomer(user)
    toggleAside()
  }

  useEffect(() => {
    if (response) {
      setTimeout(() => {
        navigate('/fr')
      }, 1000)
    }
  }, [response])

  const handleGoAccount = () => {
    navigate(`/account`)
    toggleAside()
  }

  const enToFr = (url) => {
    const newurl = url.replace("ca/en", "ca/fr")
    return newurl
  }
  const frToEn = (url) => {
    const newurl = url.replace('ca/fr', 'ca/en')
    return newurl
  }

  return asideTransition.map(
    ({ item, key, props }) =>
      item && (
        <AsideWrapper key={key} style={props} noScroll={visibility}>
          <PaddedContainer>
            <AsideHeader>
              <a href={`/${lang}`} alt='Maille logo'>
                <StyledImage src={mailleNewLogo} alt='Maille logo' /> 
              </a>
              <HeaderButtons />
              <button onClick={toggleAside}>FERMER</button>
            </AsideHeader>
            <SearchForm aria-label='Search form'>
              <input
                type='text'
                placeholder='Rechercher'
                value={searchTerm}
                onChange={handleChange}
              />
              <button>
                <Icon name='search' />
              </button>
            </SearchForm>
            <div onClick={handleClose}>
              {searchTerm ? <SearchSuggestions search={searchTerm} /> : null}
            </div>
          </PaddedContainer>
          <NavLinks>
            {links.map(({ title, optionalIcon, link, externalLink }, i) => {
              return (
                <li key={i}>
                    <LinkType
                      activeClassName='active'
                      to={
                        link && link.slug
                          ? `/${lang}${link.slug}`
                          : `/${lang}${externalLink}`
                      }
                      alt={`Ce lien va ouvrir une nouvelle fenêtre ${title}`}
                    >
                      <Icon name={optionalIcon} />
                      <span onClick={toggleAside}>{title}</span>
                      {title === 'Nos produits' ? (
                        <ButtonWrapper
                          onClick={() => { setOverlayIndex(1), setVisibility(!visibility) }}
                        >
                          <button>
                            <ArrowRight />
                          </button>
                        </ButtonWrapper>
                      ) : null}
                      {title === 'Personnalisation' ? (
                        <ButtonWrapper
                          onClick={() => { setOverlayIndex(2), setVisibility(!visibility) }}
                        >
                          <button>
                            <ArrowRight />
                          </button>
                        </ButtonWrapper>
                      ) : null}
                      {title.toLowerCase() === 'boutique en ligne' ? (
                        <ButtonWrapper
                          onClick={() => { setOverlayIndex(0), setVisibility(!visibility) }}
                        >
                          <button>
                            <ArrowRight />
                          </button>
                        </ButtonWrapper>
                      ) : null}
                    </LinkType>
                    {title === 'Ma communauté Maille' ? (
                      <StyledList>
                        <li>
                          <button
                            onClick={() => {
                              handleGoAccount()
                            }}
                            as='li'
                          >
                            Mon compte
                          </button>
                        </li>
                        <li>
                          <button onClick={() => handleLogout()}>
                            Déconnexion
                          </button>
                        </li>
                        <li>S'inscrire à la newsletter</li>
                        <li>
                          Site <span>France</span>
                        </li>
                      </StyledList>
                    ) : null}
                  </li>
              )
            })}

            {
              locale === 'en-CA' &&
              <button
                type='button'
                onClick={() => {
                  navigate(`${enToFr(location.pathname)}`)
                  // navigate(`/ca/fr`)
                }}
              >
                <StyledSpan style={{ color: `${colors.mailleGold}` }}>
                  Version française
                </StyledSpan>
              </button>
            }
            {
              locale === 'fr-CA' &&
              <button
                type='button'
                onClick={() => {
                  navigate(`${frToEn(location.pathname)}`)
                  // navigate(`/ca/en`)
                }}
              >
                <StyledSpan style={{ color: `${colors.mailleGold}` }}>
                  English Version
                </StyledSpan>
              </button>
            }
            <Image
              style={{ marginTop: `${mobileVw(30)}` }}
              fluid={navigationAsideDecoration.fluid}
              alt={navigationAsideDecoration.title}
            />
          </NavLinks>
          {visibility && (
            <GammeOverlay
              onClick={() => setVisibility(!visibility)}
              data={links[overlayIndex].link.slides}
            />
          )}
        </AsideWrapper>

      ),
  )
}

const StyledSpan = styled.span`
  color: ${colors.white};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(18)};
  letter-spacing: ${mobileVw(1.6)};
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    letter-spacing: ${desktopVw(1.4)};
  }
`

const AsideWrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  color: #fff;
  z-index: 100;
  overflow-x: ${(props) => (props.noScroll ? 'none' : ' hidden')};
  overflow-y: ${(props) => (props.noScroll ? 'none' : ' scroll')};
  max-width: 100vw;

  li {
    padding-left: ${mobileVw(16)};
  }

  .swiper-container {
    padding-right: ${mobileVw(24)};
    .swiper-pagination-progressbar,
    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`
const SliderWrapper = styled.div`
  h2 {
    display: none;
  }

  .swiper-slide {
    img {
      height: ${mobileVw(96)};
      background-color: ${colors.black};
    }
  }

  .swiper-wrapper {
    button {
      font-size: ${mobileVw(14)};
      line-height: ${mobileVw(20)};
      font-family: ${fonts.splandor};
      margin: ${mobileVw(18)} 0 0 0;
      text-transform: none;
      background-color: inherit;
      padding: 0;
    }
  }

  .slider-image-wrapper {
    background-color: ${colors.black};
    height: auto;
    mix-blend-mode: multiply;
  }

  button {
    color: white;
  }

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const PaddedContainer = styled.div`
  width: 100%;
  padding: ${mobileVw(16)};
`

const ButtonWrapper = styled.div`
  width: 100%;
  height: ${mobileVw(52)};
  position: absolute;
  display: flex;
  justify-content: flex-end;

  button {
    position: relative;
    right: ${mobileVw(90)};
  }
`

const StyledList = styled.ul`
  margin-top: ${mobileVw(21)};
  list-style-type: none;
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(16)};
  letter-spacing: ${mobileVw(1.6)};
  text-transform: uppercase;
  cursor: pointer;

  li {
    margin-bottom: ${mobileVw(20)};
  }

  span {
    color: ${colors.mailleGold};
  }
  button {
    color: ${colors.white};
    text-transform: uppercase;
    font-family: ${fonts.tivoli};
    font-size: ${mobileVw(16)};
    letter-spacing: ${mobileVw(1.6)};
    padding-left: 0;
    cursor: pointer;
  }
`

const StyledImage = styled.img`
  width: ${mobileVw(50)};
  margin-right: ${mobileVw(90)};
`
const StyledImageCa = styled(Image)`
  width: ${mobileVw(50)};
  margin-right: ${mobileVw(90)};
`
const AsideHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > button {
    padding: ${mobileVw(16)} 0px;
    font-family: ${fonts.tivoli};
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    letter-spacing: ${letterSpacing(100)};
    color: ${colors.mailleGold};
    cursor: pointer;
  }
`

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${shortMobVw([32, 0, 16])};
  border: 1px solid ${colors.mailleGold};
  input {
    background: transparent;
    border: none;
    width: 100%;
    padding: ${mobileVw(12)};
    font-family: 'MrsEaves';
    font-size: ${mobileVw(14)};
    line-height: ${mobileVw(24)};
    color: ${colors.mailleGold};
  }
  button {
    padding: ${mobileVw(16)};
    display: flex;
    align-items: center;
  }
`

const NavLinks = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;

  li {
    &:nth-child(7){
        a{
            color:#ae841f;
        } 
      }
}
    a {
      display: flex;
      align-items: center;
      padding: ${mobileVw(16)} 0px;
      font-family: ${fonts.tivoli};
      font-size: ${mobileVw(20)};
      line-height: ${mobileVw(14)};
      letter-spacing: ${letterSpacing(100)};
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;

      svg {
        margin: 0 ${mobileVw(16)} 0px 0;
        fill: ${colors.mailleGold};
      }

      button {
        cursor: pointer;
      }
    }
  }
`

export default Aside
