import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  desktopBreakpoint,
  desktopVw,
  mobileVw,
  letterSpacing,
  colors,
} from '../../styles'
import { useLang } from '../../hooks'
export const BreadCrumbs = ({ breadcrumbs, color, title, className, type }) => {
  const lang = useLang();

  return (
    <BreadcrumbsWrapper
      className={`${className} ${type === 'recipe collection' && 'is-recipe-collection'
        }`}
    >
      <Breadcrumbs color={color}>
        {breadcrumbs &&
          breadcrumbs.map(({ buttonText, target, link, title }, index) => {
            if (target && buttonText) {
              return (
                <Link
                  to={`/${lang}${target.slug}`}
                  key={index}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${target.slug}`}
                >
                  {buttonText} &gt;
                </Link>
              )
            }
            else if (link && title) {
              return (
                <Link
                  to={`/${lang}${link.slug}`}
                  key={index}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${link.slug}`}
                >
                  {title} &gt;
                </Link>
              )
            }
          })}
        {typeof title === 'string' ? (
          <span>{title}</span>
        ) : (
          title && <span>{title.join(' ').replace(',', '')}</span>
        )}
      </Breadcrumbs>
    </BreadcrumbsWrapper>
  )
}

const BreadcrumbsWrapper = styled.div`
  width: ${mobileVw(254)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(352)};

    &.is-recipe-collection {
      margin: 0 0 ${desktopVw(106)};
    }
  }
`

const Breadcrumbs = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  font-family: 'Tivoli Bold';
  font-size: ${mobileVw(14)};
  line-height: 1;
  font-weight: normal;
  letter-spacing: ${letterSpacing(100)};

  a {
    font: inherit;
    color: inherit;
    text-decoration: none;
    margin: 0 ${mobileVw(5)} 0 0;
    white-space: nowrap;
  }

  span {
    font: inherit;
    color: ${colors.mailleGold};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};

    a {
      margin: 0 ${desktopVw(5)} 0 0;
    }
  }
`
