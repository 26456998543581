import { useState } from 'react'
import { useSetCustomer } from './useSetCustomer'
import PasswordValidator from 'password-validator'

export interface Istate {
  response?: string
  loading: boolean
  error?: string
}

export const useRegisterCustomer = () => {
  const setCustomer = useSetCustomer()
  const [state, setState] = useState<Istate | null>({
    response: null,
    loading: false,
    error: null,
  })

  const schema = new PasswordValidator()

  schema.is().min(8).is().max(100).has().lowercase().has().uppercase()

  const registerCustomer = async (
    email: string,
    passwordField1: string,
    passwordField2: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
  ) => {
    if (!schema.validate(passwordField1)) {
      setState({
        response: null,
        loading: false,
        error:
          'Votre mot de passe doit avoir entre 8 et 100 caractères, et avoir au moins une lettre majuscule et une lettre minuscule.',
      })
    } else if (passwordField1 !== passwordField2) {
      setState({
        response: null,
        loading: false,
        error: 'le mot de passe ne correspond pas.',
      })
    } else if (
      email &&
      passwordField1 &&
      firstName &&
      lastName &&
      phoneNumber
    ) {
      setState({ response: null, loading: true, error: null })
      try {
        const data = await fetch(`/.netlify/functions/register`, {
          method: 'POST',
          body: JSON.stringify({
            email,
            password: passwordField1,
            firstName,
            lastName,
            phoneNumber,
          }),
        })

        const res = await data.json()
        if (res.error) {
          setState({
            response: null,
            loading: false,
            error: res.error,
          })
        } else {
          setCustomer(res, email)
          setState({
            response: res,
            loading: false,
            error: null,
          })
        }
      } catch (error) {
        setState({
          response: null,
          loading: false,
          error,
        })
      }
    } else {
      setState({
        response: null,
        loading: false,
        error:
          'L "entrée" est manquante. email, passwordField 1, passwordField 2, firstName & lastName sont requis',
      })
    }
  }

  return [registerCustomer, state] as const
}
