import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  letterSpacing,
  colors,
  fonts,
} from '../../styles'

import { Button } from '../shared'
import { useCreateContact, useNewsletterCustomer, useNewsletterLeadAnalytics } from '../../hooks'

export const EmailForm = ({
  formId,
  setSuccesForm,
  listId = null,
  newsletterCheckbox = null,
  newsletterSubmit = null,
  newsletterButton = null,
}) => {
  const [email, setEmail] = useState('')
  const [demFreeda, setDemFreeda] = useState(false)
  const [newsletterCustomer] = useNewsletterCustomer()
  const [succes, setSucces] = useState(false)

  const [createContact, { response: createContactResponse, loading, error: createContactError }] = useCreateContact()

  const validateEmail = (item) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = re.test(String(item).toLowerCase())
    return valid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // useNewsletterLeadAnalytics(form)
    if (validateEmail(email)) {
      await newsletterCustomer(email, demFreeda)
      await createContact({
        "formData": {
          "email": email,
          "emailType": "Html",
        },
        "listId": listId
      })
    }
  }
  useEffect(() => {
    if (createContactResponse && !createContactError) {
      setSucces(true)
      setSuccesForm ? setSuccesForm(true) : null
    }
  }, [createContactError, createContactResponse])

  return (
    <Newsletter onSubmit={(e) => handleSubmit(e)} aria-label={formId}>
      {!succes ? (
        <>
          <input type='hidden' name='form-name' value='newsletter' />
          <Input
            name='email'
            type='email'
            placeholder='Email'
            id={formId}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor='email' aria-label='email' />
          <Statement>
            <StyledCheckbox type='checkbox' required />
            {
              newsletterCheckbox ?
                newsletterCheckbox
                :
                <>
                  Je confirme avoir plus de 16 ans et souhaite recevoir des
                  informations de la part de Maille sur les nouveaux produits et
                  services.
                </>

            }
          </Statement>
          <Button type='submit' text={newsletterButton ? newsletterButton : "s'abonner"} disabled={loading} />
        </>
      ) : (

        newsletterSubmit ? <Strong>{newsletterSubmit}</Strong> : <Strong>Merci pour l'enregistrement</Strong>


      )}
    </Newsletter>
  )
}

const Newsletter = styled.form`
  width: 100%;
  margin: 0;
  h4 {
    margin: 0 0 ${mobileVw(15)} 0;
    font-family: 'Tivoli Bold';
    font-size: ${mobileVw(20)};
    line-height: ${mobileVw(16)};
    letter-spacing: ${letterSpacing(100)};
    text-transform: uppercase;
    color: ${colors.mailleGold};
  }
  @media (min-width: ${desktopBreakpoint}) {
    /* margin: 0 ${desktopVw(125)} 0 0; */
    margin: 0;
    h4 {
      font-size: ${desktopVw(20)};
      line-height: ${desktopVw(16)};
      margin: 0 0 ${desktopVw(8)} 0;
    }
  }
`

const Input = styled.input`
  width: 100%;
  border: 0.5px solid #fff;
  color: #fff;
  background: transparent;
  font-size: ${mobileVw(16)};
  padding: ${mobileVw(11)} ${mobileVw(15)};
  margin: 0 0 ${mobileVw(17)} 0;
  font-family: 'MrsEaves';
  outline: none;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    padding: ${desktopVw(15)} ${desktopVw(24)};
    margin: 0 0 ${desktopVw(17)} 0;
    border: ${desktopVw(0.5)} solid #fff;
  }
  &::placeholder {
    color: #fff;
    font-family: 'MrsEaves';
  }
`
const Statement = styled.div`
  margin: 0 0 ${mobileVw(12)} 0;
  text-align: left;
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
  font-size: ${mobileVw(12)};
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(20)} 0;
    font-size: ${desktopVw(14)};
  }
`

const StyledCheckbox = styled.input`
  background: transparent;
  border-radius: 0;
  display: block;
  margin: 0 ${mobileVw(7)} 0 0;
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};
  border: ${mobileVw(0.5)} solid #fff;
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(12)} 0 0;
    width: ${desktopVw(16)};
    height: ${desktopVw(16)};
    border: ${desktopVw(0.5)} solid #fff;
  }
`

const Strong = styled.p`
  margin: 0 0 ${mobileVw(12)} 0;
  font-size: ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  text-transform: uppercase;
  letter-spacing: ${letterSpacing(100)};
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(20)} 0;
    font-size: ${desktopVw(16)};
  }
`