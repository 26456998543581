import React from 'react'
import styled from 'styled-components'

export const HeartFilled = (props) => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 18'
      {...props}
    >
      <path
        d='M-1019.9-2212.08a5.017,5.017,0,0,0-4.181,2.159,5.012,5.012,0,0,0-4.177-2.166,4.642,4.642,0,0,0-3.444,1.333,4.641,4.641,0,0,0-1.38,3.426c0,3.073,3.128,6.129,7.089,10q.851.831,1.744,1.714l.168.166.167-.166c.593-.588,1.176-1.158,1.742-1.712,3.961-3.871,7.09-6.928,7.09-10a4.636,4.636,0,0,0-1.378-3.422A4.636,4.636,0,0,0-1019.9-2212.08Z'
        transform='translate(1033.084 2212.091)'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
