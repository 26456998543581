import React from 'react'
import styled from 'styled-components'

export const Visa = props => {
  return (
    <StyledSvg
      width={36.794}
      height={24.529}
      viewBox='0 0 36.794 24.529'
      {...props}
    >
      <path
        data-name='Path 55'
        d='M30.143 10.971l.575 2.631h-2.06l.989-2.546.33-.869zm6.65-7.9v18.4a3.067 3.067 0 01-3.066 3.066H3.066A3.067 3.067 0 010 21.463V3.066A3.067 3.067 0 013.066 0h30.661a3.067 3.067 0 013.067 3.066zM10.508 16.819l3.908-9.09h-2.63l-2.43 6.21-.256-1.261-.871-4.185a1.039 1.039 0 00-1.125-.77h-4l-.032.189a10.5 10.5 0 012.608 1l2.208 7.911zm7.394-9.1H15.42l-1.552 9.111h2.482zm7.09 6.131c.009-1.036-.652-1.824-2.083-2.474-.868-.422-1.4-.7-1.394-1.128 0-.379.449-.783 1.421-.783a4.566 4.566 0 011.858.35l.222.1.336-1.973a6.39 6.39 0 00-2.224-.383c-2.453 0-4.181 1.236-4.2 3.006-.014 1.309 1.234 2.039 2.175 2.474.966.446 1.291.731 1.286 1.13-.006.61-.771.889-1.486.889a5.2 5.2 0 01-2.336-.478l-.32-.146-.347 2.045a7.839 7.839 0 002.766.486c2.612 0 4.306-1.222 4.326-3.112zm8.735 2.98l-2.01-9.1H29.8a1.235 1.235 0 00-1.3.756l-3.687 8.346h2.606l.523-1.369h3.18l.3 1.364z'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
