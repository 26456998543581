import { useContext } from 'react'
import { StoreContext } from '../../contexts'

export function useWishlistProducts() {
  const { setLayout } = useContext(StoreContext)

  async function setWishlistProducts(newWishlistProducts) {
    setLayout((prevState) => {
      return { ...prevState, wishlistProducts: newWishlistProducts }
    })
  }

  return setWishlistProducts
}
