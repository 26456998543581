import React from 'react'
import styled from 'styled-components'

export const ApplePay = props => {
  return (
    <StyledSvg
      data-name='Group 33'
      width={38.327}
      height={24.524}
      viewBox='0 0 38.327 24.524'
      {...props}
    >
      <path
        data-name='Path 52'
        d='M22.347 14.312c0 .556.464.926 1.158.926a1.532 1.532 0 001.645-1.459v-.51l-1.576.093c-.787.047-1.227.394-1.227.95z'
        fill='#fff'
      />
      <path
        data-name='Path 53'
        d='M17.391 8.545h-1.574v3.474h1.574a1.642 1.642 0 001.853-1.737 1.631 1.631 0 00-1.853-1.737z'
        fill='#fff'
      />
      <path
        data-name='Path 54'
        d='M38.258 1.992a2.728 2.728 0 00-.232-.695 2.649 2.649 0 00-.417-.579 2.051 2.051 0 00-.579-.417 2.227 2.227 0 00-.694-.232c-.232-.046-.487-.046-.719-.069H2.71a3.767 3.767 0 00-.718.069 2.72 2.72 0 00-.695.232 2.051 2.051 0 00-.579.417 2.05 2.05 0 00-.417.579 2.236 2.236 0 00-.232.695c-.046.232-.046.486-.069.718v19.105a3.764 3.764 0 00.069.718 2.73 2.73 0 00.232.695 2.614 2.614 0 00.417.579 2.047 2.047 0 00.579.417 2.245 2.245 0 00.695.232c.231.046.487.046.718.069h32.907a3.773 3.773 0 00.719-.069 2.726 2.726 0 00.694-.232 2.633 2.633 0 00.579-.417 2.051 2.051 0 00.417-.579 2.237 2.237 0 00.232-.695c.046-.232.046-.486.069-.718V2.71a3.8 3.8 0 00-.069-.718zM9.542 7.434a2.2 2.2 0 011.412-.718 2.13 2.13 0 01-.486 1.528 1.661 1.661 0 01-1.435.648 2.011 2.011 0 01.508-1.458zm2.732 7.619c-.394.6-.811 1.158-1.459 1.181s-.856-.37-1.574-.37-.949.37-1.552.394c-.625.023-1.111-.625-1.505-1.2-.834-1.181-1.436-3.335-.6-4.794a2.324 2.324 0 011.969-1.2c.625 0 1.2.417 1.575.417s1.042-.486 1.829-.44a2.155 2.155 0 011.714.949 2.1 2.1 0 00-1.02 1.806 2.1 2.1 0 001.274 1.922 4.673 4.673 0 01-.651 1.335zm5.442-1.9h-1.875v2.987h-1.366V7.386h3.289a2.753 2.753 0 012.895 2.872 2.784 2.784 0 01-2.943 2.896zm8.754 3.01h-1.25v-1.042h-.023a2.269 2.269 0 01-2.015 1.158 1.962 1.962 0 01-2.177-1.922c0-1.111.857-1.806 2.385-1.9l1.76-.093v-.486c0-.718-.486-1.135-1.273-1.135a1.221 1.221 0 00-1.343.926h-1.25c.069-1.158 1.065-2.015 2.64-2.015 1.552 0 2.547.811 2.547 2.107zm4.191.371c-.556 1.552-1.18 2.038-2.5 2.038-.093-.023-.417-.023-.533-.046v-1.065a2.506 2.506 0 00.417.023 1.07 1.07 0 001.134-.9c0-.023.117-.394.117-.394L26.98 9.798h1.413l1.621 5.21h.024l1.621-5.21h1.389z'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
