import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useCreateContact, useNewsletterCustomer } from '../../hooks'
import {
  colors,
  desktopBreakpoint,
  desktopVw,
  fonts,
  mobileVw,
} from '../../styles'
import { Button } from './Button'
import { NewsletterWDPopup } from './NewsletterWDPopup'

export const NewsletterWithDownload = ({ popupImage }) => {
  const [newsletterCustomer] = useNewsletterCustomer()
  const [createContact, { response: createContactResponse, loading, error: createContactError }] = useCreateContact()

  const [popupOpen, setPopupOpen] = useState(false)
  const formRef = useRef(null)

  const validateEmail = (item) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = re.test(String(item).toLowerCase())
    return valid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const email = e.target.elements[0].value
    const newsletterOptIn = e.target.elements[2].checked

    if (validateEmail(email)) {
      setPopupOpen(true)
      await newsletterCustomer(email, false).then(() => formRef.current.reset())
      if (newsletterOptIn) {
        await createContact({
          "formData": {
            "email": email,
            "emailType": "Html",
          },
          "listId": process.env.GATSBY_DOTDIGITAL_LIST_VEGAN_CAMPAIGN
        })
      }
    }
  }
  return (
    <Wrapper>
      <Title>
        <span>Envie de relever le challenge de </span>
        <span>veganuary?</span>
      </Title>
      <Description>
        <span>
          Puisez votre inspiration au sein de nos recettes saines et savoureuses
          pour créer vos délicieux menus vegan.
        </span>
        <span>Téléchargez notre livre de recettes vegan.</span>
      </Description>
      <Form onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        <TextInputsWrapper>
          <EmailInput
            type='email'
            id='veganuary-email-input'
            name='veganuary-email-input'
            placeholder='Email'
            required
          />
        </TextInputsWrapper>
        <CheckboxesWrapper>
          <CheckboxElement>
            <input
              type='checkbox'
              required
              id='veganuary-checkbox-1'
              name='veganuary-checkbox-1'
            />
            <label htmlFor="veganuary-checkbox-1">
              Je confirme avoir plus de 16 ans et souhaite recevoir des
              informations de la part de Maille sur les nouveaux produits et
              services.*
            </label>
          </CheckboxElement>
          <CheckboxElement>
            <input
              type='checkbox'
              id='veganuary-checkbox-2'
              name='veganuary-checkbox-2'
            />
            <label htmlFor="veganuary-checkbox-2">
              Oui, je veux recevoir des offres et actualités par email de la
              part d'autres marques Unilever sélectionnées avec attention par
              Unilever et des publicités en ligne personnalisées en fonction de
              mes intérêts et préférences.
            </label>
          </CheckboxElement>
          <PolicyNotice>
          Veuillez lire notre{' '}
              <a href='https://www.unilevernotices.com/france/french/privacy-notice/notice.html'>
                Politique de Confidentialité
              </a>{' '}
              et{' '}
              <a href='https://www.unilevernotices.com/france/french/cookie-notice/notice.html'>
                Politique de Cookies
              </a>{' '}
              pour comprendre comment nous utilisons vos données personnelles.
          </PolicyNotice>
          <FormNotice>
            *Les champs indiqués par un astérisque sont obligatoires
          </FormNotice>
        </CheckboxesWrapper>
        <SubmitButton type='submit' text='Téléchargez' />
      </Form>
      {popupOpen && (
        <NewsletterWDPopup image={popupImage} setPopupOpen={setPopupOpen} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: auto;
  width: 90vw;
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(24)};
  padding-top: ${mobileVw(30)};
  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(24)};
    font-size: ${desktopVw(18)};
    padding-top: 0;
  }
`

const Title = styled.h2`
  font-family: ${fonts.splandor};
  span {
    font-size: ${mobileVw(30)};
    &:first-child {
      color: ${colors.mailleGold};
    }
    &:last-child {
      color: ${colors.black};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    text-align: center;
    span {
      font-size: ${desktopVw(44)};
    }
  }
`

const Description = styled.p`
  display: flex;
  flex-direction: column;
  @media (min-width: ${desktopBreakpoint}) {
    text-align: center;
  }
`

const Form = styled.form`
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

const TextInputsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmailInput = styled.input`
  width: 100%;
  border: 0.5px solid ${colors.black};
  background: transparent;
  padding: ${mobileVw(11)} ${mobileVw(15)};
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    padding: ${desktopVw(15)} ${desktopVw(24)};
    width: ${desktopVw(500)};
  }
`

const CheckboxesWrapper = styled.div`
  padding: ${mobileVw(20)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(20)} 0;
    width: ${desktopVw(650)};
  }
`

const CheckboxElement = styled.div`
  font-size: ${mobileVw(13)};
  line-height: ${mobileVw(19)};
  input {
    margin: 0 ${mobileVw(10)} 0 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(21)};

    input {
      margin: 0 ${desktopVw(10)} 0 0;
    }
  }
`

const PolicyNotice = styled.p`
  font-size: ${mobileVw(13)};
  line-height: ${mobileVw(19)};

  a {
    text-decoration: underline;
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(21)};
    text-align: left;
  }
`

const FormNotice = styled.p`
  font-size: ${mobileVw(12)};
  line-height: ${mobileVw(12)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(12)};
    line-height: ${desktopVw(16)};
    margin-bottom: 0;
    text-align: right;
  }
`

const SubmitButton = styled(Button)`
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(500)};
  }
`