import React, { useState } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { Image } from '../Image'
import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  colors,
  fonts,
} from '../../../styles'
import { useLang, useLocale, useProductCategoryAnalytics } from '../../../hooks'
import { Button } from '../Button'
import { LinkType } from '../LinkType'
import { Space } from '../Space'
import RichText from '../RichText'

export const CollectionSlider = ({
  titleHeading,
  newDescription,
  slides,
  button,
  buttonSlug,
  menu,
  refContainer,
  index,
  slidesMobile,
  topTitle,
  topSubtitle,
  optionalButtonText,
  height,
  productCategory,
  menuCarousel
}) => {
  const [swiper, setSwiper] = useState(null)
  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    watchOverflow: true,
    breakpoints: {
      280: {
        slidesPerView: slidesMobile || 1.7,
        spaceBetween: 24,
      },
      1024: {
        slidesPerView: 4,
        allowTouchMove: false,
      },
    },
  }


  const lang = useLang();
  const locale = useLocale();
  
  return (
    <StyledCollectionSlider
      ref={index && index === menu ? refContainer : null}
      aria-label='Collection slider'
    >
      <Container>
       {titleHeading && <StyledTitle>
          {typeof title === 'string'
            ? titleHeading
            : titleHeading.map((word, i) => <span key={i}>{word}</span>)}
        </StyledTitle> }
        {newDescription && 
        <StyledSpan>
        <span>
          <RichText json={newDescription} />
        </span>
      </StyledSpan>}
        <Wrapper showProgressBar={slides.length > 4 && !menuCarousel} menuCarousel={menuCarousel}>
          <Swiper {...params} getSwiper={setSwiper}>
            {slides &&
              slides.map(
                ({ title, thumbnailImage, secondaryImage, lifestyleImage, slug, tags , navbarText}, i) => {
                  return (
                    <Itemwrap key={i}>

                      <>
                        { topTitle && 
                          <Item
                            to={`/${lang}${slug}`}
                            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                            onClick={
                              productCategory
                                ? () => useProductCategoryAnalytics(title, slug)
                                : null
                            }
                          >
                            {topTitle ? (
                              <Title
                                style={
                                  optionalButtonText && slug === '/e-boutique'
                                    ? { color: `${colors.mailleGold}` }
                                    : null
                                }
                                menuCarousel={menuCarousel}
                              >
                                {title}
                              </Title>
                            ) : null}
                            {topSubtitle && navbarText ? (
                              <Subtitle
                                style={
                                  slug === '/e-boutique'
                                    ? { color: `${colors.mailleGold}` }
                                    : null
                                }
                                menuCarousel={menuCarousel}
                              >
                                {navbarText && navbarText}
                              </Subtitle>
                            ) : null}
                          </Item>
                        }
                      </>


                      <>
                      <Item

                        to={`/${lang}${slug}`}
                        alt={`Ce lien va ouvrir une nouvelle fenêtre: ${title}`}
                        onClick={
                          productCategory
                            ? () => useProductCategoryAnalytics(title, slug)
                            : null
                        }
                      >
                        <StyledImage
                          height={height}
                          className='slider-image-wrapper'
                          fluid={
                            lifestyleImage ? lifestyleImage.fluid :
                            thumbnailImage
                              ? thumbnailImage.fluid
                              : secondaryImage.fluid
                          }
                          alt={
                            lifestyleImage ? lifestyleImage.title :
                            thumbnailImage
                              ? thumbnailImage.title
                              : secondaryImage.title
                          }
                        />
                        { (tags || title) && <RecipeButton
                          style={
                            optionalButtonText && slug !== '/e-boutique'
                              ? { backgroundColor: 'inherit' }
                              : null
                          }
                          type='transparent'
                          text={
                            optionalButtonText && slug === '/e-boutique'
                              ? 'achetez en ligne'
                              : optionalButtonText
                                ? 'en savoir plus'
                                : tags || title
                          }
                        /> }
                        <TextButton>{tags || title}</TextButton>
                      </Item>
                      </>
                    </Itemwrap>
                  )
                },
              )}
          </Swiper>
        </Wrapper>
      </Container>
      {button && locale === 'fr' && (
        <>
          <StyledButton
            className='cta_button'
            to={buttonSlug ? `/${lang}${buttonSlug}` : ''}
            text={button}
          />
        </>
      )}
    </StyledCollectionSlider>
  )
}
const StyledCollectionSlider = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: ${mobileVw(16)};
  .swiper-container {
    margin-left: 0;
    width: 100%;
    padding-left: ${mobileVw(16)};
  }
  .swiper-slide {
    height: auto;
    max-width: ${mobileVw(240)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    padding-bottom: ${desktopVw(16)};
    .swiper-container {
      padding: 0 ${desktopVw(128)} 0 ${desktopVw(98)};
      overflow: visible;
    }
    .swiper-slide {
      padding-left: ${desktopVw(30)};
      cursor: pointer;
    }
  }
`
const StyledSpan = styled.div`
  a {
    text-decoration: underline;
  }
  padding: ${mobileVw(140)};
  padding-left: ${mobileVw(15)};
  padding-right: ${mobileVw(15)};

  span {
    font-size: ${mobileVw(16)};
    text-align: justify;
  }
  word-break:break-word;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(50)} 0 ${desktopVw(50)} ${desktopVw(130)};
    max-width: ${desktopVw(1225)};
    span {
      font-size: ${desktopVw(20)};
    }
  }
`

const Itemwrap = styled.div`
  padding: 0;
  @media (min-width: ${desktopBreakpoint}) {
  }
`
const Item = styled(LinkType)`
  padding: 0;
  @media (min-width: ${desktopBreakpoint}) {
  }
`
const Container = styled.div`
  margin-top: ${mobileVw(15)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVw(24)};
  }
`
const Title = styled.span`
  color: ${colors.white};
  font-family: ${fonts.splandor};
  font-size: ${({ menuCarousel }) => menuCarousel ? desktopVw(20) : desktopVw(34)};
  text-transform: lowercase;
  display: block;
  padding-bottom: ${desktopVw(5)};
`
const Subtitle = styled(Title)`
  font-size: ${({ menuCarousel }) => menuCarousel ? desktopVw(14) : desktopVw(18)};
  letter-spacing: ${desktopVw(1.6)};
  line-height: ${desktopVw(16)};
  min-height: ${desktopVw(35)};
`
const StyledTitle = styled.h2`
  font-family: ${fonts.splandor};
  color: ${colors.mailleGold};
  font-size: ${mobileVw(30)};
  margin-bottom: ${mobileVw(25)};
  padding-left: ${mobileVw(16)};
  color: #000;
  position: relative;
  z-index: 3;
  span {
    &:first-child {
      color: ${colors.mailleGold};
      padding-right: ${mobileVw(5)};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(34)};
    margin-bottom: ${desktopVw(15)};
    padding-left: ${desktopVw(128)};
    display: inline-block;
    span {
      display: inline-block;
      &:first-child {
        padding-right: ${desktopVw(8)};
      }
    }
  }
`
const StyledImage = styled(Image)`
  height: ${mobileVw(200)};
  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(300)};
  }
`
const Wrapper = styled.div`
  position: relative;
  .swiper-pagination-progressbar-fill {
    background: ${colors.mailleGold};
    position: absolute;
  }
  .swiper-container {
    height: 100%;
    overflow: visible;
    .swiper-button-prev {
      transform: rotate(180deg);
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      background-color: ${colors.lightGrey};
      &:after {
        fill: white;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy43OTQ5IDcuNTI5OTZIMFY2LjUyOTk2SDEzLjc5MjhMNy45NzA0MyAwLjcwNzU0OUw4LjY3NzU0IDAuMDAwNDQyNTA1TDE1LjcwODEgNy4wMzFMOC42Nzc1NCAxNC4wNjE2TDcuOTcwNDMgMTMuMzU0NEwxMy43OTQ5IDcuNTI5OTZaIiBmaWxsPSJibGFjayIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwIj4KPHJlY3Qgd2lkdGg9IjE1LjcwNyIgaGVpZ2h0PSIxNC4wNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
      width: ${mobileVw(35)};
      height: ${mobileVw(60)};
      z-index: 999;
      background-color: ${colors.mailleGold};
      &:after {
        content: '';
        height: 15px;
        width: 15px;
      }
      &:focus {
        outline: none;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xMy43OTQ5IDcuNTI5OTZIMFY2LjUyOTk2SDEzLjc5MjhMNy45NzA0MyAwLjcwNzU0OUw4LjY3NzU0IDAuMDAwNDQyNTA1TDE1LjcwODEgNy4wMzFMOC42Nzc1NCAxNC4wNjE2TDcuOTcwNDMgMTMuMzU0NEwxMy43OTQ5IDcuNTI5OTZaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwIj4KPHJlY3Qgd2lkdGg9IjE1LjcwNyIgaGVpZ2h0PSIxNC4wNiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K');
      }
    }
  }
  .swiper-pagination-progressbar,
  .swiper-pagination {
    display: inline-block;
    position: absolute;
    top: ${mobileVw(-15)};
    left: unset;
    background: ${colors.darkGrey};
    width: ${mobileVw(288)};
    height: ${mobileVw(2)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    .swiper-pagination-progressbar,
    .swiper-pagination {
      display: ${(props) => (props.showProgressBar ? 'inline-block' : 'none')};
      top: ${desktopVw(-35)};
      right: ${desktopVw(128)};
      background: ${colors.darkGrey};
      width: ${desktopVw(253)};
      height: ${desktopVw(2)};
    }
    .swiper-container {
      .swiper-button-prev,
      .swiper-button-next {
        display: ${(props) => (props.showProgressBar ? 'flex' : 'none')};
        width: ${desktopVw(64)};
        height: ${desktopVw(64)};
      }
    }
  }
`
const StyledButton = styled(Button)`
  width: auto;
  margin: 0 ${mobileVw(16)} 0;
  display: block;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
    margin: ${desktopVw(30)} auto 0;
  }
`
const RecipeButton = styled(Button)`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: ${desktopVw(15)} 0 0 0;
  }
`
const TextButton = styled.button`
  font-size: ${mobileVw(18)};
  line-height: ${mobileVw(20)};
  background-color: ${colors.mailleGold};
  color: ${colors.white};
  font-family: ${fonts.tivoli};
  margin: ${mobileVw(8)} 0 0 0;
  width: 100%;
  padding: ${mobileVw(4)};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(1.2)};
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`
