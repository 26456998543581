import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { LinkType } from './LinkType'
import { useLocale } from '../../hooks'
import {renderRichText} from 'gatsby-source-contentful/rich-text'
// const options = (isPreparations, locale) => ({
//   renderNode: {
//     [BLOCKS.HEADING_3]: (props) => {
//       return <h3 id={props.content[0].id}>{props.content[0].value}</h3>
//     },
//     // This needed to be done in order to avoid changing all recipes in Contentful
//     // as in preparations.tsx there's an issue with inccorectly nested headings(h2 followed by h5)
//     [BLOCKS.HEADING_5]: (props) => {
//       return isPreparations ?
//         <h3 id={props.content[0].id}>{props.content[0].value}</h3>
//         : <h5 id={props.content[0].id}>{props.content[0].value}</h5>
//     },
//     [INLINES.ENTRY_HYPERLINK]: ({
//       data: {
//         target: { fields },
//       },
//     }) => {
//       return (
//         <LinkType
//           alt={`Ce lien va ouvrir une nouvelle fenêtre: ${fields && fields.title.fr
//             }`}
//           to={`/${locale}${fields && fields.slug.fr}`}
//         >
//           {fields && fields.title.fr}
//         </LinkType>
//       )
//     },
//     [INLINES.HYPERLINK]:( node, children ) =>{
//       const { uri } = node.data
//       return(
//         <a 
//         rel="noopener noreferrer" aria-label="Le lien s'ouvrira dans une nouvelle fenêtre"
//         title="Le lien s'ouvrira dans une nouvelle fenêtre"
//           href = {uri} target= '_blanc'>
//           {children}
//         </a>
//       )
//     },
//     [BLOCKS.EMBEDDED_ENTRY]: ({
//       data: {
//         target: {
//           fields: { title, thumbnailImage, slug },
//         },
//       },
//     }) => {
//       return (
//         <LinkType className='entry-card' to={`/${locale}${slug && slug.fr}`}>
//           <h3 style={{ marginBottom: "20px" }}>{title.fr}</h3>
//           <img alt={title.fr} src={thumbnailImage.fr.fields.file.fr.url} />
//         </LinkType>
//       )
//     },
//     [BLOCKS.EMBEDDED_ASSET]: ({
//       //The destructuring has been commented to avoid the case 
//       //when the data comes undefined from the
//       //backend , it will be unhandled and cause website to break
//       data: { target
//         // target: {
//         //   fields: {
//         //     file: {
//         //       fr: { url, fileName },
//         //     },
//         //   },
//         // },
//       },
//     }) => {
//       return <img alt={target.fields ? target.fields.title.fr : ''} src={target.fields ? target.fields.file.fr.url : ''} />
//     },
//   },
//   renderMark: {
//     [MARKS.BOLD]: (text) => {
//       return <span className='bold-rich-text'>{text}</span>
//     },
//     [MARKS.ITALIC]: (text) => {
//       return <span className='italic-rich-text'>{text}</span>
//     },
//   },
// })

export const RichText = ({ json, isPreparations }) => {
    
    let locale = useLocale();
    if (locale === 'fr') {
      locale = 'fr'
    } else if (locale === 'en-CA') {
      locale = 'ca/en'
    } else if (locale === 'fr-CA') {
      locale = 'ca/fr'
    }
    const options = (isPreparations, locale) =>( {
        renderNode: {
            [BLOCKS.HEADING_3]: (props) => {
              return <h3 id={props.content[0].id}>{props.content[0].value}</h3>
            },
            // This needed to be done in order to avoid changing all recipes in Contentful
            // as in preparations.tsx there's an issue with inccorectly nested headings(h2 followed by h5)
            [BLOCKS.HEADING_5]: (props) => {
              return isPreparations ?
                <h3 id={props.content[0].id}>{props.content[0].value}</h3>
                : <h5 id={props.content[0].id}>{props.content[0].value}</h5>
            },
            [INLINES.ENTRY_HYPERLINK]: ({data: {target}}, children) => {
                
              return (
                target && target.slug ? <LinkType
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${target.slug}`}
                  to={`/${locale}${target.slug}`}
                >
                  {children}
                </LinkType> : <span> {children} </span>
              )
            },
            [INLINES.HYPERLINK]:( node, children ) =>{
              const { uri } = node.data
              return(
                <a 
                rel="noopener noreferrer" aria-label="Le lien s'ouvrira dans une nouvelle fenêtre"
                title="Le lien s'ouvrira dans une nouvelle fenêtre"
                  href = {uri} target= '_blanc'>
                  {children}
                </a>
              )
            },
            [BLOCKS.EMBEDDED_ENTRY]: ({
              data: {
                target: {
                     title, thumbnailImage, slug
                },
              },
            }) => {
              return (
                <LinkType className='entry-card' to={`/${locale}${slug && slug}`}>
                  <h3 style={{ marginBottom: "20px" }}>{title}</h3>
                  <img alt={title} src={thumbnailImage.file.url} />
                </LinkType>
              )
            },
            [BLOCKS.EMBEDDED_ASSET]: ({
              //The destructuring has been commented to avoid the case 
              //when the data comes undefined from the
              //backend , it will be unhandled and cause website to break
              data: { target
                // target: {
                //   fields: {
                //     file: {
                //       fr: { url, fileName },
                //     },
                //   },
                // },
              },
            }) => {
              return <img alt={target ? target.title : ''} src={target ? target.file.url : ''} className='entry-card'/>
            },
          },
          renderMark: {
            [MARKS.BOLD]: (text) => {
              return <span className='bold-rich-text'>{text}</span>
            },
            [MARKS.ITALIC]: (text) => {
              return <span className='italic-rich-text'>{text}</span>
            },
          },
        }
    )
  return <div> {renderRichText(json, options(isPreparations, locale))}</div>
}

export default RichText
