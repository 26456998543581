import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Book = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      viewBox='0 0 15.514 21.642'
      {...props}
    >
      <defs>
        <clipPath id='a'>
          <path
            fill='#ba9b37'
            stroke='#ae841f'
            strokeMiterlimit='10'
            strokeWidth='0.5'
            d='M2.621 1.357a.612.612 0 01.014-.163c.011.012.189.315.107.389a.035.035 0 01-.029.011c-.053-.007-.1-.157-.092-.236m3.905.758c-.083-.1.1-.35.107-.359a.652.652 0 01.013.167c0 .081-.039.218-.092.211a.049.049 0 01-.029-.019M4.638.016h-.011a2.387 2.387 0 00-1.135.14 1.545 1.545 0 00-.86.808A2.16 2.16 0 001.403.232 2.267 2.267 0 00.324.287a.758.758 0 00-.322.161 2.209 2.209 0 012.19.327 2.478 2.478 0 01.385.365 1.054 1.054 0 00-.029.187s0 .26.144.324a.107.107 0 00.1-.013c.069-.059.166-.271-.107-.609A1.534 1.534 0 013.85.285a2.8 2.8 0 011.548.214 2.282 2.282 0 011.176 1.076h0c-.273.261-.176.5-.107.579a.133.133 0 00.1.042c.144-.024.143-.284.143-.284a1.224 1.224 0 00-.028-.195 1.645 1.645 0 01.385-.256 2.143 2.143 0 012.19.289 1.117 1.117 0 00-.322-.252 2.66 2.66 0 00-1.078-.358 1.568 1.568 0 00-1.228.386A2.314 2.314 0 005.768.477 2.9 2.9 0 004.633.018h0z'
            data-name='Path 313'
          ></path>
        </clipPath>
      </defs>
      <g data-name='Group 641' transform='translate(-369.294 -110.558)'>
        <path
          fill='none'
          stroke='#ae841f'
          strokeMiterlimit='10'
          strokeWidth='1'
          d='M382.485 131.585v-17.748l-12.645-2.662v17.748z'
          data-name='Path 308'
        ></path>
        <path
          fill='none'
          stroke='#ae841f'
          strokeMiterlimit='10'
          strokeWidth='1'
          d='M382.489 129.538h0l1.721.089.1-17.747-14.416-.743-.1 17.747'
          data-name='Path 309'
        ></path>
        <g data-name='Group 640' transform='translate(371.443 118.752)'>
          <path
            fill='#ba9b37'
            stroke='#ae841f'
            strokeWidth='0.5'
            d='M2.621 1.357a.612.612 0 01.014-.163c.011.012.189.315.107.389a.035.035 0 01-.029.011c-.053-.007-.1-.157-.092-.236m3.905.758c-.083-.1.1-.35.107-.359a.652.652 0 01.013.167c0 .081-.039.218-.092.211a.049.049 0 01-.029-.019M4.638.016h-.011a2.387 2.387 0 00-1.135.14 1.545 1.545 0 00-.86.808A2.16 2.16 0 001.403.232 2.267 2.267 0 00.324.287a.758.758 0 00-.322.161 2.209 2.209 0 012.19.327 2.478 2.478 0 01.385.365 1.054 1.054 0 00-.029.187s0 .26.144.324a.107.107 0 00.1-.013c.069-.059.166-.271-.107-.609A1.534 1.534 0 013.85.285a2.8 2.8 0 011.548.214 2.282 2.282 0 011.176 1.076h0c-.273.261-.176.5-.107.579a.133.133 0 00.1.042c.144-.024.143-.284.143-.284a1.224 1.224 0 00-.028-.195 1.645 1.645 0 01.385-.256 2.143 2.143 0 012.19.289 1.117 1.117 0 00-.322-.252 2.66 2.66 0 00-1.078-.358 1.568 1.568 0 00-1.228.386A2.314 2.314 0 005.768.477 2.9 2.9 0 004.633.018h0z'
            data-name='Path 311'
          ></path>
          <g clipPath='url(#a)' data-name='Group 639'>
            <path
              fill='#ba9b37'
              stroke='#ae841f'
              strokeMiterlimit='10'
              strokeWidth='0.5'
              d='M-.02-.631L9.287.677v1.922L-.02 1.291z'
              data-name='Path 312'
            ></path>
          </g>
          <path
            fill='#ba9b37'
            stroke='#ae841f'
            strokeMiterlimit='10'
            strokeWidth='0.5'
            d='M2.621 1.357a.612.612 0 01.014-.163c.011.012.189.315.107.389a.035.035 0 01-.029.011c-.053-.007-.1-.157-.092-.236m3.905.758c-.083-.1.1-.35.107-.359a.652.652 0 01.013.167c0 .081-.039.218-.092.211a.049.049 0 01-.029-.019M4.638.016h-.011a2.387 2.387 0 00-1.135.14 1.545 1.545 0 00-.86.808A2.16 2.16 0 001.403.232 2.267 2.267 0 00.324.287a.758.758 0 00-.322.161 2.209 2.209 0 012.19.327 2.478 2.478 0 01.385.365 1.054 1.054 0 00-.029.187s0 .26.144.324a.107.107 0 00.1-.013c.069-.059.166-.271-.107-.609A1.534 1.534 0 013.85.285a2.8 2.8 0 011.548.214 2.282 2.282 0 011.176 1.076h0c-.273.261-.176.5-.107.579a.133.133 0 00.1.042c.144-.024.143-.284.143-.284a1.224 1.224 0 00-.028-.195 1.645 1.645 0 01.385-.256 2.143 2.143 0 012.19.289 1.117 1.117 0 00-.322-.252 2.66 2.66 0 00-1.078-.358 1.568 1.568 0 00-1.228.386A2.314 2.314 0 005.768.477 2.9 2.9 0 004.633.018h0z'
            data-name='Path 314'
          ></path>
        </g>
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(14)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(14)};
    &.sticky-nav-icon {
      width: ${desktopVw(12)};
    }
  }
`
