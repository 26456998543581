/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled, { css } from 'styled-components'
import { saveAs } from 'file-saver'
import { Link } from 'gatsby'
import {
  mobileVw,
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  shortDskVw,
  colors,
} from '../../styles'

export const Button = (props) => {
  const { icon, text, to, type, download, pdfName, pdp = false } = props
  const fetchPdfFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let file = new Blob([blob], {
          type: 'application/octet-stream',
        })
        saveAs(file, `${pdfName}.pdf`)
      })
      .catch((error) => {
        console.error(error)
      })
  }
  if (pdp) {
    return (
      <StyledButton aria-label={text} {...props}>
        {
          text.split('/').map((phrase, index) => (
            <span key={index}>{phrase}</span>
          ))
        }
      </StyledButton>
    )
  }
  if (download) {
    return (
      <StyledButton
        aria-label={text}
        onClick={() => {
          // saveAs(to, text)
          fetchPdfFile(to)
        }}
      >
        {text}
      </StyledButton>
    )
  }
  if (to) {
    return (
      <StyledLink {...props} to={to}>
        {text}
      </StyledLink>
    )
  }
  return (
    <StyledButton aria-label={text} {...props}>
      {text}
    </StyledButton>
  )
}

const isTransparent = (props) => props.type === 'transparent'
const customPadding = (props) => props.customPadding

const ButtonCSS = css`
  cursor: pointer;
  font: inherit;
  width: 100%;
  padding: ${(props) =>
    isTransparent(props) ? `${mobileVw(12)} 0` : `${mobileVw(15)} 0`};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(16)};
  text-transform: uppercase;
  background: ${(props) =>
    isTransparent(props) ? 'transparent' : colors.mailleGold};
  color: ${(props) => (isTransparent(props) ? colors.mailleGold : '#fff')};
  border: 1px solid ${colors.mailleGold};
  font-family: 'Tivoli Bold', sans-serif;
  letter-spacing: ${letterSpacing(100)};
  :disabled {
    color: #000;
    background: #fafafa;
    border: 1px solid #707070;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${(props) =>
    customPadding(props)
      ? desktopVw(customPadding(props))
      : `${shortDskVw([16, 0, 12, 0])}`};

    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
    border: 1px solid ${colors.mailleGold};
  }
`

const StyledButton = styled.button`
  ${ButtonCSS}

  span{
    display:block;
    @media (min-width: ${desktopBreakpoint}){
      padding-bottom: 5px;
    }
  }
`

const StyledLink = styled(Link)`
  display: block;
  text-align: center;
  text-decoration: none;
  ${ButtonCSS}
`
