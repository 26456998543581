import React from 'react'
import styled from 'styled-components'

export const Star = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      width='15px'
      height='15px'
      viewBox='0 0 10.548 10.096'
      {...props}
    >
      <path
        fill={props.color}
        d='M25.208.339l-1.288 2.61-2.881.42a.631.631 0 00-.349 1.077l2.084 2.031-.493 2.869a.631.631 0 00.915.664l2.577-1.354 2.577 1.354a.631.631 0 00.915-.664l-.493-2.869 2.084-2.031a.631.631 0 00-.349-1.077l-2.881-.42-1.287-2.61a.631.631 0 00-1.131 0z'
        transform='translate(-20.5 .013)'
      ></path>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
