import React from 'react'
import styled from 'styled-components'

export const Pinterest = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      width='24.913'
      height='32.381'
      viewBox='0 0 24.913 32.381'
      {...props}
    >
      <path
        fill='#ae841f'
        d='M13.235 0C6.579 0 0 4.438 0 11.62c0 4.567 2.569 7.162 4.126 7.162.642 0 1.012-1.791 1.012-2.3 0-.6-1.538-1.888-1.538-4.4a8.768 8.768 0 019.109-8.914c4.418 0 7.688 2.511 7.688 7.124 0 3.445-1.382 9.907-5.858 9.907a2.881 2.881 0 01-3-2.842c0-2.452 1.713-4.827 1.713-7.357 0-4.295-6.092-3.516-6.092 1.674a7.622 7.622 0 00.623 3.289c-.9 3.854-2.725 9.6-2.725 13.566 0 1.226.175 2.433.292 3.659.221.247.11.221.448.1 3.27-4.477 3.153-5.352 4.632-11.211a5.262 5.262 0 004.5 2.336c6.89 0 9.985-6.715 9.985-12.768C24.913 4.2 19.346 0 13.235 0z'
        data-name='Path 33'
      ></path>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
