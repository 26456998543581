import * as React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const CheckList = props => {
  return (
    <Svg width={13} height={17.235} viewBox='0 0 13 17.235' {...props}>
      <g
        data-name='Group 1197'
        fill='none'
        stroke='#ae841f'
        strokeLinejoin='round'
      >
        <path
          data-name='Path 1411'
          d='M9.677 1.891H12.5v14.844H.5V1.891h2.824'
          strokeLinecap='round'
        />
        <path
          data-name='Path 1412'
          d='M9.718 1.891v.7H3.283v-.7A1.411 1.411 0 014.713.5h3.57a1.411 1.411 0 011.435 1.391z'
          strokeLinecap='round'
        />
        <path data-name='Path 1413' d='M1.891 6.859L3.282 8.25l2.087-2.087' />
        <path data-name='Path 1414' d='M1.891 11.104l1.391 1.391 2.087-2.087' />
        <path
          data-name='Line 112'
          strokeLinecap='round'
          d='M7.283 7.559h3.478'
        />
        <path
          data-name='Line 113'
          strokeLinecap='round'
          d='M7.283 11.794h3.478'
        />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: ${mobileVw(12)};
  height: ${mobileVw(16.24)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(24.03)};
    height: ${desktopVw(32.51)};
  }
`
