import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '../index'
import { useLocation } from '@reach/router'
export const useSiteQuery = (node_locale = null) => {
  const locale = useLocale()
  const {
    allContentfulGlobalSite: { edges },
  } = useStaticQuery(
    graphql`
      query Site {
        allContentfulGlobalSite {
          edges {
            node {
              node_locale
              footer {
                titleNewsletter
                descriptionNewsletter {
                  descriptionNewsletter
                }
                placeholderNewsletter
                buttonNewsletter
                socialTitle
                topOfPage
                instagramLink
                facebookLink
                paymentsTitle
                copyright {
                  copyright
                }
                firstColumnFooterTitle
                firstColumnFooterLinks {
                  title
                  externalLink
                  link {
                    ...linkTarget
                  }
                }
                secondColumnFooterTitle
                secondColumnFooterLinks {
                  title
                  externalLink
                  link {
                    ...linkTarget
                  }
                }
                thirdColumnFooterTitle
                thirdColumnFooterLinks {
                  title
                  externalLink
                  link {
                    ...linkTarget
                  }
                }
                fourthColumnFooterTitle
                fourthColumnFooterLinks {
                  title
                  externalLink
                  link {
                    ...linkTarget
                  }
                }

              }
              defaultTestimonialImage {
                fluid(sizes: "(max-width: 1023px) 40vw, 30vw") {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
              }
              logo {
                title
                fluid(sizes: "(max-width: 1023px) 15vw, 25vw") {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              uspBarText {
                raw
              }
              headerLinks {
                title
                optionalIcon
                externalLink
                link {
                  ... on ContentfulPageLaMaisonMaille {
                    slug
                  }
                  ... on ContentfulPageGifting {
                    slug
                  }
                  ... on ContentfulPageRecipeLanding {
                    slug
                  }
                  ... on ContentfulPageStores {
                    slug
                  }
                  ... on ContentfulPageCommunity {
                    slug
                  }

                  ... on ContentfulPageEBoutiqueCollection{
                    slug
                  }
                  ... on ContentfulComponentCollectionSlider {
                    title
                    slides {
                      ... on ContentfulPageEBoutiqueCollection {
                        __typename
                        title
                        slug
                        secondaryImage {
                          fluid(maxWidth: 1800) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                          }
                          title
                        }
                      }
                      ... on ContentfulPageEBoutique {
                        __typename
                        title
                        slug
                        navbarText
                        thumbnailImage {
                          title
                          fluid(maxWidth: 1800) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                          }
                        }
                        productSlider {
                          button
                          title
                          products {
                            shopifyProduct {
                              id
                              handle
                              variants {
                                edges {
                                  node {
                                    id
                                    title
                                    price{
                                        amount
                                    }
                                  }
                                }
                              }
                            }
                            thumbnailImage {
                              fluid(maxWidth: 1800) {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                              }
                              title
                            }
                            title
                            slug
                          }
                        }
                      }
                      ... on ContentfulPageBoutiqueLanding {
                        __typename
                        title
                        slug
                        navbarText
                        thumbnailImage {
                          fluid(maxWidth: 1800) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                          }
                          title
                        }
                      }
                    }
                  }
                }
              }
              footerDecoration {
                image {
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  title
                }
                positionDesktop
                positionMobile
                view
                name
              }
              navigationAsideDecoration {
                fluid {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
              }
              languageSelector {
                title
                optionalImage {
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  title
                }
                externalLink
              }
              instagramBlockText {
                instagramBlockText
              }
              testimonialText {
                testimonialText
              }
              instagramLink
              facebookLink
              colorCodeLabels
              accountDecoration {
                image {
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
              accountPage {
                ... on ContentfulPageLogin {
                  id
                  slug
                  submit
                  title
                  dontHaveAnAccount
                  dontHaveAnAccountLink
                  emailLabel
                  emailPlaceholder
                  forgotPassword
                  passwordLabel
                  passwordPlaceholder
                  login
                  loadingText
                }
                ... on ContentfulRegisterPage {
                  id
                  slug
                  title
                  confirmPasswordLabel
                  confirmPasswordPlaceholder
                  emailLabel
                  emailPlaceholder
                  telephoneLabel
                  telephonePlaceholder
                  lastNameLabel
                  lastNamePlaceholder
                  logIn
                  logInLink
                  nameLabel
                  namePlaceholder
                  passwordLabel
                  passwordPlaceholder
                  passwordRequirements
                  submit
                }
                ... on ContentfulPageForgotPassword {
                  id
                  name
                  slug
                  title
                  description
                  emailLabel
                  emailPlaceholder
                  submit
                  cancel
                  successMessage
                  loading
                }
              }
            }
          }
        }
      }
    `,
  )
  const localeData = locale
    ? edges.filter(({ node }) => node.node_locale === locale)
    : edges.filter(({ node }) => node.node_locale === 'fr')
  const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
  return site
}
