import React from 'react'
import styled from 'styled-components'

export const Heart = (props) => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 18'
      {...props}
    >
      <path
        fill={props.color}
        d='M-1019.9-2212.08a5.017,5.017,0,0,0-4.181,2.159,5.013,5.013,0,0,0-4.177-2.166,4.641,4.641,0,0,0-4.824,4.759c0,3.073,3.128,6.129,7.089,10,.567.554,1.15,1.124,1.744,1.714l.168.166.167-.166c.593-.588,1.176-1.158,1.742-1.712,3.961-3.871,7.09-6.928,7.09-10A4.636,4.636,0,0,0-1019.9-2212.08Zm3.614,4.751c0,2.57-2.948,5.448-6.679,9.091q-.55.536-1.117,1.093c-.369-.363-.736-.72-1.1-1.073-3.593-3.5-6.7-6.523-6.7-9.11a3.463,3.463,0,0,1,3.619-3.554c1.018,0,2.885.4,3.956,3.1l.219.553.222-.552c1.12-2.8,3.143-3.1,3.961-3.1A3.434,3.434,0,0,1-1016.289-2207.329Z'
        data-name='Path 50'
        transform='translate(1033.084 2212.087)'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
