import * as React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const ReturnArrow = props => {
  return (
    <Svg width={17} height={13.522} viewBox='0 0 17 13.522' {...props}>
      <g data-name='Group 1199'>
        <path
          data-name='Path 1417'
          d='M.5 6.682l6.261 6.34V8.769s5.565-2.008 9.739 4.174c0 0-1.391-8.964-9.739-8.348V.5z'
          fill='none'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: ${mobileVw(16)};
  height: ${mobileVw(12.52)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32.04)};
    height: ${desktopVw(25.07)};
  }
`
