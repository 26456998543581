/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Image } from './Image'

import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  letterSpacing,
  colors,
  fonts,
} from '../../styles'
import { Rating, Button, LinkType } from '.'
import { Heart, HeartFilled } from './icons'
import { LocalStorage } from '../../utils/LocalStorage'
import {
  useAddItemToCart,
  useLayout,
  useWishlistProducts,
  useProductInfoAnalytics,
  useAddToCartTracking,
  useLang,
  useLocale,
  useGtagAddToCart,
} from '../../hooks'
import { Price } from './Price'

export const Productcard = ({
  product,
  buttonText,
  recipePage,
  shopenful,
  landingCard,
}) => {
  const lang = useLang()
  const locale = useLocale()

  const addItemToCart = useAddItemToCart()
  const [adding, setAdding] = useState(false)

  const [onWishlist, setOnWishlist] = useState(false)

  const { wishlistProducts } = useLayout()
  const setWishlistProducts = useWishlistProducts()

  const variants =
    shopenful &&
    product.shopifyProduct &&
    shopenful.filter(
      (productItem) => productItem.handle === product.shopifyProduct.handle,
    )[0]

  const [sku, setSku] = useState(null)
  const [variantId, setVariantId] = useState(null)
  const [variant, setVariant] = useState(null)

  useEffect(() => {
    const variants =
      shopenful &&
      product.shopifyProduct &&
      shopenful.find(
        (productItem) => productItem.handle === product.shopifyProduct.handle,
      )
    console.log('slug', variants)

    const vari = (variants && variants.variants[0]) || null
    setVariant(vari)
    let prodId = variants && variants?.id?.split('/')
    setSku(() => prodId && prodId[prodId.length - 1])
    setVariantId(vari && vari.id.split('/').at(-1))
  }, [])
  //  console.log('variantId', variantId);

  useEffect(() => {
    const currentWishlistProducts =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_products')
    if (currentWishlistProducts) {
      const localWishlist = JSON.parse(currentWishlistProducts)
      if (product.slug in localWishlist) {
        setOnWishlist(true)
      } else {
        setOnWishlist(false)
      }
    }
  }, [wishlistProducts])
  const handleAddToWishlist = () => {
    const currentWishlistProducts =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_products')
    if (currentWishlistProducts) {
      const localWishlist = JSON.parse(currentWishlistProducts)
      if (product.slug in localWishlist) {
      } else {
        localWishlist[product.slug] = { ...product, buttonText }
        localStorage.setItem('wishlist_products', JSON.stringify(localWishlist))
        setWishlistProducts(localWishlist)
      }
    } else {
      const localWishlist = {}
      localWishlist[product.slug] = { ...product, buttonText }
      localStorage.setItem('wishlist_products', JSON.stringify(localWishlist))
      setWishlistProducts(localWishlist)
    }
  }

  const handleRemoveFromWishlist = () => {
    const currentWishlistProducts =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_products')
    const localWishlist = JSON.parse(currentWishlistProducts)
    if (product.slug in localWishlist) {
      delete localWishlist[product.slug]
      localStorage.setItem('wishlist_products', JSON.stringify(localWishlist))
      setWishlistProducts(localWishlist)
    }
  }

  const handleAddToCart = () => {
    setAdding(true)
    addItemToCart(
      variants.variants[0].id.replace('Shopify__ProductVariant__', ''),
      1,
    ).then(async () => {
      setAdding(false)
      LocalStorage.addCartExpiration()
      useAddToCartTracking(product.title, product.shopifyProduct)
      // if (variants.variants[0].title !== "Default Title" && variants.variants[0].title.includes(' / ')) {
      //     await addItemToCart(process.env.SPOON_ID, 1, [{
      //         key: "Free",
      //         value: "true"
      //     }])
      // }
    })
  }

  // const getAdminIdFromStorefrontId = (storefrontId) =>
  //   typeof window !== 'undefined' &&
  //   window.atob(storefrontId).match(/\d/g).join('')

  // const decodeId = id =>
  //     typeof window === "undefined"
  //         ? Buffer.from(id, "base64").toString()
  //         : atob(id);
  // const productId = variants
  //   ? decodeId(variants.id.replace('Shopify__Product__', '')).split('/').pop()
  //   : 0

  const padding1 =
    product.thumbnailImage?.description === 'p' ? 'padding1' : null
  const padding2 =
    product.thumbnailImage?.description === 'pp' ? 'padding2' : null

  // const productId = variants
  //     ? decodeId(variants.id.replace('Shopify__Product__', '')).split('/').pop()
  //     : 0
  // const productId = variants
  //   ? getAdminIdFromStorefrontId(variants.id.replace('Shopify__Product__', ''))
  //   : 0

  const discountExist =
    variants &&
    variants.variants[0].compareAtPrice &&
    variants.variants[0].compareAtPrice.amount > 1

  const textAddingToCart = () => {
    if (product.shopifyProduct) {
      if (adding) return 'ajouté'
      return 'Ajouter au panier'
    }
    return 'En savoir plus'
  }

  return (
    <StyledProductCard onClick={() => useProductInfoAnalytics(product)}>
      <ProductCardWrapper
        recipePage={recipePage}
        className='product-wrapper'
        landingCard={landingCard}
      >
        <ImageWrapper>
          {product.promotion ? (
            <GoldLabel className='label'>{product.thumbnailLabel}</GoldLabel>
          ) : (
            <>
              {product.thumbnailLabel && !discountExist && (
                <GoldLabel className='label'>
                  {product.thumbnailLabel}
                </GoldLabel>
              )}
              {product.thumbnailLabel && discountExist && (
                <GoldLabel className='label'>
                  {product.thumbnailLabel}
                </GoldLabel>
              )}
              {discountExist && !product.thumbnailLabel && (
                <GoldLabel className='label'>EN PROMOTION</GoldLabel>
              )}
            </>
          )}

          <LinkType
            to={product.slug ? `/${lang}${product.slug}` : ''}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${product.title}`}
          >
            <ProductCardImageContainer className='image-link'>
              {product.thumbnailImage && product.thumbnailImage.fluid && (
                <ProductCardImage
                  className='imageContainer'
                  fluid={product.thumbnailImage.fluid}
                  resize={product.resizeImage}
                  position={product.imageAlignment}
                  alt={product.thumbnailImage.title}
                  addPadding={padding1 || padding2 || null}
                />
              )}
            </ProductCardImageContainer>
          </LinkType>
          {locale === 'fr' && (
            <AddToWishlist
              onClick={(e) => {
                e.preventDefault()
                if (!onWishlist) {
                  handleAddToWishlist()
                  setOnWishlist(true)
                } else {
                  handleRemoveFromWishlist()
                  setOnWishlist(false)
                }
              }}
            >
              {onWishlist ? <StyledHeartFilled /> : <StyledHeart />}
            </AddToWishlist>
          )}
        </ImageWrapper>

        <TextWrapper className='text-wrapper'>
          <ProductCardInfo
            landingCard={landingCard}
            noReviews={!(product.shopifyProduct && product.shopifyProduct.id)}
          >
            <LinkType
              to={product.slug ? `/${lang}${product.slug}` : ''}
              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${product.title}`}
            >
              <p
                style={{ height: 'fit-content', minHeight: '10.75vw' }}
                className='mobileOnly'
              >
                {product && product.title && product.title.length > 45
                  ? `${product.title.substring(0, 45 - 3)}...`
                  : product.title}
              </p>
            </LinkType>
            <LinkType
              to={product.slug ? `/${lang}${product.slug}` : ''}
              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${product.title}`}
            >
              <p
                style={{
                  height: 'fit-content',
                  minHeight: '2.392386530014641vw',
                }}
                className='desktopOnly'
              >
                {product && product.title}
              </p>
            </LinkType>
            {locale === 'fr' && (
              <Ratings>
                {' '}
                <div
                  className='skeepers_product__stars'
                  data-product-id={variantId}
                ></div>{' '}
              </Ratings>
            )}
            {locale === 'fr' && (
              <ProductCardCommerce>
                {/* <Rating ratingId={productId} /> */}
                <span className='price'>
                  {product.shopifyProduct && variants && variants.variants ? (
                    <StyledPrice>
                      <Price variants={variants.variants} />
                    </StyledPrice>
                  ) : (
                    <StyledPrice style={{ visibility: 'hidden' }}>
                      0€
                    </StyledPrice>
                  )}
                </span>
              </ProductCardCommerce>
            )}
          </ProductCardInfo>
          {locale === 'fr' && !recipePage && (
            <>
              {variants && variants.variants[0].availableForSale == false ? (
                <OutOfStockButton
                  style={{ pointerEvents: 'none' }}
                  onClick={() => {}}
                  text={buttonText || textAddingToCart()}
                  type='transparent'
                />
              ) : (
                <Button
                  onClick={
                    product.shopifyProduct
                      ? () => handleAddToCart()
                      : () => navigate(`/fr${product.slug}`)
                  }
                  text={buttonText || textAddingToCart()}
                  type='transparent'
                />
              )}
            </>
          )}
        </TextWrapper>
      </ProductCardWrapper>
    </StyledProductCard>
  )
}

const OutOfStockButton = styled(Button)`
  pointer-events: none;
  color: ${colors.darkGrey};
  border-color: ${colors.darkGrey};
`

const StyledProductCard = styled.div`
  width: 100%;
`

const AddToWishlist = styled.button`
  cursor: pointer;
  position: absolute;
  top: ${mobileVw(15)};
  right: ${mobileVw(15)};
  padding: 0;

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVw(17)};
    right: ${desktopVw(16)};
  }
`

const ImageWrapper = styled.div`
  position: relative;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Ratings = styled.div`
  height: 15px;
  margin-top: 10px;
`
const GoldLabel = styled.div`
  background-color: ${(props) =>
    props.color ? `${props.color}` : `${colors.mailleGold}`};
  color: ${colors.white};
  position: absolute;
  top: ${mobileVw(14)};
  left: ${mobileVw(12)};
  font-family: ${fonts.tivoli};
  text-transform: uppercase;
  padding: ${mobileVw(5)} ${mobileVw(8)} ${mobileVw(3)};
  font-size: ${mobileVw(10)};
  letter-spacing: ${mobileVw(1.2)};
  border-radius: ${mobileVw(8)};
  z-index: 2;

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVw(17)};
    left: ${desktopVw(16)};
    padding: ${desktopVw(5)} ${desktopVw(10)} ${desktopVw(3)};
    font-size: ${desktopVw(16)};
    letter-spacing: ${desktopVw(1.6)};
    height: auto;
  }
`

const StyledPrice = styled.div`
  margin: ${desktopVw(8)} 0;
`

const ProductCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.recipePage ? `row` : `column`)};
  justify-content: space-between;
  @media (min-width: ${desktopBreakpoint}) {
    /* width: ${desktopVw(255)}; */
    margin: ${(props) => (props.landingCard ? `1rem` : ``)};
  }
`

const ProductCardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightGrey};
  height: ${mobileVw(200)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    height: ${desktopVw(300)};
    margin: 0 0 ${desktopVw(19)} 0;
  }
`

const StyledHeart = styled(Heart)`
  fill: ${colors.mailleGold};
  width: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(25)};
    height: auto;
  }
`

const StyledHeartFilled = styled(HeartFilled)`
  fill: ${colors.mailleGold};
  width: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(25)};
    height: auto;
  }
`

const ProductCardInfo = styled.div`
  display: ${(props) => (!props.landingCard ? '' : 'none')};
  font-family: 'Tivoli Bold';
  letter-spacing: ${letterSpacing(100)};
  text-transform: uppercase;

  p {
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    overflow: hidden;
    height: ${mobileVw(60)};
    margin-block-end: 0;
  }

  svg {
    fill: ${(props) =>
      props.noReviews ? `${colors.mediumGrey}` : `${colors.mailleGold}`};
  }

  @media (min-width: ${desktopBreakpoint}) {
    p {
      margin: 0;
      font-size: ${desktopVw(18)};
      line-height: ${desktopVw(16)};
      height: ${desktopVw(60)};
    }
  }
`

const ProductCardImage = styled(Image)`
  mix-blend-mode: multiply;
  padding: ${(props) =>
    props.addPadding === 'padding1'
      ? `${mobileVw(0)}`
      : props.addPadding === 'padding2'
      ? `${mobileVw(15)}`
      : `${mobileVw(10)}`};
  img {
    object-fit: contain !important;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${(props) =>
      props.addPadding === 'padding1'
        ? `${desktopVw(15)}`
        : props.addPadding === 'padding2'
        ? `${desktopVw(20)}`
        : `0`};
    img {
      object-position: ${(props) =>
        props.position
          ? `${props.position} !important`
          : `center center !important`};
    }
  }
`

const ProductCardCommerce = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  span {
    font-family: 'Tivoli Bold';
    letter-spacing: ${letterSpacing(100)};
    text-transform: uppercase;
    color: ${colors.mailleGold};
    font-size: ${mobileVw(18)};
    line-height: 1;
    margin: 0 0 ${mobileVw(5)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    span {
      font-size: ${desktopVw(22)};
      line-height: ${desktopVw(20)};
      margin: 0;
    }
    label {
      /* margin: ${desktopVw(8)} 0 ${desktopVw(9)}; */
    }
    button {
      display: block;
    }
  }
`
