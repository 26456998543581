/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Image } from './Image'
import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  letterSpacing,
  colors,
  fonts,
} from '../../styles'
import { Rating, LinkType, Button } from '.'
import {
  useAddItemToCart,
  useWishlistProducts,
  useToggleWishlist,
} from '../../hooks'

export const ProductcardWishlist = ({ product }) => {
  const goldLabel1 = 'nouveautés'
  const goldLabel2 = 'dernières tendances'
  const goldLabel3 = 'meilleures ventes'
  const addItemToCart = useAddItemToCart()
  const [adding, setAdding] = useState(false)

  const [onWishlist, setOnWishlist] = useState(false)

  const setWishlistProducts = useWishlistProducts()
  const toggleWishlist = useToggleWishlist()

  useEffect(() => {
    const currentWishlistProducts =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_products')
    if (currentWishlistProducts) {
      const localWishlist = JSON.parse(currentWishlistProducts)
      if (product.slug in localWishlist) {
        setOnWishlist(true)
      }
    }
  }, [])

  const handleRemoveFromWishlist = () => {
    const currentWishlistProducts =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_products')
    const localWishlist = JSON.parse(currentWishlistProducts)
    if (product.slug in localWishlist) {
      delete localWishlist[product.slug]
      localStorage.setItem('wishlist_products', JSON.stringify(localWishlist))
      setWishlistProducts(localWishlist)
    }
  }

  const handleAddToCart = () => {
    setAdding(true)
    addItemToCart(product.shopifyProduct.variants.edges[0].node.id, 1).then(
      () => {
        setAdding(false)
      },
    )
  }

  return (
    onWishlist && (
      <StyledProductCard>
        <ProductCardWrapper>
          <ProductCardLeft>
            <ProductCardImageContainer resize={product.resize}>
              {product.thumbnailImage && (
                <LinkType
                  to={product.slug ? `/fr${product.slug}` : ''}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${product.title}`}
                  onClick={() => toggleWishlist()}
                >
                  <ProductCardImage
                    className='imageContainer'
                    fluid={product.thumbnailImage.fluid}
                    alt={product.thumbnailImage.title}
                    resize={product.resizeImage}
                  />
                </LinkType>
              )}
            </ProductCardImageContainer>
          </ProductCardLeft>

          <ProductCardInfo
            noReviews={!(product.shopifyProduct && product.shopifyProduct.id)}
          >
            <p>{product && product.title}</p>
            <ProductCardCommerce>
              <>
                {/* <Rating score={4} /> */}

                <span className='price'>
                  {product.shopifyProduct && product.shopifyProduct.variants
                    ? `${Number(
                        product.shopifyProduct.variants.edges[0].node.price,
                      ).toFixed(2)} 
                €`
                    : null}
                </span>
                <Button
                  onClick={() => {
                    handleAddToCart()
                  }}
                  text={
                    product.buttonText ||
                    // eslint-disable-next-line no-nested-ternary
                    (product.shopifyProduct
                      ? adding
                        ? 'ajouté'
                        : 'Ajouter au panier'
                      : 'En savoir plus')
                  }
                  type='transparent'
                  className='wishlist-product'
                  // disabled={adding}
                  // onClick={() => handleAddToCart(product.shopifyId.shopifyId)}
                  // text={adding ? 'adding' : 'Ajouter au panier'}
                />
              </>
              {/* ) : ( */}
              {/* <Button
            type='transparent'
            // to={`/fr${product.slug}`}
            text='En savoir plus'
          />
          )} */}
            </ProductCardCommerce>
          </ProductCardInfo>
        </ProductCardWrapper>

        <Remove
          onClick={() => {
            handleRemoveFromWishlist()
            setOnWishlist(false)
          }}
        >
          Retirer
        </Remove>
      </StyledProductCard>
    )
  )
}

const StyledProductCard = styled.div`
  border-top: ${mobileVw(1)} solid ${colors.mediumGrey};
  padding-top: ${mobileVw(16)};
  :first-child {
    border-top: none;
    padding-top: 0;
  }
  @media (min-width: ${desktopBreakpoint}) {
    border-top: ${desktopVw(1)} solid ${colors.mediumGrey};
    padding-top: ${desktopVw(16)};
    :first-child {
      border-top: none;
      padding-top: 0;
    }
  }
`

const ProductCardWrapper = styled.div`
  position: relative;
  display: flex;
  @media (min-width: ${desktopBreakpoint}) {
    /* width: ${desktopVw(255)}; */
  }
`

const ProductCardLeft = styled.div`
  position: relative;
  flex: 0 0 ${mobileVw(136)};
  margin-right: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    flex: 0 0 ${desktopVw(160)};
    margin-right: ${desktopVw(30)};
  }
`

const ProductCardImageContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightGrey};
  height: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
  }
`

const ProductCardInfo = styled.div`
  font-family: 'Tivoli Bold';
  letter-spacing: ${letterSpacing(100)};
  text-transform: uppercase;

  p {
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    overflow: hidden;
    text-overflow: ellipsis;
    margin-block-end: 0;
    margin-top: 0;
  }

  svg {
    width: ${mobileVw(11)};
    fill: ${(props) =>
      props.noReviews ? `${colors.mediumGrey}` : `${colors.mailleGold}`};
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${desktopBreakpoint}) {
    p {
      margin: 0;
      font-size: ${desktopVw(22)};
      line-height: ${desktopVw(24)};
    }
    svg {
      width: ${desktopVw(13)};
    }

    .price {
      font-size: ${desktopVw(22)};
      padding-top: ${desktopVw(14.4)};
      color: ${colors.mailleGold};
    }
  }
`

const RatingWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVw(5)};
  }
`

const ProductCardImage = styled(Image)`
  mix-blend-mode: multiply;
  height: ${(props) => (props.resize ? `${props.resize}` : '100%')};
  img {
    object-fit: contain !important;
  }
  width: ${mobileVw(104)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(126)};
  }
`

const ProductCardCommerce = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-family: 'Tivoli Bold';
    letter-spacing: ${letterSpacing(100)};
    text-transform: uppercase;
    color: ${colors.mailleGold};
    font-size: ${mobileVw(20)};
    line-height: 1;
    margin: 0 0 ${mobileVw(11)};
  }

  button {
    display: block;
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    &.wishlist-product {
      align-self: flex-end;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    span {
      font-size: ${desktopVw(22)};
      line-height: ${desktopVw(20)};
      margin: ${desktopVw(8)} 0 ${desktopVw(9)};
    }
    button {
      font-size: ${desktopVw(16)};
      line-height: ${desktopVw(16)};
    }
  }
`

const Remove = styled.button`
  display: block;
  margin: ${mobileVw(14)} 0 0 0;
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  border-bottom: ${mobileVw(1)} solid ${colors.black};
  padding: 0;
  cursor: pointer;
  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(13)} 0 0 0;
    font-size: ${desktopVw(16)};
    border-bottom: ${desktopVw(1)} solid ${colors.black};
  }
`
