import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Jar = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      viewBox='0 0 15 21.432'
      {...props}
    >
      <path
        fill='none'
        stroke='#ae841f'
        strokeMiterlimit='10'
        strokeWidth='1'
        d='M.5 8.478c0 1.632 1.549 10.986 1.549 10.986s.27 1.422 5.336 1.464h.23c5.066-.042 5.336-1.464 5.336-1.464S14.5 10.11 14.5 8.478s-1.374-2.574-2.018-3.428a1.506 1.506 0 01.047-1.759.789.789 0 00.187-.612c0-.459-.974-.408-.974-.408h-.106L11.785.73S11.191.5 7.236.5 3.094.755 3.094.755l.155 1.516c-.1 0-.965-.025-.965.408a.787.787 0 00.188.612 1.507 1.507 0 01.047 1.759C1.873 5.904.5 6.846.5 8.478z'
        data-name='Path 307'
      ></path>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(14)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(14)};
    &.sticky-nav-icon {
      width: ${desktopVw(12)};
    }
  }
`
