/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  colors,
} from '../../styles/index'

export const Select = ({
  className,
  label,
  placeholder,
  options = [{ title: 'Option 1', value: 'option-1' }],
}) => {
  return (
    <SelectWrapper className={className}>
      {label && <label>{label}</label>}
      <button type='button'>
        <span>{placeholder || options[0].title}</span>{' '}
        <Icon name='dropdown-arrow' />
      </button>
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  width: 100%;
  margin: ${mobileVw(16)} 0px;
  button {
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Tivoli Bold';
    font-size: ${mobileVw(14)};
    line-height: ${mobileVw(12)};
    text-transform: uppercase;
    border: none;
    background-color: ${colors.lightGrey};
    width: 100%;
    padding: ${mobileVw(22)};
    svg {
      margin-left: ${mobileVw(12)};
      stroke: #000;
      width: ${mobileVw(8)};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    width: auto;
    margin: ${desktopVw(16)} 0px;
    button {
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Tivoli Bold';
      font-size: ${desktopVw(14)};
      line-height: ${desktopVw(12)};
      text-transform: uppercase;
      border: none;
      background-color: ${colors.lightGrey};
      width: 100%;
      padding: ${desktopVw(22)};
      svg {
        margin-left: ${desktopVw(12)};
        stroke: #000;
        width: ${desktopVw(8)};
      }
    }
  }
`
