export const useDownloadAnalytics = (fileName) => {
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.event.push({
      componentInfo: {
        componentID: `${fileName}`,
        componentName: fileName,
      },
      eventInfo: {
        type: ctConstants.trackEvent,
        eventAction: ctConstants.downloads,
        eventLabel: fileName,
        eventValue: 1,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Interest',
    })
    console.log(window.digitalData)
  }
}
