import React from 'react'
import styled from 'styled-components'

export const Menu = props => {
  return (
    <StyledSvg
      width='100%'
      height='100%'
      viewBox='0 0 25 8'
      xmlns='http://www.w3.org/2000/StyledSvg'
      {...props}
    >
      <rect width='15' height='1' />
      <rect width='25' y='8' height='1' />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
