import React from 'react'
import styled from 'styled-components'

export const GooglePay = (props) => {
  return (
    <StyledSvg
      data-name='Group 33'
      width={50.782}
      height={20.166}
      viewBox='0 0 50.782 20.166'
      {...props}
    >
      <path
        id='Path_444'
        data-name='Path 444'
        d='M472.863,1856.682v5.911h-1.875V1848h4.973a4.5,4.5,0,0,1,3.22,1.264,4.22,4.22,0,0,1,0,6.179,4.484,4.484,0,0,1-3.22,1.243h-3.1Zm0-6.889v5.1h3.145a2.54,2.54,0,0,0,1.905-4.273l-.05-.05a2.446,2.446,0,0,0-1.855-.774h-3.145Z'
        transform='translate(-448.847 -1846.83)'
        fill='#fff'
      />
      <path
        id='Path_445'
        data-name='Path 445'
        d='M556.9,1884.783a4.651,4.651,0,0,1,3.282,1.111,3.939,3.939,0,0,1,1.2,3.047v6.156h-1.794v-1.386h-.082a3.582,3.582,0,0,1-3.1,1.712,4.028,4.028,0,0,1-2.762-.978,3.125,3.125,0,0,1-1.111-2.446,2.966,2.966,0,0,1,1.172-2.466,4.954,4.954,0,0,1,3.128-.918,5.538,5.538,0,0,1,2.751.611v-.429a2.135,2.135,0,0,0-.775-1.66,2.656,2.656,0,0,0-1.81-.684,2.871,2.871,0,0,0-2.487,1.325l-1.652-1.04A4.6,4.6,0,0,1,556.9,1884.783Zm-2.426,7.256a1.5,1.5,0,0,0,.622,1.223,2.287,2.287,0,0,0,1.457.489,2.989,2.989,0,0,0,2.109-.876,2.74,2.74,0,0,0,.932-2.058,3.83,3.83,0,0,0-2.446-.7,3.17,3.17,0,0,0-1.906.551,1.643,1.643,0,0,0-.769,1.37Z'
        transform='translate(-520.894 -1879.334)'
        fill='#fff'
      />
      <path
        id='Path_446'
        data-name='Path 446'
        d='M641.1,1887.583l-6.262,14.39H632.9l2.324-5.035-4.117-9.355h2.038l2.976,7.175h.041l2.894-7.175Z'
        transform='translate(-590.317 -1881.808)'
        fill='#fff'
      />
      <path
        id='Path_447'
        data-name='Path 447'
        d='M360.941,1898.708A9.99,9.99,0,0,0,360.8,1897h-7.909v3.23h4.53a3.881,3.881,0,0,1-1.675,2.548v2.1h2.7A8.2,8.2,0,0,0,360.941,1898.708Z'
        transform='translate(-344.502 -1890.13)'
        fill='#fff'
      />
      <path
        id='Path_448'
        data-name='Path 448'
        d='M296.051,1930.564a8.026,8.026,0,0,0,5.558-2.024l-2.7-2.1a5.086,5.086,0,0,1-7.563-2.66h-2.785v2.161A8.386,8.386,0,0,0,296.051,1930.564Z'
        transform='translate(-287.665 -1913.791)'
        fill='#fff'
      />
      <path
        id='Path_449'
        data-name='Path 449'
        d='M284.569,1883.044a5.025,5.025,0,0,1,0-3.21v-2.161h-2.785a8.386,8.386,0,0,0,0,7.531Z'
        transform='translate(-280.892 -1873.052)'
        fill='#fff'
      />
      <path
        id='Path_450'
        data-name='Path 450'
        d='M296.051,1841.318a4.553,4.553,0,0,1,3.217,1.258h0l2.393-2.394a8.059,8.059,0,0,0-5.611-2.184,8.387,8.387,0,0,0-7.493,4.621l2.785,2.161A5.016,5.016,0,0,1,296.051,1841.318Z'
        transform='translate(-287.665 -1837.998)'
        fill='#fff'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
