import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint, colors } from '../../../styles'

export const ArrowRight = (props) => {
  return (
    <StyledSvg
      width={15.707}
      height={14.06}
      viewBox='0 0 15.707 14.06'
      {...props}
    >
      <g data-name='Group 32' fill='none' stroke={colors.mailleGold} strokeMiterlimit={10}>
        <path data-name='Path 45' d='M8.324.354l6.677 6.677-6.677 6.677' />
        <path data-name='Line 2' d='M15 7.03H0' />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
