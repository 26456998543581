// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-templates-article-collection-tsx": () => import("./../../../src/templates/ArticleCollection.tsx" /* webpackChunkName: "component---src-templates-article-collection-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-boutique-landing-tsx": () => import("./../../../src/templates/BoutiqueLanding.tsx" /* webpackChunkName: "component---src-templates-boutique-landing-tsx" */),
  "component---src-templates-community-tsx": () => import("./../../../src/templates/Community.tsx" /* webpackChunkName: "component---src-templates-community-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-e-boutique-collection-tsx": () => import("./../../../src/templates/EBoutiqueCollection.tsx" /* webpackChunkName: "component---src-templates-e-boutique-collection-tsx" */),
  "component---src-templates-e-boutique-tsx": () => import("./../../../src/templates/EBoutique.tsx" /* webpackChunkName: "component---src-templates-e-boutique-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/Faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-gifting-tsx": () => import("./../../../src/templates/Gifting.tsx" /* webpackChunkName: "component---src-templates-gifting-tsx" */),
  "component---src-templates-history-tsx": () => import("./../../../src/templates/History.tsx" /* webpackChunkName: "component---src-templates-history-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-la-maison-maille-tsx": () => import("./../../../src/templates/LaMaisonMaille.tsx" /* webpackChunkName: "component---src-templates-la-maison-maille-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/Landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-page-not-found-tsx": () => import("./../../../src/templates/PageNotFound.tsx" /* webpackChunkName: "component---src-templates-page-not-found-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-recipe-collection-tsx": () => import("./../../../src/templates/RecipeCollection.tsx" /* webpackChunkName: "component---src-templates-recipe-collection-tsx" */),
  "component---src-templates-recipe-landing-tsx": () => import("./../../../src/templates/RecipeLanding.tsx" /* webpackChunkName: "component---src-templates-recipe-landing-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/Recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */),
  "component---src-templates-stores-tsx": () => import("./../../../src/templates/Stores.tsx" /* webpackChunkName: "component---src-templates-stores-tsx" */)
}

