import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Instagram = (props) => {
  return (
    <StyledSvg
      width={32.387}
      height={32.387}
      viewBox='0 0 32.387 32.387'
      {...props}
    >
      <path
        data-name='Path 31'
        d='M16.193 2.969c4.318 0 4.858 0 6.612.135 4.453.135 6.477 2.294 6.612 6.612.135 1.754.135 2.159.135 6.477s0 4.858-.135 6.477c-.135 4.318-2.294 6.477-6.612 6.612-1.754.135-2.159.135-6.612.135-4.318 0-4.858 0-6.477-.135-4.453-.135-6.477-2.294-6.612-6.612-.135-1.754-.135-2.159-.135-6.477s0-4.858.135-6.477C3.239 5.4 5.4 3.239 9.716 3.1c1.619-.131 2.159-.131 6.477-.131zm0-2.969C11.74 0 11.2 0 9.581.135 3.644.4.4 3.644.135 9.581 0 11.2 0 11.74 0 16.193s0 4.993.135 6.612c.27 5.938 3.509 9.176 9.446 9.446 1.619.135 2.159.135 6.612.135s4.993 0 6.612-.135c5.938-.27 9.176-3.509 9.446-9.446.135-1.619.135-2.159.135-6.612s0-4.993-.135-6.612C31.982 3.644 28.743.4 22.806.135 21.186 0 20.647 0 16.193 0zm0 7.827a8.367 8.367 0 108.367 8.367 8.409 8.409 0 00-8.367-8.367zm0 13.764a5.4 5.4 0 115.4-5.4 5.414 5.414 0 01-5.4 5.4zM24.83 5.668a1.845 1.845 0 00-1.889 1.889 1.889 1.889 0 103.778 0 1.845 1.845 0 00-1.889-1.889z'
        fill={props.color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(18)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(16)};
    &.sticky-nav-icon {
      width: ${desktopVw(12)};
    }
  }
`
