/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { TweenMax } from 'gsap'
import {
  colors,
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  fonts,
} from '../../../styles'
import { Space } from '../Space'
import RichText from '../RichText'
import { LinkType } from '../LinkType'
import { useLang } from '../../../hooks'

export const Textslider = ({ data, swiper, order }) => {
  let arr = null
  const lang = useLang()
  const refSlider = useRef([])

  useEffect(() => {
    if (swiper === null) {
      return
    }

    arr = refSlider.current
    const lengthArr = [...Array(arr.length)]

    const slideTransition = (prevSlide) => {
      TweenMax.set(arr[prevSlide], {
        autoAlpha: 0,
        y: 0,
      })
      TweenMax.set(arr[swiper.realIndex], {
        autoAlpha: 1,
        y: 0,
      })
    }

    if (data) {
      // init
      lengthArr.map((x, i) => slideTransition(i, 0))

      swiper.on('slideNextTransitionEnd', () => {
        lengthArr.map((x, i) => {
          return slideTransition(i, 1.5)
        })
      })

      swiper.on('slidePrevTransitionEnd', () => {
        lengthArr.map((x, i) => {
          return slideTransition(i, 1.5)
        })
      })
    }
  }, [swiper])

  return (
    <StyledTextslider>
      {/* On mobile they want to havve the text under the image */}
      {order ? (
        <>
          {[...Array(2)].map((x, i) => {
            return <Hidden key={i}>l</Hidden>
          })}
          {data.map(({ title, link, subTitleText }, i) => {
            return (
              <Slider
                key={i}
                ref={(e) => {
                  refSlider.current[i] = e
                }}
              >
                {link ? (
                  <LinkType to={`/${lang}${link.link.slug}`}>
                    {title.map((word, index) => {
                      return (
                        <>
                          <span key={index}>{word}</span> <br></br>
                        </>
                      )
                    })}
                  </LinkType>
                ) : (
                  title.map((word, index) => {
                    return <span key={index}>{word}</span>
                  })
                )}
                {subTitleText && 
                <SubTitleText>
                  {subTitleText}
                </SubTitleText>}
              </Slider>
            )
          })}
        </>
      ) : (
        <>
          <HiddenMob>
            {data && data[0].text && (
              <Description>{data[2].text.text}</Description>
            )}
            <Space s={1} />
          </HiddenMob>
          {data &&
            data.map(({ text }, i) => {
              return (
                <Slider
                  key={i}
                  ref={(e) => {
                    refSlider.current[i] = e
                  }}
                >
                  <Space l={1} s={1} />
                  {text && <Description>{text.text}</Description>}
                </Slider>
              )
            })}
          {data &&
            data.map(({ description }, i) => {
              return (
                <Slider
                  key={i}
                  ref={(e) => {
                    refSlider.current[i] = e
                  }}
                >
                  <Space l={1} s={1} />
                  {description && (
                    <Description>
                      <RichText json={description} />
                    </Description>
                  )}
                </Slider>
              )
            })}
        </>
      )}
    </StyledTextslider>
  )
}

const StyledTextslider = styled.div`
  position: relative;
  width: 100%;
  z-index: 5;
  height: ${mobileVw(90)};
  @media (min-width: ${desktopBreakpoint}) {
    height: unset;
  }
` 

const Slider = styled.div`
  position: absolute;
  top: 0;
  opacity: 0;
  .gold:first-of-type {
    color: ${colors.mailleGold};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(50)} 0 0;
  }
`

const Hidden = styled.span`
  position: relative;
  opacity: 0;
  display: block !important;
`

const HiddenMob = styled.span`
  position: relative;
  opacity: 0;
  white-space: inherit !important;
  display: block !important;

  @media (min-width: ${desktopBreakpoint}) {
    display: none !important;
  }
`
const SubTitleText = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(24)};
  margin: ${(props) => mobileVw(props.marginMobile || 50)} ${mobileVw(
    16,
  )} 0; 
  z-index: 1;
  margin: 0;

  @media (min-width: ${desktopBreakpoint}) {
    /* position: relative; */
    font-size: ${desktopVw(25)};
    line-height: ${desktopVw(24)};
    padding:  1.5rem 0;
    color: #f4bc34
}
`
const Description = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(24)};
  /* margin: ${(props) => mobileVw(props.marginMobile || 50)} ${mobileVw(
    16,
  )} 0; */
  z-index: 1;
  margin: 0;

  @media (min-width: ${desktopBreakpoint}) {
    /* position: relative; */
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(24)};
    /* margin: ${(props) => desktopVw(props.margin || 50)} ${desktopVw(95)} 0
      ${desktopVw(0)}; */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  h3 {
    font-family: ${fonts.tivoli};
    margin: 0 0 ${mobileVw(8)} 0;
    font-size: ${mobileVw(30)};
    text-transform: uppercase;

    @media (min-width: ${desktopBreakpoint}) {
      margin: 0 0 ${desktopVw(15)} 0;
      font-size: ${desktopVw(30)};
    }
  }
`
