import { decode } from 'shopify-gid'

export const useProductInfoAnalytics = (product) => {
  const id = product.shopifyProduct
    ? decode(product.shopifyProduct.variants.edges[0].node.id).id
    : 'unset'
  if (typeof window !== 'undefined' && window.digitalData) {
    window.digitalData.product.push({
      productInfo: {
        productID: id,
        productName: product.title,
        price:
          product.shopifyProduct &&
          product.shopifyProduct.variants.edges[0].node.price,
        brand: 'Maille',
        quantity: 1,
      },
      category: {
        primaryCategory: product.shopifyProduct ? 'Product' : 'Classic product',
      },
      pCAT: {
        name: 'shs',
        level: '2',
      },
      attributes: {
        productVariants:
          product.shopifyProduct && product.shopifyProduct.variants,
        listPosition: 4,
      },
    })

    window.digitalData.event.push({
      eventInfo: {
        type: ctConstants && ctConstants.productInfo,
      },
      category: { primaryCategory: ctConstants.engagement },
      subcategory: 'Read',
      attributes: { nonInteractive: { nonInteraction: 1 } },
    })

    console.log(window.digitalData)
  }
}
