import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { LinkType, Productcard } from '../shared'

import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'

import { useLang, useLocale, useSearchAnalytics } from '../../hooks'

const SearchSuggestions = ({ search, handleClose }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageRecipe {
        nodes {
          node_locale
          thumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title
          slug
          relatedProducts {
            title
          }
        }
      }
      allContentfulProduct {
        nodes {
          node_locale
          name
          thumbnailImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title
          slug
          shopifyProduct {
            id
            handle
            variants {
              edges {
                node {
                  id
                  title
                  price{
                    amount
                  }
                }
              }
            }
          }
          isGourmetAndClassical
        }
      }
    }
  `)
  const locale = useLocale();
  const lang = useLang();
  const allRecipes = []

  
  data.allContentfulPageRecipe.nodes.map((obj) => {
    if (obj.node_locale === locale && obj.title && obj.thumbnailImage && obj.slug) {
      allRecipes.push(obj)
    }
  })


  const allProducts = []
  data.allContentfulProduct.nodes.map((obj) => {
    const testcheck = obj.name.split(' ')
    if (obj.node_locale === locale && obj.title && obj.slug  && obj.thumbnailImage && testcheck[0].toLowerCase() !== 'test') {
      allProducts.push(obj)
    }
  })


  const matchingRecipes = []
  const matchingGourmetProducts = []
  const matchingClassicalProducts = []

  const normalize = (search) => {
    const newSearch = search
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace("'", '')
    return newSearch
  }

  const getRecipes = () => {
    allRecipes.filter(({ slug }) => slug !== null).map(({ relatedProducts }, i) =>
      relatedProducts && relatedProducts.map(({ title }) =>
        normalize(title).includes(normalize(search)) ||
          normalize(allRecipes[i].title).includes(normalize(search))
          ? search.length <= 2 && matchingRecipes.length >= 20
            ? null
            : allRecipes[i].node_locale === locale && matchingRecipes.push(allRecipes[i])
          : null,
      ),
    )
  }

  const getProducts = () => {
    allProducts.map((product) =>
      normalize(product.title).includes(normalize(search)) &&
        (product.shopifyProduct || product.isGourmetandClassical)
        ? search.length <= 2 && matchingGourmetProducts.length >= 20
          ? null
          : product.node_locale === locale && matchingGourmetProducts.push(product)
        : normalize(product.title).includes(normalize(search))
          ? search.length <= 2 && matchingClassicalProducts.length >= 20
            ? null
            : product.node_locale === locale && matchingClassicalProducts.push(product)
          : null,
    )
  }

  return (
    <Wrapper
      onClick={() =>
        useSearchAnalytics(
          search,
          matchingGourmetProducts.length +
          matchingClassicalProducts.length +
          matchingRecipes.length,
        )
      }
    >
      {getRecipes()}
      {getProducts()}
      <Section>
        {matchingGourmetProducts.length > 0 ? (
          <StyledSpan>Collection les gourmets</StyledSpan>
        ) : null}
        {Array.from(new Set(matchingGourmetProducts)).map((product, i) => (
          <LinkType
            key={i}
            to={`/${lang}${product.slug}`}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${product.title}`}
            onClick={handleClose}
          >
            <Item>
              <ImageContainer>
                {product.thumbnailImage && product.thumbnailImage.fluid && (
                  <StyledImage
                    fluid={product.thumbnailImage.fluid}
                    alt={product.thumbnailImage.title}
                  />
                )}
              </ImageContainer>
              <h2>{product.title}</h2>
            </Item>
          </LinkType>
        ))}
        {matchingClassicalProducts.length > 0 ? (
          <StyledSpan>Collection les classiques</StyledSpan>
        ) : null}
        {Array.from(new Set(matchingClassicalProducts)).map((product, i) => (
          <LinkType
            key={i}
            to={`/${lang}${product.slug}`}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${product.title}`}
            onClick={handleClose}
          >
            <Item>
              <ImageContainer>
                {product.thumbnailImage && product.thumbnailImage.fluid && (
                  <StyledImage
                    fluid={product.thumbnailImage.fluid}
                    alt={product.thumbnailImage.title}
                  />
                )}
              </ImageContainer>
              <h2>{product.title}</h2>
            </Item>
          </LinkType>
        ))}
        {matchingRecipes.length > 0 ? <StyledSpan>Recettes</StyledSpan> : null}
        {Array.from(new Set(matchingRecipes)).map((recipe, i) => (
          <LinkType
            key={i}
            to={`/${lang}${recipe.slug}`}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${recipe.title}`}
            onClick={handleClose}
          >
            <Item>
              {recipe.thumbnailImage && recipe.thumbnailImage.fluid && (
                <StyledRecipeImage
                  fluid={recipe.thumbnailImage.fluid}
                  alt={recipe.thumbnailImage.title}
                />
              )}
              <h2>{recipe.title}</h2>
            </Item>
          </LinkType>
        ))}
        <StyledSpan>Rechercher &quot;{search}&quot;</StyledSpan>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.black};
  overflow-y: scroll;
  max-height: 90vh;
  position: relative;
  min-width: 80vw;
  padding: ${mobileVw(28)} ${mobileVw(30)} ${mobileVw(28)} ${mobileVw(15)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
    padding: ${desktopVw(28)} ${desktopVw(30)} ${desktopVw(28)} ${desktopVw(15)};
    position: absolute;
    right: 0;
    top: ${desktopVw(40)};
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  @media (min-width: ${desktopBreakpoint}) {
  }
`

const Item = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: ${mobileVw(10)};

  &:hover {
    background-color: ${colors.mailleGold};
    color: ${colors.white};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(10)};
    padding: ${desktopVw(8)} ${desktopVw(15)};
  }
`

const ImageContainer = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${mobileVw(50)};
  height: ${mobileVw(50)};
  margin-right: ${mobileVw(16)};

  img {
    object-fit: contain !important;
  }

  @media (min-width: ${desktopBreakpoint}) {
    min-width: auto;
    width: ${desktopVw(57)};
    height: ${desktopVw(56)};
    margin-right: ${desktopVw(16)};
  }
`

const StyledImage = styled(Image)`
  mix-blend-mode: multiply;
  width: ${mobileVw(39)};
  height: ${mobileVw(56)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(39)};
    height: ${desktopVw(56)};
  }
`

const StyledRecipeImage = styled(Image)`
  mix-blend-mode: multiply;
  min-width: ${mobileVw(56)};
  height: ${mobileVw(56)};
  margin-right: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    min-width: auto;
    width: ${desktopVw(56)};
    height: ${desktopVw(56)};
    margin-right: ${desktopVw(16)};
  }
`

const StyledSpan = styled.span`
  font-family: ${fonts.tivoli};
  color: ${colors.mailleGold};
  font-size: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(29)} 0 ${desktopVw(20)};
    letter-spacing: ${desktopVw(1.6)};
    font-size: ${desktopVw(20)};
  }
`

export default SearchSuggestions
