import React from 'react'
import { Link } from 'gatsby'

export const LinkType = (props) => {
  const altAtt =
    (props && props.title) || 'Ce lien va ouvrir une nouvelle fenêtre'
  return (
    <Link alt={altAtt} {...props}>
      {props.children}
    </Link>
  )
}
