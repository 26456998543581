import React, { useState } from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom';
import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  letterSpacing,
  shortMobVw,
  shortDskVw,
  colors,
} from '../../styles'

import {
  Button,
  Icon,
  Social,
  Decoration,
  LinkType,
  EmailForm,
} from '../shared'

import { useLang, useLocale, useSiteQuery } from '../../hooks'
import NewsletterMailchimp from '../newsletterMailchimp/NewsletterMailchimp'
import CookieFooterEnCA from './CookieFooterEnCA'
import CookieFooterFrCA from './CookieFooterFrCA'
import PopUp from '../checkLanguage/GeneralPopUp'

const Footer = () => {
  const lang = useLang()
  const locale = useLocale()
  const [show, setShow] = useState(false)
  const { footerDecoration, footer } = useSiteQuery()
  const [email, setEmail] = useState('')
  const [privacy, setPrivacy] = useState(false)
  const [privacyNeeded, setPrivacyNeeded] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)

  const instagramLink = footer.instagramLink
    ? footer.instagramLink
    : 'https://www.instagram.com/maisonmaille/?hl=en'
  const facebookLink = footer.facebookLink
    ? footer.facebookLink
    : 'https://www.facebook.com/Maille/?brand_redir=1417949285086328'
  const germanFooter = [
    {title: 'Kontakt',slug : null},
    {title: 'Datenschutzhinweis', slug: 'https://www.unilevernotices.com/privacy-notices/germany-german.html'},
    {title: 'Cookie informationen', slug: 'https://www.unilevernotices.com/cookie-notices/germany-german.html'}
    ]
  return (
    <StyledFooter>
      { <BackToTop>
        <button
          type='button'
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        >
          <Icon name='arrow-up' />
          <span>{footer.topOfPage}</span>
        </button>
      </BackToTop>}
      { locale === 'de' && <GermanFooter>
        {show && <PopUp setShow={setShow}/>}
        {germanFooter.map((item, id) =>{
            const {title, slug} = item
            if(slug){
                return <a href = {slug} target='_blanc' key={id}>{title}</a>
            }
            return(
                <span key={id} onClick={() => setShow(true)}>{title}</span>
            )
        })}
      </GermanFooter>}
      <Wrapper newsletter={true}>
        {/* {
          footer.titleNewsletter && */}
        <StyledNewsletter>
          {locale === 'fr' && (
            <>
              <h2>
                {footer.titleNewsletter
                  ? footer.titleNewsletter
                  : 'stay Informed'}
              </h2>
              <Paragraph>
                {footer.descriptionNewsletter.descriptionNewsletter
                  ? footer.descriptionNewsletter.descriptionNewsletter
                  : 'Restez informés ! Recevez les dernières offres, nouveautés et recettes'}
              </Paragraph>
            </>
          )}
          {/* {locale === 'fr' ? (
            <EmailForm form='footer' formId='footer-form' />
          ) : locale === 'de' ? '' : (
            <NewsletterMailchimp footer='footer' />
          )} */}
          {locale === 'fr' && <EmailForm form='footer' formId='footer-form' />}
          {locale === 'en-CA' && <NewsletterMailchimp footer='footer' />}
          {locale === 'fr-CA' && <NewsletterMailchimp footer='footer' />}
        </StyledNewsletter>

        {/* } */}

        <Grid
          only={
            !!(
              footer.fourthColumnFooterTitle &&
              !footer.thirdColumnFooterTitle &&
              !footer.secondColumnFooterTitle &&
              !footer.firstColumnFooterTitle
            )
          }
        >
          <FooterLinks
            only={
              !!(
                footer.fourthColumnFooterTitle &&
                !footer.thirdColumnFooterTitle &&
                !footer.secondColumnFooterTitle &&
                !footer.firstColumnFooterTitle
              )
            }
          >
            {footer.firstColumnFooterTitle && (
              <Column
                key={
                  footer.firstColumnFooterTitle && footer.firstColumnFooterTitle
                }
              >
                <Title>
                  {footer.firstColumnFooterTitle &&
                    footer.firstColumnFooterTitle}
                </Title>
                <ul>
                  {footer.firstColumnFooterTitle &&
                    footer.firstColumnFooterLinks.map((item) => {
                      return (
                        <li key={item.title}>
                          {item.link ? (
                            <LinkType
                              to={`/${lang}${item.link.slug}`}
                              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                            >
                              {item.title}
                            </LinkType>
                          ) : (
                            <a
                              href={item.externalLink}
                              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                            >
                              {item.title}
                            </a>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </Column>
            )}
            {footer.secondColumnFooterTitle && (
              <Column
                key={
                  footer.secondColumnFooterTitle &&
                  footer.secondColumnFooterTitle
                }
                newWidth={locale === 'de' ? true : false}
                only={locale === 'de' ? true : false}
              >
                <Title>
                  {locale === 'de'
                    ? ''
                    : footer.secondColumnFooterTitle &&
                      footer.secondColumnFooterTitle}
                </Title>
                <ul>
                  {footer.secondColumnFooterTitle &&
                    footer.secondColumnFooterLinks.map((item) => {
                      return (
                        <li key={item.title}>
                          {item.link ? (
                            <LinkType
                              to={`/${lang}${item.link.slug}`}
                              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                            >
                              {item.title}
                            </LinkType>
                          ) : (
                            <a
                              href={item.externalLink}
                              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                            >
                              {item.title}
                            </a>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </Column>
            )}
            {footer.thirdColumnFooterTitle && (
              <Column
                key={
                  footer.thirdColumnFooterTitle && footer.thirdColumnFooterTitle
                }
              >
                <Title>
                  {footer.thirdColumnFooterTitle &&
                    footer.thirdColumnFooterTitle}
                </Title>
                <ul>
                  {footer.thirdColumnFooterTitle &&
                    footer.thirdColumnFooterLinks.map((item) => {
                      return (
                        <li key={item.title}>
                          {item.link ? (
                            <LinkType
                              to={`/${lang}${item.link.slug}`}
                              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                            >
                              {item.title}
                            </LinkType>
                          ) : (
                            <a
                              href={item.externalLink}
                              alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                            >
                              {item.title}
                            </a>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </Column>
            )}

            <Column
              key={
                footer.fourthColumnFooterTitle && footer.fourthColumnFooterTitle
              }
              only={
                !!(
                  footer.fourthColumnFooterTitle &&
                  !footer.thirdColumnFooterTitle &&
                  !footer.secondColumnFooterTitle &&
                  !footer.firstColumnFooterTitle
                )
              }
            >
              <Title>
                <a
                  href={`https://www.unilever.fr/legal/`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {footer.fourthColumnFooterTitle &&
                    footer.fourthColumnFooterTitle}
                  <span>ouvrir lien dans une nouvelle fenêtre</span>
                  {/* <span>The link will open in a new window</span> */}
                </a>
              </Title>
              <ul>
                {footer.fourthColumnFooterTitle &&
                  footer.fourthColumnFooterLinks.map((item) => {
                    return (
                      <li key={item.title}>
                        {item.link ? (
                          <LinkType
                            to={`/${lang}${item.link.slug}`}
                            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                          >
                            {item.title}
                          </LinkType>
                        ) : (
                          <a
                            href={item.externalLink}
                            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${item.title}`}
                          >
                            {item.title}
                          </a>
                        )}
                      </li>
                    )
                  })}
              </ul>

              <ul>
                {locale !== 'fr' && (
                  <li>{locale === 'en-CA' ? <CookieFooterEnCA /> : ''}</li>
                )}
              </ul>
            </Column>
          </FooterLinks>
          <SocialWrapper
            only={
              !!(
                footer.fourthColumnFooterTitle &&
                !footer.thirdColumnFooterTitle &&
                !footer.secondColumnFooterTitle &&
                !footer.firstColumnFooterTitle
              )
            }
          >
            {locale !== 'de' && (
              <StyledSocial
                social={{
                  title: footer.socialTitle,
                  share: ['Instagram', 'Facebook'],
                  links: [instagramLink, facebookLink],
                }}
                footer={locale !== 'fr'}
              />
            )}
            {locale === 'fr' && (
              <Payments>
                <h2>{footer.paymentsTitle}</h2>
                <div>
                  <Icon name='applepay' />
                  <Icon name='visa' />
                  <Icon name='amex' />
                  <Icon name='mastercard' />
                  <Icon name='paypal' />
                </div>
              </Payments>
            )}
          </SocialWrapper>
        </Grid>
      </Wrapper>
      <Copyright>{footer.copyright.copyright}</Copyright>
      {footerDecoration.map((data) => {
        return <Decoration key={data.name} data={data} />
      })}
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 ${mobileVw(16)} ${mobileVw(50)};
  background: #000;
  color: #fff;
  font-family: 'MrsEaves';
  font-size: ${mobileVw(12)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(15)} ${desktopVw(128)} ${desktopVw(140)};
    font-size: ${desktopVw(14)};
  }
`

const BackToTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${shortMobVw([16, 40])};
  margin: ${desktopVw(20)};

  button {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    width: 100%;
    font-family: 'Tivoli Bold';
    font-size: ${mobileVw(16)};
    letter-spacing: ${letterSpacing(100)};
    color: #fff;
    cursor: pointer;

    span {
      margin-top: ${mobileVw(12)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    button {
      font-size: ${desktopVw(16)};

      span {
        margin-top: ${desktopVw(10)};
      }
    }
  }
`

const Wrapper = styled.div`
  display: grid;
  padding: ${shortMobVw([16, 0, 0, 0])};
  grid-gap: ${mobileVw(50)} 0;

  h2 {
    margin: 0 0 ${mobileVw(15)} 0;
    font-family: 'Tivoli Bold';
    font-size: ${mobileVw(20)};
    line-height: ${mobileVw(16)};
    letter-spacing: ${letterSpacing(100)};
    text-transform: uppercase;
    color: ${colors.mailleGold};
  }

  @media (min-width: ${desktopBreakpoint}) {
    ${({ newsletter }: any) =>
      newsletter
        ? `
      grid-template-columns: auto 1fr;
      `
        : `
      grid-template-columns: 1fr;
      `}
    grid-gap: 0;
    padding: ${desktopVw(10)} 0 0 0;
    margin: 0 0 ${desktopVw(25)};

    h2 {
      font-size: ${desktopVw(20)};
      line-height: ${desktopVw(16)};
      margin: 0 0 ${desktopVw(8)} 0;
    }
  }
`

const FooterLinks = styled.div`
  display: ${(props) => (props.only ? null : `inline-grid`)};
  grid-template-columns: ${(props) => (props.only ? `none` : `repeat(2, 1fr)`)};
  justify-content: space-between;
  grid-gap: ${mobileVw(25)} 0;
  justify-content: space-between;

  h2 {
    font-size: ${mobileVw(16)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: ${(props) =>
      props.only ? 'repeat(1, auto)' : 'repeat(4, auto)'};
    grid-gap: 0;
    width: 100%;

    h2 {
      font-size: ${desktopVw(18)};
    }
  }
`

const Column = styled.div`
 
ul {
  display:${(props) => (props.only ? 'flex' : 'unset')};     
    
    padding: 0;
    font-size: ${mobileVw(14)};
    /* width: ${mobileVw(128)}; */

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(16)};
      line-height: ${desktopVw(20)};
      width: inherit;
    }
    @media (max-width:${desktopBreakpoint}){
      flex-direction:${(props) => (props.only ? 'column' : 'unset')}
    }
  }

  li {
    
    list-style: none;
    margin: ${(props) => (props.only ? '0 3% 0 0' : '0')};
    padding: 0;
    width: ${(props) => (props.newWidth ? '12vw' : '')};
    @media (max-width: ${desktopBreakpoint}) {
      padding: 10px 0;
      
      a{
          padding:15px 0;
        }
    }
  }
}

`

const Title = styled.h2`
  font-family: 'Tivoli Bold';
  position: relative;
  text-transform: uppercase;
  color: ${colors.mailleGold};
  margin: 0 0 ${mobileVw(15)} 0;
  font-size: ${mobileVw(20)};
  letter-spacing: ${letterSpacing(100)};

  & a span {
    position: absolute;
    left: -9000px;
    width: 0;
    overflow: hidden;
  }
  & a:hover span {
    display: block;
    position: absolute;
    top: 1em;
    left: 1em;
    width: 12em;
    border: 1px solid #0cf;
    background-color: #fff;
    color: #000;
    text-align: center;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(14)} 0;
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
  }
`

const Paragraph = styled.p`
  font-size: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(16)};
    margin: ${shortDskVw([8, 0, 12, 0])};
    max-width: ${desktopVw(300)};
  }
`

const Input = styled.input`
  width: 100%;
  border: 0.5px solid #fff;
  color: #fff;
  background: transparent;
  font-size: ${mobileVw(16)};
  padding: ${mobileVw(11)} ${mobileVw(15)};
  margin: 0 0 ${mobileVw(17)} 0;
  font-family: 'MrsEaves';
  outline: none;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    padding: ${desktopVw(15)} ${desktopVw(24)};
    margin: 0 0 ${desktopVw(17)} 0;
    border: ${desktopVw(0.5)} solid #fff;
  }

  &::placeholder {
    color: #fff;
    font-family: 'MrsEaves';
  }
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${(props) => (props.only ? 'unset' : 'auto 0 0 0')};
  }
`

const StyledSocial = styled(Social)`
  order: 1;
  width: 100%;
  margin: ${(props) =>
    props.footer
      ? `0 0 ${mobileVw(38)} `
      : `${mobileVw(30)} 0 ${mobileVw(38)}`};
  padding: 0;

  h2 {
    margin: 0 0 ${mobileVw(18)};
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    color: ${colors.mailleGold};
    letter-spacing: ${letterSpacing(100)};
  }

  a {
    height: ${mobileVw(20)};
    margin: 0 ${mobileVw(30)} 0 0;
  }

  svg {
    height: ${mobileVw(20)};
    * {
      fill: #fff;
    }

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(160)};
    margin: 0 ${desktopVw(25)} 0 0;

    h2 {
      margin: ${shortDskVw([0, 0, 14, 0])};
      font-size: ${desktopVw(18)};
      line-height: ${desktopVw(16)};
    }
    a {
      height: ${desktopVw(20)};
      margin: ${shortDskVw([0, 20, 0, 0])};
    }
    svg {
      height: ${desktopVw(20)};
    }
  }
`



const Payments = styled.div`
  order: 3;
  margin: 0 0 ${mobileVw(25)} 0;

  h2 {
    font-family: 'Tivoli Bold';
    margin: 0 0 ${mobileVw(13)};
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    color: ${colors.mailleGold};
    letter-spacing: ${letterSpacing(100)};
    text-transform: uppercase;
  }

  svg {
    height: ${mobileVw(20)};
    margin: 0 ${mobileVw(15)} 0 0;

    &:last-child {
      margin: 0;
    }

    * {
      fill: #fff;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    order: 2;
    margin: 0;

    h2 {
      margin: ${shortDskVw([0, 0, 10, 0])};
      font-size: ${desktopVw(18)};
      line-height: ${desktopVw(16)};
    }

    svg {
      height: ${desktopVw(24)};
      margin: ${shortDskVw([0, 18, 0, 0])};
    }
  }
`

const Copyright = styled.p`
  order: 2;
  width: 100%;
  margin: 0 0 ${mobileVw(33)};
  font-size: ${mobileVw(12)};
  line-height: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    order: 3;
    margin: 0;
    font-size: ${desktopVw(14)};
    line-height: ${desktopVw(16)};
    width: ${desktopVw(770)};
  }
`

const Statement = styled.div`
  margin: 0 0 ${mobileVw(12)} 0;
  text-align: left;
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
  font-size: ${mobileVw(12)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(20)} 0;
    font-size: ${desktopVw(14)};
  }
`

const StyledCheckbox = styled.input`
  background: transparent;
  border-radius: 0;
  display: block;
  margin: 0 ${mobileVw(7)} 0 0;
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};
  border: ${mobileVw(0.5)} solid #fff;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(12)} 0 0;
    width: ${desktopVw(16)};
    height: ${desktopVw(16)};
    border: ${desktopVw(0.5)} solid #fff;
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.only ? 'row' : 'column')};
  display: ${(props) => (props.only ? 'flex' : 'grid')};
`

const StyledNewsletter = styled.div`
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(256)};
    margin: 0 ${desktopVw(50)} 0 0;
  }
`

const CookieAnchor = styled.a`
  text-decoration: none !important;
`

const CookieImg = styled.img`
  border: 0 !important;
  display: inline !important;
  vertical-align: middle !important;
  padding-right: 5px !important;
`

const CookieSpan = styled.span`
  vertical-align: middle !important;
`
const GermanFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction:column;
  align-items: center;
  padding-top: 2rem;
  span{
    margin: 10px;
    font-size: ${mobileVw(16)};
}
  a{
    margin: 10px;
    font-size: ${mobileVw(16)};
  }
  @media (min-width: ${desktopBreakpoint}){
    flex-direction: row;
    span{
        font-size: ${desktopVw(16)};
        line-height: ${desktopVw(20)};
        margin: 20px
    }
    a{
        font-size: ${desktopVw(16)};
        line-height: ${desktopVw(20)};
        margin: 20px
    }
  }
`

export default Footer
