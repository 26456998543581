import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { setCookie, getCookie } from 'koekie'
import gsap, { Power2 } from 'gsap'
import { useSiteQuery, useCustomer } from '../../hooks'
import { Image } from '../shared/Image'
import { Button, EmailForm } from '../shared'

import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  letterSpacing,
  colors,
  fonts,
} from '../../styles'

export const Newsletter = () => {
  const { defaultTestimonialImage } = useSiteQuery()

  // Disable so it doesnt flickr when the page is loaded
  const [visibility, setVisibility] = useState(false)
  const [animation, setAnimation] = useState(true)
  const [succes, setSuccesForm] = useState(false)

  const user = useCustomer()

  const cookie = 'maille_newsletter_closed'
  const langCookie = 'maille_langSelector_closesd'

  useEffect(() => {
    if (getCookie(cookie) || user) setVisibility(false)
    else
      setTimeout(() => {
        setVisibility(true)
      }, 2000)
  }, [])

  const close = () => {
    setVisibility(false)
    setCookie(cookie, true, {
      expires: 15,
      in: 'days',
      path: '/',
    })
  }

  const refContainer = useRef(null)
  const refOverlay = useRef(null)

  const [tl] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    tl.fromTo(
      refContainer.current,
      0.4,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: Power2.easeInOut },
    )
      .fromTo(
        refOverlay.current,
        0.4,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Power2.easeInOut },
        0,
      )
      .fromTo(
        refContainer.current.children,
        0.4,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Power2.easeInOut },
      )
  }, [])

  if (visibility && animation) {
    setAnimation(false)
    tl.play()
  } else if (!visibility && !animation) {
    setAnimation(true)
    tl.reverse(0)
  }

  return (
    <Wrapper>
      <Overlay ref={refOverlay} onClick={() => close()} />
      <Animated ref={refContainer}>
        <StyledNewsletter aria-label='Newsletter popup'>
          <StyledImage
            fluid={defaultTestimonialImage.fluid}
            alt={defaultTestimonialImage.title}
          />
          <Content>
          <Heading>
            Inscrivez-vous pour rejoindre le Club Maille et bénéficier de
              <strong> 15% </strong>
            de remise sur votre première commande.
            </Heading>
            <EmailForm
              setSuccesForm={(value) => setSuccesForm(value)}
              form='popup'
              listId={process.env.GATSBY_DOTDIGITAL_LIST_GLOBAL_POPUP}
            />
            <Cancel onClick={() => close()}>
              {succes ? 'Merci!!!' : 'Non merci'}
            </Cancel>
          </Content>
        </StyledNewsletter>
      </Animated>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 99999;

  input {
    border: solid ${mobileVw(1)} ${colors.black};
    color: ${colors.black};
    ::placeholder {
      color: ${colors.black};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    input {
      border: solid ${desktopVw(1)} ${colors.black};
    }
  }
`

const Animated = styled.div`
  opacity: 0;
  background: white;
  margin: 0 ${mobileVw(16)};
  display: block;
  min-height: calc(100% - ${mobileVw(160)});
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999999;
  transform: translateY(-50%);

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(128)};
    min-height: calc(100vh - ${desktopVw(160)});
  }
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  align-items: center;
  opacity: 0;
`

const StyledNewsletter = styled.section`
  background: ${colors.white};
  width: 100%;
  height: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    display: grid;
    grid-template-columns: ${desktopVw(570)} auto;
    min-height: calc(100vh - ${desktopVw(160)});
  }
`

const StyledImage = styled(Image)`
  height: ${mobileVw(184)};

  @media (min-width: ${desktopBreakpoint}) {
    height: unset;
  }
`

const Content = styled.div`
  margin: ${mobileVw(20)} ${mobileVw(16)};
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(145)} ${desktopVw(95)} ${desktopVw(50)};
  }
`

const Heading = styled.h3`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  line-height: ${mobileVw(20)};
  margin: 0 0 ${mobileVw(10)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(30)} 0;
  }
`

const Form = styled.form``

const Label = styled.label`
  display: block;
  font-family: ${fonts.tivoli};
  text-transform: uppercase;
  letter-spacing: ${letterSpacing(100)};
  font-size: ${mobileVw(16)};
  margin: 0 0 ${mobileVw(11)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    margin: 0 0 ${desktopVw(11)} 0;
  }
`

const Input = styled.input`
  background: ${colors.white};
  border: ${mobileVw(1)} solid ${colors.black};
  padding: ${mobileVw(10)} ${mobileVw(15)};
  outline: none;
  width: 100%;
  font-size: ${mobileVw(14)};
  margin: 0 0 ${mobileVw(8)} 0;
  font-family: ${fonts.mrs};

  @media (min-width: ${desktopBreakpoint}) {
    border: ${desktopVw(1)} solid ${colors.black};
    padding: ${desktopVw(15)} ${desktopVw(24)};
    font-size: ${desktopVw(16)};
    margin: 0 0 ${desktopVw(15)} 0;
  }

  &::placeholder {
    font-family: ${fonts.mrs};
    color: ${colors.black};
  }
`

const Statement = styled.div`
  margin: ${mobileVw(10)} 0 ${mobileVw(12)} 0;
  text-align: left;
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
  font-size: ${mobileVw(12)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(15)} 0 ${desktopVw(20)} 0;
    font-size: ${desktopVw(14)};
  }
`

const StyledCheckbox = styled.div`
  background: transparent;
  border-radius: 0;
  display: block;
  margin: 0 ${mobileVw(7)} 0 0;
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};
  border: ${mobileVw(0.5)} solid #fff;
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: 0 ${desktopVw(12)} 0 0;
    width: ${desktopVw(16)};
    height: ${desktopVw(16)};
    border: ${desktopVw(0.5)} solid ${colors.black};
  }
`

const Cancel = styled.button`
  display: block;
  margin: auto 0 0 0;
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(16)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  border-bottom: ${mobileVw(1)} solid ${colors.black};
  align-self: center;
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(16)};
    font-size: ${desktopVw(16)};
    border-bottom: ${desktopVw(1)} solid ${colors.black};
  }
`
