import React from 'react'
import styled from 'styled-components'

export const Vegan = props => {
  if (props.circle === 'true') {
    return (
      <StyledSvg width={41} height={41} viewBox='0 0 41 41' {...props}>
        <g
          data-name='Group 1176'
          transform='translate(.5 .5)'
          stroke='#ae841f'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        >
          <circle data-name='Ellipse 57' cx={20} cy={20} r={20} />
          <path
            data-name='Path 1389'
            d='M6.628 11.471s13.111 11.706 13.111 22.945'
          />
          <path
            data-name='Path 1390'
            d='M31.915 12.407S19.74 17.09 19.74 34.415'
          />
          <path
            data-name='Path 1391'
            d='M24.416 18.792s-1.229-10.014 9.422-8.273c.418 7.508-7.724 8.675-7.724 8.675'
          />
        </g>
      </StyledSvg>
    )
  }
  return (
    <StyledSvg viewBox='0 0 19.799 17.464' {...props}>
      <g
        data-name='Group 820'
        fill='none'
        stroke={props.color}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path data-name='Path 1389' d='M.706 1.295S9.66 9.289 9.66 16.964' />
        <path data-name='Path 1390' d='M17.975 1.935S9.66 5.135 9.66 16.964' />
        <path
          data-name='Path 1391'
          d='M12.854 6.295s-.839-6.839 6.434-5.65c.286 5.127-5.275 5.924-5.275 5.924'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
