import React, { useEffect, useState, useContext, useMemo } from 'react'
import ShopifyBuy from 'shopify-buy'
import { LocalStorage, LocalStorageKeys } from './utils'
import {useCustomer} from './hooks/user/useCustomer'
interface InitialLayout {
  cartIsOpen: boolean
  menuIsOpen: boolean
  wishlistIsOpen: boolean
  wishlistProducts: Object
  wishlistRecipes: Object
  newsletterInactiveOnVegan: boolean
}

const InitialLayoutState = {
  cartIsOpen: false,
  menuIsOpen: false,
  wishlistIsOpen: false,
  wishlistProducts:
    LocalStorage.get('wishlist_products') &&
    JSON.parse(LocalStorage.get('wishlist_products')),
  wishlistRecipes:
    LocalStorage.get('wishlist_recipes') &&
    JSON.parse(LocalStorage.get('wishlist_recipes')),
  newsletterInactiveOnVegan: false
}

interface ContextShape {
  client: ShopifyBuy.Client | null
  cart: ShopifyBuy.Cart | null
  setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Cart | null>>
  layout: InitialLayout
  setLayout: React.Dispatch<React.SetStateAction<any | null>>
}

export const StoreContext = React.createContext<ContextShape>({
  client: null,
  cart: null,
  setCart: () => {
    throw Error('You forgot to wrap this in a Provider object')
  },
  layout: InitialLayoutState,
  setLayout: () => {
    throw Error('You forgot to wrap this in a Provider object')
  },
})
export const UserNameContext = React.createContext(false)
export const LocaleContext = React.createContext({ locale: 'fr', multiLang: [] })

export function StoreContextProvider({ children }) {
  const initialCart = LocalStorage.getInitialCart()
  const [cart, setCart] = useState<ShopifyBuy.Cart | null>(initialCart)
  const [layout, setLayout] = useState<any | null>(InitialLayoutState)

  const client = ShopifyBuy.buildClient({
    storefrontAccessToken: process.env.SHOPIFY_ACCES_TOKEN,
    domain: `${process.env.SHOPIFY_STORE}.myshopify.com`,
    apiVersion: '2023-04'
  })

  useEffect(() => {
    async function getNewCart() {
      const newCart = await client.checkout.create()
      setCart(newCart)
    }

    async function refreshExistingCart(cartId: string) {
      const nullVariant = !!(
        cart.lineItems.filter((item) => item.variant === null).length !== 0
      )

      try {
        const refreshedCart = await client.checkout.fetch(cartId)

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const cartHasBeenPurchased =
          refreshedCart && refreshedCart.completedAt != null

        if (cartHasBeenPurchased || cartHasBeenPurchased === null) {
          getNewCart()
        } else if (nullVariant) {
          getNewCart()
        } else {
          setCart(refreshedCart)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (cart == null) {
      getNewCart()
    } else {
      refreshExistingCart(String(cart.id))
    }
  }, [])

  useEffect(() => {
    LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(cart))
  }, [cart])

  return (
    <StoreContext.Provider
      value={{
        client,
        cart,
        setCart,
        layout,
        setLayout,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export const UserNameProvider = (props) => {
  const user = useCustomer()
  const [firstName, setFirstName] = useState(user?.firstName ? user?.firstName : "")
  const [lastName, setLastName] = useState(user?.lastName ? user?.lastName : "")

  const values = {
    firstName, 
    setFirstName,
    lastName, 
    setLastName
  }
  return (
   <UserNameContext.Provider value = {{...values}}>
    {props.children}
   </UserNameContext.Provider>
  )
}

