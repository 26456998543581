import * as React from 'react'
import styled from 'styled-components'
import { desktopVw, desktopBreakpoint, mobileVw } from '../../../styles'

export const Truck = props => {
  return (
    <Svg width={17.1} height={11.5} viewBox='0 0 17.1 11.5' {...props}>
      <g
        data-name='Group 1198'
        transform='translate(-600 -965)'
        fill='none'
        stroke='#ae841f'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          data-name='Path 1415'
          d='M604.7 974.6h6.3v-8.4a.7.7 0 00-.7-.7h-9.1a.7.7 0 00-.7.7v7.7a.7.7 0 00.7.7h.7'
        />
        <path
          data-name='Path 1416'
          d='M614.5 974.6h1.4a.7.7 0 00.7-.7v-3.21a.7.7 0 00-.2-.495l-2.39-2.39a.7.7 0 00-.495-.205H611v7h.7'
        />
        <circle
          data-name='Ellipse 61'
          cx={1.4}
          cy={1.4}
          r={1.4}
          transform='translate(601.9 973.2)'
        />
        <circle
          data-name='Ellipse 62'
          cx={1.4}
          cy={1.4}
          r={1.4}
          transform='translate(611.7 973.2)'
        />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: ${mobileVw(16.1)};
  height: ${mobileVw(10.5)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(32.24)};
    height: ${desktopVw(21.02)};
  }
`
