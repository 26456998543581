import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { colors, desktopBreakpoint, desktopVw, fonts, mobileVw } from '../../styles'
import { Button } from './Button'

export const InstagramSection = ({ hashtagText, link, icon, images, buttonText }) => {

  return (
    <Wrapper>
      <a
        href={link}
        alt='Ce lien va ouvrir une nouvelle fenêtre: Maille Instagram'
      >
        <HashtagWrapper>
          <InstagramIcon
            fluid={icon.fluid}
            alt={icon.title}
          />
          <Hashtag>#{hashtagText}</Hashtag>
        </HashtagWrapper>
      </a>
      <a
        href={link}
        alt='Ce lien va ouvrir une nouvelle fenêtre: Maille Instagram'
      >
        <ImageGallery>
          {images.map((image, i) => (
            <StyledImage key={i} fluid={image.fluid} alt={image.title} />
          ))}
        </ImageGallery>
      </a>
      <a
        className='mobileOnly'
        href={link}
        alt='Ce lien va ouvrir une nouvelle fenêtre: Maille Instagram'
      >
        <Button text={buttonText} />
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${mobileVw(40)} ${mobileVw(16)} ${mobileVw(80)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(20)} ${desktopVw(0)} ${desktopVw(80)};
  }
`

const HashtagWrapper = styled.div`
  display: flex;
  margin: ${mobileVw(12)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    align-items: center;
    padding-left: ${desktopVw(150)};
    margin: ${desktopVw(50)} 0 ${desktopVw(50)} 0;
  }
`

const Hashtag = styled.h2`
  color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(30)};
  padding-left: ${mobileVw(5)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(55)};
  }
`

const InstagramIcon = styled(Image)`
  width: ${mobileVw(30)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(55)};
  }
`

const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${mobileVw(12)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 0;
    justify-content: center;
  }
`

const StyledImage = styled(Image)`
  width: 44vw;
  margin-bottom: ${mobileVw(6)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(265)};
    margin: ${desktopVw(5)};
  }
`