import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { RichText } from '../shared'
import gsap, { Power2, Power4 } from 'gsap'
import {
  useToggleCart,
  useLayout,
  useCart,
  useCartCheckoutAnalytics,
  useUpdateItemQuantity,
  useUpdateItemAttributes,
} from '../../hooks'

import { LineItem, Button } from '../shared'

import {
  letterSpacing,
  colors,
  fonts,
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  newMobileVw,
  newDesktopVw,
  deskBp,
} from '../../styles'

const mockData = {
  fontFamilies: [
    {
      fontFamily: {
        name: 'Mrs Eaves',
        css: fonts.mrs,
      },
      fontSize: {
        mobile: 20,
        desktop: 28,
      },
    },
    {
      fontFamily: {
        name: 'Tivoli',
        css: fonts.tivoli,
      },
      fontSize: {
        mobile: 30,
        desktop: 36,
      },
    },
    {
      fontFamily: {
        name: 'Corsiva',
        css: fonts.corsiva,
      },
      fontSize: {
        mobile: 22,
        desktop: 30,
      },
    },
  ],
  characterLimit: 80,
}

const Cart = ({
  visible,
  showMessage,
  messagePlaceholder,
  giftMessageForUser,
  giftMessageHeadingText,
}: {
  visible: boolean
  showMessage: boolean
  messagePlaceholder: string
  giftMessageHeadingText: any
  giftMessageForUser: string
}) => {
  const [items, setItems] = useState([])
  const [webUrl, setWeburl] = useState(null)
  const cart = useCart()
  const toggleCart = useToggleCart()
  const [animationPlaying, setAnimationPlaying] = useState(false)
  const updateItemQuantity = useUpdateItemQuantity()
  const updateCustomAttributes = useUpdateItemAttributes()

  const [firstLineText, setFirstLineText] = useState('')
  const [secondLineText, setSecondLineText] = useState('')
  let [show, setShow] = useState(false)
  const Toggle = (e) => {
    // e.preventDefault()
    return (
      <>
        {setShow(!show)}
        {setFirstLineText('')}
      </>
    )
  }

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (cart) {
      setItems(cart.lineItems)
      setWeburl(
        cart.webUrl.replace(
          'https://maille-fr.myshopify.com/',
          'http://fr.maille.com/',
        ),
      )
    }
  }, [cart])

  const refCart = useRef(null)
  const refOverlay = useRef(null)

  // const removePersonalisationItem = async (quantity) => {
  //   console.debug(`: remove personalisation called`)
  //   // const perId = process.env.PERSONALISATION_ENGRAVING_PRODUCT_ID
  //   // items.map(item => {
  //   //   if (item.variant.id === perId) {
  //   //     updateItemQuantity(item.variant.id, item.quantity - quantity);
  //   //   }
  //   // })
  //   // debugger;
  // }

  const [tl] = useState(gsap.timeline({ paused: true }))

  const useBothAnalyticsAndAttributes = (e, webUrl) => {
    e.preventDefault()
    updateCustomAttributes(items[0].variant.id, {
      key: 'Note',
      value: firstLineText,
    }).then(() => {
      window.location = webUrl
    })
  }
  useEffect(() => {
    tl.set(refCart.current, { autoAlpha: 1 })
      .fromTo(
        refCart.current,
        0.5,
        { xPercent: 100 },
        { xPercent: 0, ease: Power2.easeInOut },
      )
      .fromTo(
        refOverlay.current,
        0.5,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Power2.easeInOut },
        0,
      )
      .staggerFromTo(
        refCart.current.children,
        0.6,
        { autoAlpha: 0, y: -2 },
        { autoAlpha: 1, y: 0, ease: Power2.easeInOut },
        0.08,
        '-=.12',
      )
  }, [])

  if (visible && !animationPlaying) {
    setAnimationPlaying(true)
    tl.play()
  } else if (!visible && animationPlaying) {
    setAnimationPlaying(false)
    tl.reverse(0)
  }

  useEffect(() => {
    if (items.length == 0) {
      setFirstLineText('')
      setShow(false)
    }
  }, [items])
  let totalSum: any
  let cartTotal = items.map((item) => {
    return item.variant.price.amount * item.quantity
  })

  totalSum = cartTotal.reduce(function (acc, val) {
    return acc + val
  }, 0)

  let cartValue = (90 - totalSum).toFixed(2)

  //   const freeGiftLimit = 60

  //   const handleAddFreeItem = async () => {
  //     if (freeGift) return
  //     else {
  //       await addItemToCart('gid://shopify/ProductVariant/50554377109853', 1, [
  //         {
  //           key: 'free',
  //           value: 'true',
  //         },
  //       ])
  //     }
  //   }
  //   const handleRemoveFreeItem = async () => {
  //     setFreeGift(false)
  //     console.log('called')
  // await removeItemFromCart(
  //   'gid://shopify/ProductVariant/50554377109853',
  //   null,
  // )
  // await updateItemQuantity('gid://shopify/ProductVariant/50554377109853', 0)
  // console.log('reoved');
  //   }
  //   console.log(freeGift)
  //   console.log(totalSum)

  //   const isFreeGiftExist = items.find(
  //     ({ variant }) =>
  //       variant.id === 'gid://shopify/ProductVariant/50554377109853',
  //   )
  //   useEffect(() => {
  //     if (totalSum) {
  //       console.log('isFreeGiftExist', isFreeGiftExist)

  //       if (!isFreeGiftExist) {
  //         if (totalSum > freeGiftLimit && freeGift === false) {
  //           setFreeGift(true)
  //           handleAddFreeItem()
  //         }
  //       } else {
  //         if (totalSum < freeGiftLimit) {
  //           handleRemoveFreeItem()
  //         }
  //       }
  //     }
  //   }, [totalSum])

  return (
    <div>
      <Overlay ref={refOverlay} onClick={() => toggleCart()} />
      <StyledCart ref={refCart} aria-label='Products cart' loading={loading}>
        <Close onClick={() => toggleCart()}>Fermer</Close>
        {
          <OfferTitle>
            {totalSum == 0
              ? 'Livraison offerte à partir de 90€ d’achat'
              : totalSum >= 90
              ? `Félicitations, vous avez atteint le montant de commande pour bénéficier du Tablier Maille rose offert`
              : `Plus que ${cartValue} € pour bénéficier de la livraison offerte `}{' '}
          </OfferTitle>
        }
        <OuterDiv>
          <CartWrapper>
            <Title>Votre Panier</Title>
            <Wrapper>
              {items && items.length >= 1 ? (
                <div>
                  <ul>
                    {items.map((item, i) => (
                      <li key={i}>
                        {item && !(item.variant === null) && (
                          <>
                            <LineItem
                              item={item}
                              items={items}
                              setLoading={setLoading}
                            />
                            {i !== items.length - 1 && <Line />}
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                'Pas d’article dans votre panier'
              )}
            </Wrapper>
          </CartWrapper>
          <GiftMessage>
            {items && items.length >= 1 ? (
              showMessage ? (
                <Form>
                  <input onChange={Toggle} type='checkbox' id='deskMessage' />
                  <label for='deskMessage'>
                    <RichText json={giftMessageHeadingText} />
                  </label>
                </Form>
              ) : null
            ) : null}

            {show ? (
              <TextInputWrapper>
                <TextInput>
                  <Input
                    onChange={(e) => setFirstLineText(e.target.value)}
                    placeholder={messagePlaceholder}
                    maxLength={mockData.characterLimit}
                    defaultValue={''}
                    id='firstLineInput'
                    pattern="[A-Za-zÀ-ž0-9\s'-]+"
                    required={true}
                    autoComplete='off'
                  />
                  <CharacterLimit>
                    {firstLineText.length + ' / ' + mockData.characterLimit}
                  </CharacterLimit>
                </TextInput>
                <TextInput>{giftMessageForUser}</TextInput>
              </TextInputWrapper>
            ) : null}
          </GiftMessage>
        </OuterDiv>

        <ButtonWrapper>
          <a
            onClick={(e) => useBothAnalyticsAndAttributes(e, webUrl)}
            alt='checkout'
          >
            <StyledButton
              onClick={() =>
                useCartCheckoutAnalytics(cart.lineItems, firstLineText)
              }
              text='procéder au paiement'
            />
          </a>
          <StyledGreyButton
            onClick={() => toggleCart()}
            background={colors.mediumGrey}
            text='continuer mes achats'
          />
        </ButtonWrapper>
        {loading ? (
          <LoadingOverlay>
            <Title>Chargement...Veuillez patienter</Title>
          </LoadingOverlay>
        ) : null}
      </StyledCart>
    </div>
  )
}

const StyledCart = styled.section`
  background: ${colors.white};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  padding: ${mobileVw(28)} ${mobileVw(16)};
  height: 100vh;
  /* overflow: ${(props) => (!props.loading ? 'initial' : 'scroll')}; */
  overflow: ${(props) => (props.loading ? 'initial' : 'scroll')};
  display: block;

  visibility: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(414)};
    padding: ${desktopVw(30)};
    min-height: 100vh;
    overflow: auto;
  }

  ul,
  ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
  }
`

const Close = styled.button`
  color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(16)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(28)} auto;
  display: block;
  padding: 0;
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    letter-spacing: ${letterSpacing(100)};
    line-height: ${desktopVw(16)};
    font-size: ${desktopVw(18)};
    margin: 0 0 ${desktopVw(50)} auto;
  }
`

const Wrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    max-height: calc(100vh - ${desktopVw(300)});
    overflow-y: scroll;
  }
`
const OfferTitle = styled.h3`
  font-size: ${mobileVw(20)};
  color: ${colors.mailleGold};
  line-height: ${mobileVw(17)};
  margin: ${mobileVw(-20)} 0 ${mobileVw(15)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(25)};
    line-height: ${desktopVw(23)};
    margin: ${desktopVw(-30)} 0 ${desktopVw(20)} 0;
  }
`
const OuterDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${mobileVw(20)};
  ${deskBp} {
    flex-direction: column;
    margin-bottom: 0;
  }
`
const CartWrapper = styled.div``
const GiftMessage = styled.div`
margin-bottom: ${mobileVw(20)};
${deskBp}{
  margin-bottom: 0;
`
const Title = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(25)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(34)};
    line-height: ${desktopVw(32)};
    margin: 0 0 ${desktopVw(20)} 0;
  }
`
const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${newMobileVw(10)} 0;
  input {
    width: ${newMobileVw(14)};
    height: ${newMobileVw(14)};
    margin-right: ${newMobileVw(5)};
  }
  label {
    font-size: ${mobileVw(17)};
  }
  ${deskBp} {
    margin: ${newDesktopVw(16)} 0 ${newDesktopVw(0)};
    input {
      width: ${newDesktopVw(20)};
      height: ${newDesktopVw(20)};
      margin-right: ${newDesktopVw(5)};
    }
    label {
      font-size: ${desktopVw(20)};
    }
  }
`

const TextInputWrapper = styled.div`
  margin: ${newMobileVw(10)} 0;
  ${deskBp} {
    margin: ${newDesktopVw(16)} 0;
  }
`

const TextInput = styled.div`
  position: relative;
  font-size: ${mobileVw(15)};
  margin-top: ${newMobileVw(12)};
  ${deskBp} {
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(23)};
    margin-top: ${newDesktopVw(4)};
  }
`

const Input = styled.textarea`
  width: 100%;
  min-height: ${newMobileVw(20)};
  padding: ${newMobileVw(15)};
  font-size: ${newMobileVw(16)};
  line-height: ${newMobileVw(18)};
  box-sizing: border-box;
  border: none;
  border: ${newMobileVw(1)} solid ${colors.mailleGold};
  border-radius: 0;
  outline: none;
  position: relative;
  font-family: ${fonts.mrs};
  ::placeholder {
    color: ${colors.darkGrey};
  }
  ${deskBp} {
    min-height: ${desktopVw(20)};
    border: ${desktopVw(1)} solid ${colors.mailleGold};
    font-size: ${desktopVw(20)};
    line-height: ${desktopVw(22)};
    padding: ${desktopVw(10)} ${desktopVw(15)};
  }
`

const CharacterLimit = styled.div`
  position: absolute;
  right: ${newMobileVw(3)};
  bottom: ${newMobileVw(5)};
  font-size: ${newMobileVw(16)};
  line-height: ${newMobileVw(19)};
  ${deskBp} {
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(19)};
    right: ${desktopVw(10)};
    bottom: ${desktopVw(5)};
  }
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
`

const ButtonWrapper = styled.div``

const StyledButton = styled(Button)`
  margin: ${mobileVw(16)} 0 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(16)} 0 0 0;
  }
`

const StyledGreyButton = styled(Button)`
  background: ${colors.lightGrey};
  border: none;
  color: ${colors.black};
  margin: ${mobileVw(16)} 0 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(16)} 0 0 0;
  }
`

const Line = styled.div`
  width: 100%;
  height: ${mobileVw(1)};
  background: ${colors.mediumGrey};
  margin: ${mobileVw(16)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(1)};
    margin: ${desktopVw(30)} 0;
  }
`

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  h2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    color: #fff;
    text-align: center;
  }
`

const NoTransform = styled.div`
  transform: none !important;
`

export default Cart
