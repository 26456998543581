import { useContext } from 'react'
import { StoreContext } from '../../contexts'

export function useToggleWishlist() {
  const {
    layout: { wishlistIsOpen },
    setLayout,
  } = useContext(StoreContext)

  async function toggleWishlist() {
    setLayout((prevState) => {
      return { ...prevState, wishlistIsOpen: !wishlistIsOpen }
    })
  }

  return toggleWishlist
}
