import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LinkType } from '../shared'
import { Heart, HeartFilled } from './icons'
import RichText from './RichText'
import { Button } from './Button'
import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  colors,
  fonts,
  letterSpacing,
} from '../../styles'
import { Image } from '../shared/Image'
import {
  useWishlistRecipes,
  useLayout,
  useToggleWishlist,
  useRecipeAnalytics,
  useLang,
  useLocale,
} from '../../hooks'

export const RecipeCard = ({ slug, thumbnailImage, title, wishlistGrid, cta, recipeLanding }) => {

  const locale = useLocale();

  const lang = useLang();
  const [onWishlist, setOnWishlist] = useState(false)
  const { wishlistRecipes } = useLayout()
  const setWishlistRecipes = useWishlistRecipes()
  const toggleWishlist = useToggleWishlist()
  useEffect(() => {
    const currentWishlistRecipes =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_recipes')
    if (currentWishlistRecipes) {
      const localWishlist = JSON.parse(currentWishlistRecipes)
      if (slug in localWishlist) {
        setOnWishlist(true)
      } else {
        !wishlistGrid && setOnWishlist(false)
      }
    }
  }, [wishlistRecipes])
  const handleAddToWishlist = () => {
    const recipe = {
      slug,
      thumbnailImage,
      title,
    }
    const currentWishlistRecipes =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_recipes')
    if (currentWishlistRecipes) {
      const localWishlist = JSON.parse(currentWishlistRecipes)
      if (slug in localWishlist) {
      } else {
        localWishlist[recipe.slug] = { ...recipe }
        localStorage.setItem('wishlist_recipes', JSON.stringify(localWishlist))
        setWishlistRecipes(localWishlist)
      }
    } else {
      const localWishlist = {}
      localWishlist[recipe.slug] = { ...recipe }
      localStorage.setItem('wishlist_recipes', JSON.stringify(localWishlist))
      setWishlistRecipes(localWishlist)
    }
  }

  const handleRemoveFromWishlist = () => {
    const recipe = {
      slug,
      thumbnailImage,
      title,
    }
    const currentWishlistRecipes =
      typeof window !== 'undefined' && localStorage.getItem('wishlist_recipes')
    const localWishlist = JSON.parse(currentWishlistRecipes)
    if (slug in localWishlist) {
      delete localWishlist[recipe.slug]
      localStorage.setItem('wishlist_recipes', JSON.stringify(localWishlist))
      setWishlistRecipes(localWishlist)
    }
  }

  return wishlistGrid ? (
    onWishlist && (
      <div onClick={() => (slug ? useRecipeAnalytics(title) : null)}>
        <RecipeWrapper className='wishlist-grid'>
          {!!slug ? (
            <LinkType to={!!slug && `/${lang}${slug}`}>
              <StyledImage
                className='image-wrapper'
                fluid={thumbnailImage && thumbnailImage.fluid}
                alt={thumbnailImage && thumbnailImage.title}
              />
            </LinkType>
          ) : (
            <StyledImage
              className='image-wrapper'
              fluid={thumbnailImage && thumbnailImage.fluid}
              alt={thumbnailImage && thumbnailImage.title}
            />
          )}
          <RecipeTitle>{title}</RecipeTitle>
          <Remove
            onClick={(e) => {
              e.preventDefault()
              handleRemoveFromWishlist()
              setOnWishlist(false)
            }}
          >
            Retirer
          </Remove>
        </RecipeWrapper>
      </div>
    )
  ) : (
    <div onClick={() => (slug ? useRecipeAnalytics(title) : null)}>
      <RecipeWrapper>
        {!!slug ? (
          <LinkType to={!!slug && `/${lang}${slug}`}>
            <StyledImage
              className='image-wrapper'
              fluid={thumbnailImage.fluid}
              alt={thumbnailImage.title}
            />
          </LinkType>
        ) : (
          <StyledImage
            className='image-wrapper'
            fluid={thumbnailImage && thumbnailImage.fluid}
            alt={thumbnailImage && thumbnailImage.title}
          />
        )}
        <RecipeTitle>{title}</RecipeTitle>
        { cta && 
        <RecipeButton 
          className='cta_button'
          to={`/fr${slug}`}
          text={cta}>
        </RecipeButton>}

        {
          locale === 'fr' &&
          <AddToWishlist
            onClick={(e) => {
              e.preventDefault()
              if (!onWishlist) {
                handleAddToWishlist()
                setOnWishlist(true)
              } else {
                handleRemoveFromWishlist()
                setOnWishlist(false)
              }
            }}
            recipeLanding = {recipeLanding}
          >
            {onWishlist ? <StyledHeartFilled /> : <StyledHeart />}
          </AddToWishlist>

        }
      </RecipeWrapper>
    </div>
  )
}

const RecipeWrapper = styled.div`
  margin: 0 0 ${mobileVw(30)};
  &.wishlist-grid {
    margin: 0;
  }
  display: block;
  position: relative;
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
  }
`

const StyledImage = styled(Image)`
  height: ${mobileVw(264)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(216)};
  }
`

const RecipeTitle = styled.h2`
  font-size: ${mobileVw(18)};
  line-height: ${mobileVw(18)};
  font-family: ${fonts.splandor};
  margin: ${mobileVw(18)} 0 ${mobileVw(8)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(24)};
    line-height: ${desktopVw(24)};
    margin: ${desktopVw(22)} 0 ${desktopVw(10)} 0;
  }
`
const RecipeDecs = styled.p`
font-size: ${mobileVw(16)};
line-height: ${mobileVw(18)};
font-family: ${fonts.splandor};
margin: ${mobileVw(18)} 0 ${mobileVw(8)} 0;
@media (min-width: ${desktopBreakpoint}) {
  font-size: ${desktopVw(20)};
  line-height: ${desktopVw(24)};
  margin: ${desktopVw(22)} 0 ${desktopVw(10)} 0;
}
`
const RecipeButton = styled(Button)`
  width: auto;
  background: transparent;
  margin:  ${mobileVw(16)};
  padding: ${mobileVw(5)} 0;
  display: block;
  color: #AE841F;
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(250)};
    font-size: ${desktopVw(20)};
    margin: ${desktopVw(20)} auto;
    padding: ${desktopVw(10)} 0;
  }
`



const AddToWishlist = styled.button`
  display:"block";
  cursor: pointer;
  position: absolute;
  top: ${mobileVw(15)};
  right: ${mobileVw(15)};
  padding: 0;
  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVw(17)};
    right: ${desktopVw(16)};
  }
`

const StyledHeart = styled(Heart)`
  fill: ${colors.mailleGold};
  width: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(25)};
    height: auto;
  }
`

const StyledHeartFilled = styled(HeartFilled)`
  fill: ${colors.mailleGold};
  width: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(25)};
    height: auto;
  }
`

const Remove = styled.button`
  display: block;
  margin: ${mobileVw(14)} 0 0 0;
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  border-bottom: ${mobileVw(1)} solid ${colors.black};
  padding: 0;
  cursor: pointer;
  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(13)} 0 0 0;
    font-size: ${desktopVw(16)};
    border-bottom: ${desktopVw(1)} solid ${colors.black};
  }
`
