import React from "react";

export const Check = props => {
  return (
    <svg width={16.707} height={11.199} viewBox="0 0 16.707 11.199" {...props}>
      <path
        data-name="Path 532"
        d="M.354 4.631l5.861 5.861L16.354.353"
        fill="none"
        stroke="#ae841f"
        strokeMiterlimit={10}
      />
    </svg>
  );
};
