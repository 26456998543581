export const useMaiilleSchemaForLD = (
    mode,
    data
) => {

    switch (mode) {
        case 'product':
            return {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": data.productName ? data.productName : '',
                "image": data.image ? data.image : '',
                "description": data.description ? data.description : '',
                "sku": data.sku ? data.sku : '',
                "brand": data.brand ? {
                    "@type": 'Brand',
                    "name": data.brand
                } : '',
                "offers": {
                    "@type": "Offer",
                    price: data.price ? data.price : '',
                    priceCurrency: 'EUR',
                    priceValidUntil: new Date().getFullYear() + 1, //not sure, sorry if you read this
                    //itemCondition: 'new',
                    availability: 'InStock',
                    url: data.url ? data.url : '',
                    seller: {
                        name: 'Maille',
                    },
                    "shippingDetails": {
                        "@type": "OfferShippingDetails",
                        "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": 8.90,
                            "currency": "EUR"
                        },
                        "shippingDestination": {
                            "@type": "DefinedRegion",
                            "addressCountry": "FR"
                        },
                        "deliveryTime": {
                            "@type": "ShippingDeliveryTime",
                            "handlingTime": {
                                "@type": "QuantitativeValue",
                                "minValue": 0,
                                "maxValue": 5,
                                "unitCode": "DAY"
                            },
                            "transitTime": {
                                "@type": "QuantitativeValue",
                                "minValue": 1,
                                "maxValue": 5,
                                "unitCode": "DAY"
                            }
                        }
                    }
                }
            }
        case 'website':
            return {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": data.siteName ? data.siteName : '',
                "url": data.url ? data.url : '',
                "sameAs": data.sameAs ? data.sameAs.map(sameAs => sameAs) : '',
            }
        case 'article':
            return {
                "@context": "https://schema.org",
                "@type": "Article",
                "headline": data.title ? data.title : '',
                "alternativeHeadline": data.subtitle ? data.subtitle : '',
                "image": data.image ? data.image : '',
                "author": data.author ? data.author : '',
                "award": data.award ? data.award : '',
                "genre": data.genre ? data.genre : '',
                "publisher": {
                    "@type": "Organization",
                    "name": "Maille",
                },
                "url": data.url ? data.url : '',
                "datePublished": data.date,
                "dateCreated": data.date,
                "dateModified": data.date,
                "description": data.description ? data.description : '',
                "articleBody": data.content ? data.content : ''
            }
        case 'recipe':
            return {
                "@context": "https://schema.org/",
                "@type": "Recipe",
                "name": data.title ? data.title : "",
                "image": data.images ? data.images.map(link => link) : '',
                "author": {
                    "@type": "Person",
                    "name": "Maille"
                },
                "datePublished": data.date ? data.date : '',
                "description": data.description ? data.description : '',
                "prepTime": data.prepTime ? thisIsAnUtterlyStupidImplementationButAmRunningOutOfTime(data.prepTime) : '',
                "cookTime": data.cookTime ? thisIsAnUtterlyStupidImplementationButAmRunningOutOfTime(data.cookTime) : '',
                "totalTime": data.totalTime ? thisIsAnUtterlyStupidImplementationButAmRunningOutOfTime(data.totalTime) : '',
                "keywords": data.keyWords ? data.keywords : '',
                "recipeYield": data.numberOfServing ? `${data.numberOfServing}` : '',
                "recipeCategory": data.recipeCategory ? data.recipeCategory : '',
                "recipeCuisine": data.recipeOrigin ? data.recipeOrigin : '',
                "nutrition": {
                    "@type": "NutritionInformation",
                    "calories": data.calories ? data.calories : ''
                },
                "recipeIngredient": data.ingredients ? data.ingredients.map(ing => ing) : '',
                "recipeInstructions": data.instructions ? data.instructions.map(({
                    step,
                    url,
                    img
                }, index) => ({
                    "@type": "HowToStep",
                    "name": `Step ${index + 1}`,
                    "text": step ? step : '',
                    "url": url ? url : '',
                    "image": img ? img : ''
                })) : '',
            }
    }
}
const thisIsAnUtterlyStupidImplementationButAmRunningOutOfTime = time => {
    return `PT${Number(time.replace(/\D/g, ""))}M`
}