import React from 'react'
import styled from 'styled-components'

export const LevelOfDifficulty3 = props => {
  return (
    <StyledSvg viewBox='0 0 24.913 20.192' {...props}>
      <g data-name='Group 1517' fill={props.color}>
        <path d='M12.708.352l-1.288 2.61-2.881.42a.631.631 0 00-.349 1.077l2.084 2.031-.493 2.869a.631.631 0 00.915.664l2.577-1.354 2.577 1.354a.631.631 0 00.915-.664l-.493-2.869 2.084-2.031a.631.631 0 00-.349-1.077l-2.881-.42-1.287-2.61a.631.631 0 00-1.131 0z' />
        <path
          data-name='star'
          d='M4.708 10.447l-1.288 2.61-2.881.42a.631.631 0 00-.349 1.077l2.084 2.031-.493 2.869a.631.631 0 00.915.664l2.577-1.354 2.577 1.354a.631.631 0 00.915-.664l-.493-2.869 2.084-2.031a.631.631 0 00-.349-1.077l-2.881-.42-1.287-2.61a.631.631 0 00-1.131 0zm14.365 0l-1.288 2.61-2.881.42a.631.631 0 00-.349 1.077l2.084 2.031-.493 2.869a.631.631 0 00.915.664l2.577-1.354 2.577 1.354a.631.631 0 00.915-.664l-.493-2.869 2.084-2.031a.631.631 0 00-.349-1.077l-2.881-.42-1.287-2.61a.631.631 0 00-1.131 0z'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
