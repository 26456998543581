import React, { useState, useEffect } from 'react'
import  ReactDOM  from 'react-dom'
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVw, mobileVw } from '../../styles';
import { Button } from '../shared';

const PopUp = ({setShow}) =>{

    return(
        ReactDOM.createPortal(
        <Container>
        <ContainerBackground  />
        <PopupBody>
            <PopupClose onClick={()=> setShow(false)}>&#10005;</PopupClose>
            <PopupTitle>
                <p>Importhaus Wilms / Impuls GmbH & Co. KG</p>
                <p>
                    <span> Am Klingenweg 6A</span><br />
                    <span> 65396 Walluf</span>
                </p>
                <p>
                    <span>Tel. 06123-9990-0</span><br />
                    <span>Fax 06123-9990-255</span><br />
                    <span>eMail: info@importhaus-wilms.de</span>
                </p>
            </PopupTitle>
        </PopupBody>
        </Container>
    , document.body)
    )
}
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  display: block;
 
`

const ContainerBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background: ${colors.black};
  opacity: 0.5;
`

const PopupBody = styled.div`
  position: relative;
  inset: 15%;
  width: 70%;
  padding: ${mobileVw(50)} ${mobileVw(35)};
  background: ${colors.white};

  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(450)};
    padding: ${desktopVw(50)} ${desktopVw(70)};
    inset: 33%;
  }

`

const PopupClose = styled.button`
  position: absolute;
    top: 28px;
    right: 28px;

`
const PopupTitle = styled.div`
  h1,h2,h4,h5,h6{
    padding: 10px;
  }
  color: ${colors.black};
  p{
    font-weight: 700;
  }
  span{
    color: ${colors.black};
    font-weight: 500;
  }

`
const PopupDescription = styled(Button)`
`

const PopupButton = styled.div`
margin-top:${mobileVw(25)};
@media (min-width: ${desktopBreakpoint}){
  margin-top:${desktopVw(25)};
}
`
export default PopUp