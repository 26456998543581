import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { useDictionaryQuery } from '../../hooks'
import { colors, desktopBreakpoint, desktopVw, fonts, mobileVw } from '../../styles'
import { Decoration } from '../shared'
import { Button } from '../shared/Button'

const NewsletterMailchimp = ({
  decoration,
  newsletterTitle,
  newsletterDescription,
  newsletterCheckbox,
  newsletterSubmit,
  newsletterButton,
  footer
}) => {
  const [checkbox, setCheckbox] = useState(false)
  const [load, setLoad] = useState(false)

  const { globalNewsletterIndicatesRequried, globalNewsletterButton, globalNewsletterTitle, globalNewsletterDescription, globalNewsletterEmailAddress, globalNewsletterName } = useDictionaryQuery()
  const title = newsletterTitle ? newsletterTitle.split(',') : globalNewsletterTitle.split(',')
  let script;
  useEffect(() => {
    script = document.createElement('script')
    script.src =
      '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'
    script.async = true
    script.defer = true
    // return () => {
    //   document.body.removeChild(script)
    // }

  }, [load])
  if (load) {
    setTimeout(() => {
      document.body.appendChild(script)
    }, 0)
  }

  // const a = document.getElementByClassName("emailFormCheckBox").values
  const b = (e) => {
    setCheckbox(e.target.checked)
  }
  return (
    <>
      <Helmet>
        {
          load &&
          <link href="//cdn-images.mailchimp.com/embedcode/classNameic-10_7.css" rel="stylesheet" type="text/css" />
        }
      </Helmet>
      {/* <style type="text/css">
        #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
      </style> */}
      <StyledNewsletter display={!!footer} onClick={() => { setLoad(true) }}>
        <Wrapper id="mc_embed_signup" display={footer && true}>
          <form action={process.env.MAILCHIMP_NEWSLETTER} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">

              <StyledTitle>
                <span>{title[0]}</span>{title[1]}
              </StyledTitle>

              <Description>
                {newsletterDescription ? newsletterDescription : globalNewsletterDescription}
              </Description>
              <Warning className="indicates-required"><span className="asterisk">*</span> {globalNewsletterIndicatesRequried}</Warning>
              <div className="mc-field-group">
                {/* <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                </label> */}
                <Input type="email" placeholder={globalNewsletterEmailAddress ? `${globalNewsletterEmailAddress} *` : `Email Address *`} name="EMAIL" className="required email" id="mce-EMAIL" />
              </div>
              <div className="mc-field-group">
                {/* <label htmlFor="mce-FNAME">Name </label> */}
                <Input type="text" placeholder={globalNewsletterName ? `${globalNewsletterName} *` : `Name *`} name="FNAME" className="" id="mce-FNAME" />
              </div>
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
              </div>
              {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
              <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                <Input type="text" name="b_9b2c843944754b71ffcc43404_299270bc4c" tabIndex="-1" value="" />
              </div>
              <div className="clear">
                <Button type="submit" text={newsletterButton ? newsletterButton : globalNewsletterButton} value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
              </div>

            </div>
          </form>
        </Wrapper>
        {
          decoration &&
          decoration.map((data, i) => {
            return <Decoration key={i} data={data} />
          })
        }

      </StyledNewsletter>
      {
        load ?
          <script type='text/javascript' defer async dangerouslySetInnerHTML={{
            __html: `
          (function ($) { window.fnames = new Array(); window.ftypes = new Array(); fnames[0] = 'EMAIL'; ftypes[0] = 'email'; fnames[1] = 'FNAME'; ftypes[1] = 'text'; fnames[3] = 'ADDRESS'; ftypes[3] = 'address'; fnames[4] = 'PHONE'; ftypes[4] = 'phone'; }(jQuery)); var $mcj = jQuery.noConflict(true)
          `
          }}></script>
          : null
      }
      {/* <!--End mc_embed_signup--> */}
      {/* // </link> */}
      {/* // <StyledNewsletter>
    //   <Mailchimp
    //     action="https://eleves-alsacienne.us20.list-manage.com/subscribe/post?u=9b2c843944754b71ffcc43404&amp;id=299270bc4c"
    //     className="chimp forms"
    //     fields={[
    //       {
    //         name: "EMAIL",
    //         placeholder: 'Email',
    //         type: 'email',
    //         required: true
    //       },
    //       {
    //         name: "FNAME",
    //         placeholder: 'First Name',
    //         type: 'text',
    //         required: true
    //       },
    //     ]}
    //   />
    // </StyledNewsletter> */}
    </>

  )
}

const StyledNewsletter = styled.div`
  text-align: center;
  color: #fff;
  background: #000;
  position: relative;
  padding: ${mobileVw(50)} ${mobileVw(16)} ${mobileVw(175)};
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${(props) => (props.display ? 'unset' : `${desktopVw(50)} 0`)};
    overflow: hidden;
    display: block;
  }

  
  .mce_inline_error{
    font-family: ${fonts.mrs};
    line-height: ${mobileVw(20)};
    margin: 0;
    font-size: ${mobileVw(12)};
  
    @media (min-width: ${desktopBreakpoint}) {
      line-height: ${desktopVw(24)};
      margin: 0 0 ${desktopVw(25)} 0;
      font-size: ${desktopVw(15)};
    }
  }
  .response{
    font-family: ${fonts.mrs};
    line-height: ${mobileVw(20)};
    margin: 0;
    font-size: ${mobileVw(12)};
  
    @media (min-width: ${desktopBreakpoint}) {
      line-height: ${desktopVw(24)};
      margin: 0 0 ${desktopVw(25)} 0;
      font-size: ${desktopVw(15)};
    }
  }
  
`
const NewsletterButton = styled.input`
  display:${(props) => (props.display ? 'unset' : 'none')};
`

const StyledCheckbox = styled.input`
  background: transparent;
  border-radius: 0;
  display: block;
  margin: 0 ${mobileVw(7)} 0 0;
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};
  border: ${mobileVw(0.5)} solid #fff;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(12)} 0 0;
    width: ${desktopVw(16)};
    height: ${desktopVw(16)};
    border: ${desktopVw(0.5)} solid #fff;
  }
`

const Statement = styled.div`
  margin: 0 0 ${mobileVw(12)} 0;
  text-align: left;
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
  font-size: ${mobileVw(12)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(20)} 0;
    font-size: ${desktopVw(14)};
  }
`

const Wrapper = styled.div`
width: auto;
margin: 0 auto;
z-index:1;
position:relative;

@media (min-width: ${desktopBreakpoint}) {
  width: ${(props) => (props.display ? 'unset' : desktopVw(350))};
}
`

const StyledTitle = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(40)};
  line-height: ${mobileVw(32)};
  margin: 0 0 ${mobileVw(15)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(40)};
    margin: 0 0 ${desktopVw(12)};
  }

  span {
    display: inline-block;

    &:first-child {
      color: ${colors.mailleGold};
    }
  }
`
const Description = styled.div`
  font-family: ${fonts.mrs};
  line-height: ${mobileVw(20)};
  margin: 0 0 ${mobileVw(16)} 0;
  font-size: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(24)};
    margin: 0;
    font-size: ${desktopVw(18)};
  }
`
const Warning = styled.div`
  font-family: ${fonts.mrs};
  line-height: ${mobileVw(20)};
  margin: 0 0 ${mobileVw(16)} 0;
  font-size: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(25)} 0;
    font-size: ${desktopVw(18)};
  }
`

const Input = styled.input`
  width: 100%;
  border: 0.5px solid #fff;
  color: #fff;
  background: transparent;
  font-size: ${mobileVw(16)};
  padding: ${mobileVw(11)} ${mobileVw(15)};
  margin: 0 0 ${mobileVw(17)} 0;
  font-family: 'MrsEaves';
  outline: none;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(16)};
    padding: ${desktopVw(15)} ${desktopVw(24)};
    margin: 0 0 ${desktopVw(17)} 0;
    border: ${desktopVw(0.5)} solid #fff;
  }

  &::placeholder {
    color: #fff;
    font-family: 'MrsEaves';
  }
`

// const StyledButton = styled.input`
// padding: 1.1713vw 0vw 0.878477vw;
//     font-size: 1.31772vw;
//     line-height: 1.1713vw;
//     border: 1px solid rgb(174, 132, 31);
// }

// `

export default NewsletterMailchimp;