import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

export const Image = ({ fluid, backgroundColor, className, alt }) => {
  const [loaded, setLoaded] = useState(false)
  const background = backgroundColor || '#00000'

  return (
    <Wrapper className={className}>
      <StyledImage
        backgroundColor={background}
        scale={loaded}
        fluid={fluid}
        alt={alt}
        onLoad={() => setLoaded(true)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  transform: ${(props) => (props.scale ? 'scale(1)' : 'scale(1.05)')};
  transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1);
`
