import React from 'react'
import styled from 'styled-components'
import { mobileVw, desktopVw, desktopBreakpoint } from '../../../styles'

export const Bag = props => {
  return (
    <StyledSvg
      xmlns='http://www.w3.org/2000/StyledSvg'
      viewBox='0 0 15 20.071'
      {...props}
    >
      <g
        fill='none'
        stroke={props.color || props.fill || '#ba9b37'}
        strokeMiterlimit='10'
        strokeWidth='1'
        transform='translate(.5 .5)'
      >
        <path
          d='M0 0H14V14.479H0z'
          data-name='Rectangle 12'
          transform='translate(0 4.592)'
        ></path>
        <g transform='translate(4.653 2.304)'>
          <path d='M0 0L0 4.166' data-name='Line 11'></path>
          <path
            d='M0 0L0 4.166'
            data-name='Line 12'
            transform='translate(4.694)'
          ></path>
        </g>
        <path
          d='M4.653 2.346A2.347 2.347 0 017-.001a2.347 2.347 0 012.347 2.347'
          data-name='Path 48'
        ></path>
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  width: ${mobileVw(14)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(14)};
    &.sticky-nav-icon {
      width: ${desktopVw(12)};
    }
  }
`
