import { useContext } from 'react'
import { StoreContext } from '../../contexts'

export function useNewsletterInactive() {
  const {
    setLayout,
  } = useContext(StoreContext)

  async function newsletterInactive() {
    setLayout((prevState) => {
      return { ...prevState, newsletterInactiveOnVegan: true }
    })
  }

  return newsletterInactive
}
