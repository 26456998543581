import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import gsap, { Power2, Power4 } from 'gsap'
// import { Power3, TimelineMax } from 'gsap'
import { useToggleWishlist, useLayout } from '../../hooks'
import { ProductcardWishlist, RecipeCard } from '../shared'
import {
  letterSpacing,
  colors,
  fonts,
  desktopVw,
  desktopBreakpoint,
  mobileVw,
} from '../../styles'

const Wishlist = ({ visible, portalView }: { visible: boolean }) => {
  const toggleWishlist = useToggleWishlist()
  const { wishlistIsOpen } = useLayout()
  const [wishlistProducts, setWishlistProducts] = useState([])
  const [wishlistRecipes, setWishlistRecipes] = useState([])
  const [animationPlaying, setAnimationPlaying] = useState(false)

  const getWishlist = (type: string) => {
    const localWishList = localStorage.getItem(`wishlist_${type}`)
      ? JSON.parse(localStorage.getItem(`wishlist_${type}`))
      : {}
    const localValues = Object.values(localWishList)
      ? Object.values(localWishList)
      : []
    type === 'products'
      ? setWishlistProducts(localValues)
      : setWishlistRecipes(localValues)
  }
  useEffect(() => {
    getWishlist('products')
    getWishlist('recipes')
  }, [wishlistIsOpen])

  const refCart = useRef(null)
  const refOverlay = useRef(null)

  const [tl] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    if (!portalView) {
      tl.set(refCart.current, { autoAlpha: 1 })
        .fromTo(
          refCart.current,
          0.5,
          { xPercent: 100 },
          { xPercent: 0, ease: Power2.easeInOut },
        )
        .fromTo(
          refOverlay.current,
          0.5,
          { autoAlpha: 0 },
          { autoAlpha: 1, ease: Power2.easeInOut },
          0,
        )
        .staggerFromTo(
          refCart.current.children,
          0.6,
          { autoAlpha: 0, y: -2 },
          { autoAlpha: 1, y: 0, ease: Power2.easeInOut },
          0.1,
          '-=.12',
        )
    }
  }, [])

  if (!portalView && wishlistIsOpen && !animationPlaying) {
    setAnimationPlaying(true)
    tl.play()
  } else if (!portalView && !wishlistIsOpen && animationPlaying) {
    setAnimationPlaying(false)
    tl.reverse(0)
  }

  return (
    <div>
      {portalView ? (
        <PortalWishlist>
          <CategoryTitle>Produits</CategoryTitle>
          <Wrapper className='wishlist-products'>
            {wishlistProducts &&
              wishlistProducts.map((product, i) => (
                <ProductcardWishlist key={i} product={product} />
              ))}
          </Wrapper>
          <CategoryTitle className='wishlist-recipes'>Recettes</CategoryTitle>
          <Wrapper className='wishlist-recipes'>
            {wishlistRecipes &&
              wishlistRecipes.map((recipe, i) => (
                <RecipeCard
                  key={i}
                  slug={recipe.slug}
                  thumbnailImage={recipe.thumbnailImage}
                  title={recipe.title}
                  wishlistGrid
                />
              ))}
          </Wrapper>
        </PortalWishlist>
      ) : (
        <div>
          <Overlay ref={refOverlay} onClick={() => toggleWishlist()} />
          <StyledWishlist ref={refCart} aria-label='Wishlist'>
            <Close onClick={() => toggleWishlist()}>Fermer</Close>
            <Title>Mes Favoris</Title>
            <CategoryTitle>Produits</CategoryTitle>
            <Wrapper>
              {wishlistProducts &&
                wishlistProducts.map((product, i) => (
                  <ProductcardWishlist key={i} product={product} />
                ))}
            </Wrapper>
            <CategoryTitle className='wishlist-recipes'>Recettes</CategoryTitle>
            <Wrapper className='wishlist-recipes'>
              {wishlistRecipes &&
                wishlistRecipes.map((recipe, i) => (
                  <RecipeCard
                    key={i}
                    slug={recipe.slug}
                    thumbnailImage={recipe.thumbnailImage}
                    title={recipe.title}
                    wishlistGrid
                  />
                ))}
            </Wrapper>
          </StyledWishlist>
        </div>
      )}
    </div>
  )
}

const StyledWishlist = styled.section`
  background: ${colors.white};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  padding: ${mobileVw(28)} ${mobileVw(16)};
  height: 100vh;
  overflow: scroll;
  visibility: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(414)};
    padding: ${desktopVw(30)} ${desktopVw(14)} ${desktopVw(30)} ${desktopVw(30)};
    min-height: 100vh;
    overflow: auto;
  }

  ul,
  ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
  }
`

const Close = styled.button`
  color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(16)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  margin: 0 0 ${mobileVw(28)} auto;
  display: block;
  padding: 0;
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    letter-spacing: ${letterSpacing(100)};
    line-height: ${desktopVw(16)};
    font-size: ${desktopVw(18)};
    margin: 0 0 ${desktopVw(50)} auto;
  }
`

const CategoryTitle = styled.h2`
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(20)};
  line-height: ${mobileVw(20)};
  margin: 0 0 ${mobileVw(10)} 0;
  color: ${colors.mailleGold};
  text-transform: uppercase;
  letter-spacing: ${letterSpacing(100)};
  &.wishlist-recipes {
    margin-top: ${mobileVw(49)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(22)};
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(6)} 0;
    &.wishlist-recipes {
      margin-top: ${desktopVw(41)};
    }
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    row-gap: ${desktopVw(63)};
    &.wishlist-recipes {
      row-gap: ${desktopVw(16)};
    }
  }
`

const Title = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(23)};
  margin: 0 0 ${mobileVw(18)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(34)};
    line-height: ${desktopVw(32)};
    margin: 0 0 ${desktopVw(20)} 0;
  }
`

const PortalWishlist = styled.div``

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
`

export default Wishlist
