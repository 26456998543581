import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { Icon, RichText } from '../shared'
import logoMaille from '../../../static/image/logo.png'
import logoOne from '../../../static/image/logoOne.png'
import logoTwo from '../../../static/image/logoTwo.png'
// import { useLocale } from '../../hooks'
import {
  useToggleCart,
  useToggleMenu,
  useSiteQuery,
  useLayout,
  useLang,
  useLocale,
  useDictionaryQuery,
} from '../../hooks'
import StickyNav from './StickyNav'
import Aside from './Aside'
import NavLinks from './NavLinks'
import HeaderButtons from './headerButtons'
import SearchSuggestions from './searchSuggestions'
import {
  mobileVw,
  desktopVw,
  desktopBreakpoint,
  shortDskVw,
  shortMobVw,
  colors,
  fonts,
} from '../../styles'
import Cart from './Cart'
import Wishlist from './Wishlist'
import { lchmod } from 'fs'
import { LinkType } from '../shared'
const Header = () => {
  const locale = useLocale()
  const { cartIsOpen, wishlistIsOpen } = useLayout()

  const [openSearch, setOpenSearch] = useState(false)

  const { headerLinks, logo, uspBarText } = useSiteQuery()
  const {
    searchBarText,
    showGiftingMessage,
    messagePlaceholder,
    giftMessageForUser,
    giftMessageHeadingText,
  } = useDictionaryQuery()

  const toggleAside = useToggleMenu()

  const [query, updateQuery] = useState('')

  const handleInput = (e) => {
    updateQuery(e.target.value)
    setOpenSearch(true)
  }

  const lang = useLang()

  return (
    <>
      {locale === 'fr' && (
        <Banner className='mobileOnly'>
          {/* <LinkType to={`https://maille.com/fr/cadeaux-personnalisés/`} alt={`Ce lien va ouvrir une nouvelle fenêtre`} rel="noopener noreferrer">
            <p>-30% sur les coffrets prestiges</p>
          </LinkType> */}

          <p>LIVRAISON OFFERTE PARTIR À DE 90€ D'ACHATS</p>
          {/* <RichText json={uspBarText}/> */}
          {/* <p>-30% SUR LES COFFRETS PRESTIGES ET BOUCHON GRAVÉ OFFERT</p> */}
        </Banner>
      )}
      <MobileMenu className='mobileOnly'>
        {locale !== 'de' && (
          <MobileMenuButton onClick={toggleAside}>
            <Icon name='menu' />
          </MobileMenuButton>
        )}

        <Logo to={`/${lang}`}>
          {locale === 'fr' ? (
            <StyledImage src={logoOne} alt={logo.title} />
          ) : (
            <StyledImageCa fluid={logo.fluid} alt={logo.title} />
          )}
        </Logo>
        <HeaderButtons />
      </MobileMenu>
      <StyledHeader className='desktopOnly'>
        {locale === 'fr' && (
          <Banner>
            {/* <LinkType to={`https://maille.com/fr/cadeaux-personnalisés/`} alt={`Ce lien va ouvrir une nouvelle fenêtre`} rel="noopener noreferrer">
              <p>-30% sur les coffrets prestiges</p>
            </LinkType> */}
            <p>LIVRAISON OFFERTE PARTIR À DE 90€ D'ACHATS</p>
            {/* <p>-30% SUR LES COFFRETS PRESTIGES ET BOUCHON GRAVÉ OFFERT</p> */}
          </Banner>
        )}
        <Logo to={`/${lang}`}>
          <StyledImage src={logoMaille} alt={logo.title} />
        </Logo>
        <NavLinks />
        {locale !== 'de' && (
          <NavRight>
            <SearchBar animate={openSearch}>
              <button type='button'>
                <Icon name='search' />
              </button>
              <StyledInput
                // animate={openSearch}
                placeholder={searchBarText ? searchBarText : 'Rechercher'}
                onClick={() => setOpenSearch(true)}
                onChange={(e) => handleInput(e)}
              />
              {query && query.trim() && openSearch ? (
                <SearchSuggestions
                  handleClose={() => setOpenSearch(false)}
                  search={query}
                />
              ) : null}
              <Overlay
                display={openSearch}
                onClick={() => {
                  setOpenSearch(false)
                }}
              />
            </SearchBar>
            <HeaderButtons />
          </NavRight>
        )}
      </StyledHeader>
      <Aside logo={logo} mailleNewLogo={logoMaille} links={headerLinks} />
      {locale !== 'de' && (
        <StickyNav logo={logo} mailleNewLogo={logoMaille} links={headerLinks} />
      )}
      {/* <SubMenu /> */}
      {locale === 'fr' && (
        <>
          <Cart
            visible={cartIsOpen}
            showMessage={showGiftingMessage}
            messagePlaceholder={messagePlaceholder}
            giftMessageForUser={giftMessageForUser}
            giftMessageHeadingText={giftMessageHeadingText}
          />
          <Wishlist visible={wishlistIsOpen} />
        </>
      )}
    </>
  )
}

const StyledHeader = styled.header`
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    color: #fff;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: relative;
    height: auto;
    min-height: ${desktopVw(160)};
    padding: ${shortDskVw([55, 0, 0, 0])};
  }
`

const Banner = styled.div`
  width: 100vw;
  background-color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  text-align: center;
  text-transform: uppercase;
  padding: ${mobileVw(8)} 0;
  font-size: ${mobileVw(14)};
  letter-spacing: ${mobileVw(1)};
  line-height: ${mobileVw(16)};
  span,
  p {
    display: block;
    padding: 0 ${mobileVw(20)};
    margin: 0;
  }
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    width: 100vw;
    background-color: ${colors.mailleGold};
    font-family: ${fonts.tivoli};
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    font-size: ${desktopVw(14)};
    letter-spacing: ${desktopVw(1)};
    line-height: ${desktopVw(16)};
    padding: ${desktopVw(8)} 0;

    span,
    p {
      display: block;
      padding: 0 ${desktopVw(20)};
      margin: 0;
    }

    /* &::after {
      content: '';
      border-bottom: solid ${desktopVw(1)} ${colors.white};
      position: absolute;
      top: 50%;
      right: ${desktopVw(16.5)};
      width: ${desktopVw(22)};
    } */
  }
`

const Logo = styled(Link)`
  display: block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 auto;
    position: relative;
    width: ${desktopVw(150)};
    left: unset;
    transform: none;
    // image-rendering: pixelated;
  }
`

const StyledImage = styled.img`
  width: ${mobileVw(56)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(150)};
  }
`
const StyledImageCa = styled(Image)`
  width: ${mobileVw(56)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(120)};
  }
`

const MobileMenuButton = styled.button`
  padding: 0;
  width: 30px;
  height: 30px;
  fill: #fff;
  background: none;
  border: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const NavRight = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    right: ${desktopVw(35)};
    align-items: center;
    display: flex;
    top: ${desktopVw(65)};
    > button {
      padding: ${desktopVw(8)};
      border: 0;
      background: none;
      cursor: pointer;
    }
  }
`

// This should be a form
const SearchBar = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.8s ease;
  border-bottom: solid ${desktopVw(1)} ${colors.mailleGold};
  position: relative;
  z-index: 1;

  button {
    position: absolute;
    top: 0;
    left: ${desktopVw(-30)};
    cursor: pointer;
  }
`

const StyledInput = styled.input`
  @media (min-width: ${desktopBreakpoint}) {
    background-color: inherit;
    color: ${colors.white};
    font-size: ${desktopVw(14)};
    font-family: ${fonts.mrs};
    padding: ${desktopVw(3)};
    /* margin: 0 ${desktopVw(5)}; */
    border-style: none;
    width: ${desktopVw(200)};
    /* transition: width 0.7s ease; */
    z-index: 2;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${colors.white};
    }
  }
`

const Overlay = styled.div.withConfig({
  shouldForwardProp: (prop) => !['display'].includes(prop),
})`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  display: ${(props) => (props.display ? 'block' : 'none')};
`

const MobileMenu = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  color: #fff;
  z-index: 99;
  padding: ${shortMobVw([12, 16])};
  height: ${mobileVw(80)};
`

export default Header
