import React from 'react'
import styled from 'styled-components'

export const ArrowUp = props => {
  return (
    <StyledSvg
      width={14.06}
      height={15.707}
      viewBox='0 0 14.06 15.707'
      {...props}
    >
      <g
        data-name='Group 32'
        fill='none'
        stroke='#AE841F'
        strokeMiterlimit={10}
      >
        <path data-name='Path 45' d='M.354 7.383L7.031.706l6.677 6.677' />
        <path data-name='Line 2' d='M7.03.707v15' />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
