import React from 'react'
import styled from 'styled-components'

export const Tip = props => {
  return (
    <StyledSvg width={128} height={26.385} viewBox='0 0 128 26.385' {...props}>
      <defs>
        <linearGradient
          id='prefix__b'
          x1={-0.906}
          y1={17.11}
          x2={-0.9}
          y2={17.11}
          gradientUnits='objectBoundingBox'
        >
          <stop offset={0} stopColor='#e3c485' />
          <stop offset={0.006} stopColor='#e3c485' />
          <stop offset={0.082} stopColor='#d7b473' />
          <stop offset={0.208} stopColor='#c0944f' />
          <stop offset={0.41} stopColor='#fccd8a' />
          <stop offset={0.73} stopColor='#c59e5e' />
          <stop offset={0.87} stopColor='#a98141' />
          <stop offset={1} stopColor='#c59e5e' />
        </linearGradient>
        <clipPath id='prefix__a'>
          <path
            data-name='Path 443'
            d='M182.711 294.473a8.728 8.728 0 01.185-2.285c.149.145 2.619 3.977 1.478 5.171a.557.557 0 01-.4.2c-.725 0-1.318-1.983-1.267-3.088m53.939 2.886c-1.14-1.194 1.329-5.026 1.479-5.171a8.654 8.654 0 01.183 2.285c.052 1.105-.541 3.088-1.267 3.088a.557.557 0 01-.4-.2m-26.071-25.275h-.149a35.5 35.5 0 00-15.676 4.142S186 280.5 182.868 289.051a25.079 25.079 0 00-16.97-7.725 33.072 33.072 0 00-14.9 2.849c-3.244 1.505-4.767 3.013-4.449 2.852 13.92-7.074 24.392-3.4 30.253.265a27.884 27.884 0 015.321 4.29 15.464 15.464 0 00-.393 2.639s0 3.589 1.982 4.2a1.62 1.62 0 001.421-.381c.953-.944 2.288-4.062-1.482-8.2.034-.034 3.686-8.628 16.09-12.535a37.236 37.236 0 0121.384-.048c12.493 3.9 16.192 12.51 16.242 12.581-3.772 4.139-2.437 7.257-1.484 8.2a1.622 1.622 0 001.421.381c1.986-.609 1.982-4.2 1.982-4.2a15.552 15.552 0 00-.391-2.639 27.876 27.876 0 015.318-4.29c5.862-3.669 16.335-7.338 30.254-.265.318.161-1.2-1.345-4.449-2.852a33.074 33.074 0 00-14.9-2.849 25.072 25.072 0 00-16.967 7.724c-3.13-8.55-11.886-12.823-11.886-12.823a35.5 35.5 0 00-15.675-4.142z'
            transform='translate(-146.511 -272.084)'
            fill='none'
          />
        </clipPath>
      </defs>
      <g data-name='Group 745' clipPath='url(#prefix__a)'>
        <path
          data-name='Rectangle 500'
          transform='translate(-.277 -.001)'
          fill='url(#prefix__b)'
          d='M0 0h128.553v26.541H0z'
        />
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
