/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import styled from 'styled-components'
import { AttributeInput } from '../../types'
import {
  letterSpacing,
  colors,
  fonts,
  desktopVw,
  mobileVw,
  desktopBreakpoint,
} from '../../styles'
import {
  useRemoveItemFromCart,
  useUpdateItemQuantity,
  useRemoveItemFromCartAnalytics,
} from '../../hooks'
import { Rating } from '.'

const perId = process.env.PERSONALISATION_ENGRAVING_PRODUCT_ID
const corkId = process.env.CORK_ENGRAVING_ID
const appronId = process.env.APPRON_PRODUCT_ID
export const LineItem = ({
  item: { customAttributes, title, quantity, variant },
  items,
  setLoading,
}: {
  item: {
    title: string
    quantity: number
    variant?: any
    customAttributes: AttributeInput[]
  }
  items: any
  setLoading: any
}) => {
  const { image, id, priceV2, sku } = variant
  let amount
  let currencyCode
  let newTitle =
    variant.title.toLowerCase() === 'default title' ? null : variant.title
  const perSKU = process.env.PERSONALISATION_SKU
  const corkSku = process.env.CORK_SKU
  const appronSku = process.env.APPRON_SKU

  if (priceV2) {
    amount = priceV2.amount
    currencyCode = priceV2.currencyCode
  }

  const removeSingleFreeProduct = async (quantity, remove = true) => {
    const spatulaProducts = items.find(
      ({ variant }) => variant.id === process.env.SPOON_ID,
    )
    // const freeItem = spatulaProducts?.find(({ customAttributes }) => {
    //     const productFound = customAttributes.find((custom) => custom.key.toLowerCase() === 'free')
    //     if (productFound) {
    //         return true
    //     }
    // })
    // console.log("zxczxczxcz", spatulaProducts.variant.id, spatulaProducts.quantity - quantity);

    if (remove)
      await updateItemQuantity(
        spatulaProducts.variant.id,
        spatulaProducts.quantity - quantity,
      )
    else
      await updateItemQuantity(
        spatulaProducts.variant.id,
        spatulaProducts.quantity + quantity,
      )
  }

  const removeItemFromCart = useRemoveItemFromCart()
  const updateItemQuantity = useUpdateItemQuantity()
  const isTextEntered =
    customAttributes &&
    customAttributes.find(
      ({ key }) => key === '1. Texte gravé' || key === '2. Texte gravé',
    )
  const isFreeProduct =
    customAttributes &&
    customAttributes.find(({ key }) => key.toLowerCase() === 'free') &&
    id === process.env.SPOON_ID
  const isCorkEngraved =
    customAttributes &&
    customAttributes.find(({ key }) => key === 'Gravure bouchon')
  const isAppronEngraved =
    customAttributes &&
    customAttributes.find(({ key }) => key === 'Texte brodé')
  // const isFreeGift = customAttributes && customAttributes.find(({ key }) => key.toLowerCase() === 'free') && id === process.env.FREE_GIFT
  // console.log('isFreeGift', isFreeGift, sku, freeGiftSku );

  const handleRemoveItemFromCart = async () => {
    setLoading(true)
    useRemoveItemFromCartAnalytics(id, amount, title, quantity)
    await removeItemFromCart(id, customAttributes)
    if (customAttributes.find(({ key }) => key === 'Gravure bouchon')) {
      const corkItem = items.find((item) => item.variant.sku === corkSku)
      await updateItemQuantity(
        corkItem.variant.id,
        corkItem.quantity - quantity,
      )
    }
    if (customAttributes.find(({ key }) => key === 'hasAFreeProduct')) {
      // await removeSingleFreeProduct(1)
      const spatulaProducts = items.find(
        ({ variant }) => variant.id === process.env.SPOON_ID,
      )
      await updateItemQuantity(
        spatulaProducts.variant.id,
        spatulaProducts.quantity - quantity,
      )
    }
    if (
      customAttributes.find(
        ({ key }) => key === '1. Texte gravé' || key === '2. Texte gravé',
      )
    ) {
      const pdpItem = items.find(
        (item) => item.variant.sku === perSKU || item.variant.sku === appronSku,
      )
      await updateItemQuantity(pdpItem.variant.id, pdpItem.quantity - quantity)
    }
    if (customAttributes.find(({ key }) => key === 'Texte brodé')) {
      const pdpItem = items.find((item) => item.variant.sku === appronSku)
      await updateItemQuantity(pdpItem.variant.id, pdpItem.quantity - quantity)
    }
    setLoading(false)
  }

  // Make this with state later so you doesnt have to wait to update
  const minusQuantity = async () => {
    setLoading(true)
    await updateItemQuantity(id, quantity - 1)
    if (customAttributes.find(({ key }) => key === 'hasAFreeProduct')) {
      await removeSingleFreeProduct(1)
    }
    setLoading(false)
  }

  const plusQuantity = async () => {
    setLoading(true)
    await updateItemQuantity(id, quantity + 1)
    if (customAttributes.find(({ key }) => key === 'hasAFreeProduct')) {
      await removeSingleFreeProduct(1, false)
    }
    setLoading(false)
  }
  return (
    <StyledLineItem>
      <Wrapper>
        {image && <StyledImg alt={title} src={image.src} />}
        <Info>
          <Title>{title}</Title>
          <Rating score={0} />
          {newTitle && <SubTitle>{newTitle}</SubTitle>}
          {!isFreeProduct ? (
            <Price
              lessMargin={
                customAttributes &&
                customAttributes.find(({ key }) => key === 'Couleur du pot')
              }
            >
              {/* This part shows the breakdown of price in terms of personalisation charges and the product price {isTextEntered ? parseFloat(Number(quantity * amount + jarPersonalisationPrice * quantity).toFixed(2)) : Number(quantity * amount).toFixed(2)} € */}
              {Number(quantity * amount).toFixed(2)}
            </Price>
          ) : null}
          {/* This shows the breakdown of price in terms of personalisation charges and the product price
          {customAttributes &&
            customAttributes.find(({ key }) => key === 'Couleur du pot') && isTextEntered && (
              <PersonalisedProd>
                Total ={' '}
                {parseFloat(Number(
                  amount * quantity,
                ).toFixed(2))}
            € + {parseFloat(Number(jarPersonalisationPrice * quantity).toFixed(2))}€ pour la personnalisation ={' '}
                {parseFloat(Number(
                  amount * quantity + jarPersonalisationPrice * quantity
                ).toFixed(2))}€
              </PersonalisedProd>
            )} */}
          {!(
            (
              customAttributes &&
              (isTextEntered || isCorkEngraved || isAppronEngraved)
            ) // If the customProduct contains text then don't show Quantity Selector
          ) &&
          (id != perId || id != corkId || id != appronId) ? (
            process.env.PERSONALISATION_SKU === variant.sku ||
            isFreeProduct ||
            process.env.CORK_SKU === variant.sku ||
            process.env.APPRON_SKU === variant.sku ? (
              <QuantitySelectorPersonalisation>
                <>
                  <span className='quantity'>{quantity}</span>
                </>
              </QuantitySelectorPersonalisation>
            ) : (
              <QuantitySelector>
                <button
                  type='button'
                  disabled={quantity <= 1}
                  onClick={() => minusQuantity()}
                >
                  -
                </button>
                <span>{quantity}</span>
                <button type='button' onClick={() => plusQuantity()}>
                  +
                </button>
              </QuantitySelector>
            )
          ) : null}
        </Info>
      </Wrapper>
      {sku != perSKU && !isFreeProduct && sku != corkSku && sku != appronSku ? (
        <Remove type='button' onClick={() => handleRemoveItemFromCart()}>
          Retirer
        </Remove>
      ) : null}
    </StyledLineItem>
  )
}

const StyledLineItem = styled.div``

const Wrapper = styled.div`
  min-height: ${mobileVw(176)};
  display: flex;
  overflow: auto; //Added to remove the horizontal overflow while adding the personalisation product */

  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${desktopVw(240)};
  }
`

const StyledImg = styled.img`
  width: ${mobileVw(136)};
  object-fit: contain;
  mix-blend-mode: multiply;
  background: ${colors.lightGrey};
  margin: 0 ${mobileVw(16)} 0 0;
  max-height: ${mobileVw(200)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(160)};
    margin: 0 ${desktopVw(30)} 0 0;
    max-height: inherit;
  }
`

const SubTitle = styled.p`
  color: ${colors.darkGrey};
`

const Info = styled.div`
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    letter-spacing: ${letterSpacing(100)};
    font-size: ${desktopVw(18)};
  }
`

const Title = styled.h2`
  line-height: ${mobileVw(16)};
  margin: 0 0px ${mobileVw(5)} -1px;

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(24)};
    margin: 0 0px ${desktopVw(5)} 0px;
  }
`

const Price = styled.p`
  line-height: ${mobileVw(20)};
  color: ${colors.mailleGold};
  margin: ${({ lessMargin }) => (!lessMargin ? mobileVw(15) : mobileVw(7))} 0 0
    0;
  font-size: ${mobileVw(20)};

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(20)};
    margin: ${desktopVw(15)} 0 0 0;
    font-size: inherit;
  }
`

const Remove = styled.button`
  display: block;
  margin: ${mobileVw(13)} 0 0 0;
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  font-size: ${mobileVw(16)};
  text-transform: uppercase;
  border-bottom: ${mobileVw(1)} solid ${colors.black};
  padding: 0;
  cursor: pointer;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(13)} 0 0 0;
    font-size: ${desktopVw(16)};
    border-bottom: ${desktopVw(1)} solid ${colors.black};
  }
`

const QuantitySelectorPersonalisation = styled.div`
  font-family: 'Tivoli Bold';
  justify-content: center;
  font-size: ${mobileVw(20)};
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.darkGrey};
  margin: ${mobileVw(16)} 0px;
  .quantity {
    padding: ${`${mobileVw(11)} ${mobileVw(30)}`};
    @media (min-width: ${desktopBreakpoint}) {
      padding: ${`${desktopVw(11)} ${desktopVw(30)}`};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(22)};
    margin: auto 0 0 0;
  }
`

const QuantitySelector = styled.div`
  font-family: 'Tivoli Bold';
  font-size: ${mobileVw(20)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.darkGrey};
  margin: ${mobileVw(16)} 0px;
  .quantity {
    padding: ${`${mobileVw(11)} ${mobileVw(30)}`};
    @media (min-width: ${desktopBreakpoint}) {
      padding: ${`${desktopVw(11)} ${desktopVw(30)}`};
    }
  }
  button {
    padding: ${`${mobileVw(11)} ${mobileVw(30)}`};
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    &:disabled {
      color: ${colors.darkGrey};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(22)};
    margin: auto 0 0 0;

    button {
      padding: ${`${desktopVw(11)} ${desktopVw(30)}`};
      &:not(:disabled):hover {
        background-color: ${colors.mediumGrey};
      }
    }
  }
`

const PersonalisedProd = styled.p`
  margin: 0;
  font-size: ${mobileVw(10)};
  line-height: ${mobileVw(12)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(10)};
    line-height: ${desktopVw(12)};
  }
`
