import React, { useState } from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'

import {
  desktopBreakpoint,
  letterSpacing,
  mobileVw,
  shortMobVw,
  colors,
  fonts,
} from '../../styles'
import { useToggleMenu, useSiteQuery, useDictionaryQuery } from '../../hooks'
import { LinkType, Button } from '../shared'
import { ArrowRight } from '../shared/icons'

const GammeOverlay = ({ data, onClick }) => {
  const { navigationAsideDecoration } = useSiteQuery()

  const toggleAside = useToggleMenu()

  const handleClose = () => {
    toggleAside()
    onClick()
  }

  const dictionary = useDictionaryQuery();
  
  return (
    <Overlay>
      <div style={{ padding: `0 ${mobileVw(16)} ` }}>
        <AsideHeader>
          <button style={{ transform: 'rotate(180deg)' }} onClick={onClick}>
            <ArrowRight />
          </button>
          <button onClick={handleClose}>FERMER</button>
        </AsideHeader>
        {data.map((slide, i) => (
          <Wrap>
            <Wrapper>
            <OptionWrapper
            key={i}
            to={`/fr${slide.slug}`}
            alt={slide.title}
            onClick={handleClose}
          >
              {slide.__typename === 'ContentfulPageBoutiqueLanding'
                || slide.__typename === 'ContentfulPageEBoutique' ?
                <StyledImage
                fluid={slide.thumbnailImage.fluid}
                alt={slide.thumbnailImage.title}
                /> :
                <StyledImage
                  fluid={slide.secondaryImage.fluid}
                  alt={slide.secondaryImage.title}
                />}
                </OptionWrapper>
                <OptionWrapper
            key={i}
            to={`/fr${slide.slug}`}
            alt={slide.title}
            onClick={handleClose}
          >
              <TextWrapper>
                <StyledTitle
                  color={
                    slide.__typename === 'ContentfulPageBoutiqueLanding'
                      ? `${colors.white}` : slide.__typename === 'ContentfulPageEBoutique' 
                      ? `${colors.mailleGold}`
                        : `${colors.mailleGold}`
                  }
                >
                  {' '}
                  {slide.title}
                </StyledTitle>
                <Description
                  color={
                    slide.__typename === 'ContentfulPageBoutiqueLanding'
                      ? `${colors.white}` : slide.__typename === 'ContentfulPageEBoutique' 
                      ? `${colors.mailleGold}`
                        : `${colors.mailleGold}` 
                  }
                >
                  {slide.__typename === 'ContentfulPageBoutiqueLanding'
                    ? dictionary.availableInSupermarkets : slide.__typename === 'ContentfulPageEBoutique' 
                    ? dictionary.exclusiveOnlineProductRangeBoutiqueMaille 
                      : "" }
                </Description>
              </TextWrapper>
              </OptionWrapper>
            </Wrapper>
            <OptionWrapper
            key={i}
            to={`/fr${slide.slug}`}
            alt={slide.title}
            onClick={handleClose}
          >
            <StyledButton
              color={
                slide.__typename === 'ContentfulPageBoutiqueLanding'
                  ? `inherit` : slide.__typename === 'ContentfulPageEBoutique' 
                  ? `${colors.mailleGold}`
                    : `${colors.mailleGold}`
              }
              text={
                slide.__typename === 'ContentfulPageBoutiqueLanding'
                  ? dictionary.readMore : slide.__typename === 'ContentfulPageEBoutique' 
                  ? dictionary.buyOnline
                    : dictionary.buyOnline
              }
            />
          </OptionWrapper>
          </Wrap>
        ))}
      </div>
      <Image
        fluid={navigationAsideDecoration.fluid}
        alt={navigationAsideDecoration.title}
      />
    </Overlay>
  )
}

const Overlay = styled.div`
  position: absolute;
  background-color: ${colors.black};
  z-index: 999;
  width: 100%;
  z-index: 999;
  height: 100vh;
  overflow-y: auto;
`
const AsideHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${mobileVw(10)};

  button {
    padding: ${mobileVw(16)} 0px;
    font-family: 'Tivoli Bold';
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(16)};
    letter-spacing: ${letterSpacing(100)};
    color: ${colors.mailleGold};
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  display: flex;
  padding-bottom: ${mobileVw(16)};
`

const StyledButton = styled(Button)`
  background-color: ${(props) => props.color};
`

const StyledImage = styled(Image)`
  min-width: ${mobileVw(96)};
`
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${mobileVw(41)};

  &:last-of-type {
    margin-bottom: ${mobileVw(20)};
  }
`
const OptionWrapper = styled(LinkType)`
  
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${mobileVw(10)};
`

const StyledTitle = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  color: ${(props) => props.color};
`

const Description = styled.span`
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(14)};
  letter-spacing: ${mobileVw(1.4)};
`

export default GammeOverlay
