import { useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const CookieFooterEnCA = () => {
  const location = useLocation()
  useEffect(() => {
    const addjs = () => {
      const script = document.createElement('script')
      script.setAttribute('id', 'ad-choice-part-1')
      script.innerHTML = `
      (function() {
        var ev = document.createElement('script'); ev.type = 'text/javascript'; ev.async = true; ev.setAttribute('data-ev-tag-pid', 8147); ev.setAttribute('data-ev-tag-ocid', 2523); 
        ev.src = '//c.evidon.com/pub/tag.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ev, s);
      })();
`
      const script2 = document.createElement('script')
      script2.setAttribute('id', 'ad-choice-part-2')
      script2.innerHTML = `
      (function (id, cid, cb) {
          var d = document
            , s = d.createElement('script')
            , ts = d.getElementsByTagName('script')[0];
            s.type = 'text/javascript';
            s.async = true;
            s.setAttribute('data-ev-noticeid', id);
            s.setAttribute('data-ev-coid', cid);
            s.setAttribute('data-ev-consent-callback', cb);
            s.setAttribute('data-ev-consent-type', 'cn');
            s.src = '//c.evidon.com/pub/gdprnotice.js';
            ts.parentNode.insertBefore(s, ts);
      })(8147, 2523, 'g_consentGiven');
      
      /*
          Function used for consent callback.  Put any script or tag manager
          calls in here to execute after consent is detected.  Note, this
          needs to be part of the window namespace so either leave this out of any self executing
          function calls, or assign it to the window namespace (window.g_consentGiven = function() { }
            */
            function g_consentGiven() {
            }
      
      /*
            // example function which can be used to load a script after consent has been given.
            // Ex:  g_addScript('https://some.tag.com/tracker');
            function g_addScript(url) {
          var head = document.head;
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = url;
            head.appendChild(script);
      }*/
`
      const empty = document.createElement('div')
      empty.setAttribute('id', 'ad-choice-div')
      empty.append(script)
      empty.append(script2)
      document.getElementsByTagName(`body`)[0].appendChild(empty)
    }

    const removejs = (div) => {
      document.getElementsByTagName(`body`)[0].removeChild(div);
    }

    const adChoice = document.getElementById('ad-choice-div')
    if (adChoice) {
      removejs(adChoice)

      setTimeout(() => {
        addjs()
      }, 100)
    } else {
      addjs()
    }
  }, [location])
  return (
    <>
      <CookieAnchor id="_bapw-link" href="#" target="_blank">
        <>
          <CookieImg id="_bapw-icon" alt='ad_choice_icon' />
          <CookieSpan >AdChoices</CookieSpan >
        </>
      </CookieAnchor >
    </>
  )

}


const CookieAnchor = styled.a`
text-decoration: none !important;
`

const CookieImg = styled.img`
  border: 0 !important; 
  display: inline !important;
  vertical-align: middle !important;
  padding-right: 5px !important;
  height: fit-content;
  width: fit-content;
`

const CookieSpan = styled.span`
vertical-align:middle !important;
`

export default CookieFooterEnCA