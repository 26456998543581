import React from 'react'
import styled from 'styled-components'

export const DropdownArrow = (props) => {
  return (
    <StyledSvg viewBox='0 0 25.441 14.135' {...props}>
      <path
        data-name='Path 1614'
        d='M24.734.707L12.721 12.72.707.707'
        fill='none'
        stroke={props.color}
        strokeMiterlimit={10}
        strokeWidth={props.strokeWidth || 2}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg``
